import { v4 } from 'uuid'
import lodash from 'lodash'
import { basicValidator, isValidNum, isValidNumberDigits } from '@berry/common-functions/validators'
import moment from 'moment'
import axios from './axios/axios'

export const prepareObjFromServer = (inObj) => {
	if (!inObj) return
	if (typeof inObj === 'object' && inObj !== null) {
		if (!Array.isArray(inObj)) {
			if (!inObj._uuid_) {
				inObj._uuid_ = v4()
			}
			for (let k in inObj) {
				prepareObjFromServer(inObj[k])
			}
		} else {
			for (let i = 0; i < inObj.length; i++) {
				const el = inObj[i]
				prepareObjFromServer(el)
			}
		}
	}
	return inObj
}

export const prepareObjToServer = (inObj) => {
	if (!inObj) return
	if (typeof inObj === 'object' && inObj !== null) {
		if (!Array.isArray(inObj)) {
			if (inObj._uuid_) {
				delete inObj._uuid_
			}
			if (inObj.__serial) {
				delete inObj.__serial
			}
			for (let k in inObj) {
				prepareObjToServer(inObj[k])
			}
		} else {
			for (let i = 0; i < inObj.length; i++) {
				const el = inObj[i]
				prepareObjToServer(el)
			}
		}
	}
	return inObj
}

/**
 * Возвращает обьект с дополнительным полем uuid
 * @param {Object} inData - обновленный объект
 */
export const getNewObj = (inData = {}) => {
	inData._uuid_ = v4()
	return inData
}
/**
 * Удаляет обьект из массива
 * @param {string} inUuid - что удаляется
 * @param {Array<Object>} inArr - массив
 */
export const removeByUuid = (inUuid, inArr) => {
	if (!inArr || !inArr.length) throw Error('inArr is emp[ty')
	if (!inUuid) throw Error('inUuid is empty')
	const foundIndx = inArr.findIndex((e) => e._uuid_ === inUuid)
	if (foundIndx === -1) throw Error('Удаляемый элемент не найден')
	let newArr = [...inArr.slice(0, foundIndx), ...inArr.slice(foundIndx + 1, inArr.length)]
	return newArr
}

/**
 * Обновляет целиком обьект из массива
 * @param {string} inUuid - что удаляется
 * @param {Array<Object>} inArr - массив
 * @param {Object} inData - данные
 */
export const editByUuid = (inUuid, inArr, inData) => {
	if (!inArr || !inArr.length) throw Error('inArr is emp[ty')
	if (!inUuid) throw Error('inUuid is empty')
	if (!inData) throw Error('inData is empty')
	const foundIndx = inArr.findIndex((e) => e._uuid_ === inUuid)

	if (foundIndx === -1) throw Error('Изменяемый элемент не найден')
	inData._uuid_ = inUuid
	let newArr = [...inArr.slice(0, foundIndx), inData, ...inArr.slice(foundIndx + 1, inArr.length)]
	return newArr
}

/**
 * Можно ли добавить параметр в качестве элемента массива к данным провайдера
 * @param {Object} inData - обновленный объект
 */
export const isAvailableToAddToArr = (inData) => {
	return lodash.isPlainObject(inData) || inData === undefined
}

/**
 * изменяет поле внутри обьекта во вложенном массиве
 * @param {Array<String>} inFields - массив полей
 * @param {Array<String>} inUuids - массив айдишников
 * @param {Object} inState - изначальный обьект
 * @param {String} field - название поля
 * @param {any} value
 */
export const deepUpdateField = (inFields, inUuids, inState, field, value) => {
	if (!inFields?.length || !inUuids?.length || inUuids.length !== inFields.length)
		throw Error('inUuids or inFields is incorrect')
	if (!field) throw Error(' field is incorerect')
	const newState = lodash.cloneDeep(inState)
	let locObj = _deepFind(inFields, inUuids, newState)
	locObj[field] = value
	return newState
}

/**
 * изменяет поле внутри обьекта в массиве
 * @param {String} inUuid - массив айдишников
 * @param {Array<Object>} inArr - изначальный обьект
 * @param {String} field - название поля
 * @param {any} value
 */
export const updateFieldInArr = (inUuid, inArr, field, value) => {
	if (!inUuid || !inArr?.length) throw Error('inUuid or inArr is incorrect')
	if (!field) throw Error(' field is incorerect')
	const newState = lodash.cloneDeep(inArr)
	let locObj = newState
	const indx = locObj.findIndex((e) => e._uuid_ === inUuid)
	if (indx === -1) throw Error('indx is -1 ')
	locObj = locObj[indx]
	locObj[field] = value
	return newState
}

/**
 * Фильтрует данные на основе поиска и фильров
 * @param {Array<Object>} inList - массив полей
 * @param {{search: string,searchFields: Array<String> }} params - массив айдишников
 * @returns {Array<Object>}
 */
export const applyFilterForList = (inList, params = {}) => {
	let result = [...inList]
	if (![undefined, '', null].includes(params.search))
		if (params.searchFields?.length) {
			result = result.filter((e) => {
				return params.searchFields.some((sf) =>
					String(lodash.get(e, sf)).toLowerCase().includes(params.search.toLowerCase())
				)
			})
		}
	return result
}

/**
 * ищет обьект внутри обьекта во вложенном массиве
 * @param {Array<String>} inFields - массив полей
 * @param {Array<String>} inUuids - массив айдишников
 * @param {Object} inState - в чем искать
 * @param {any} value
 * @param {{key: string}} options
 */
export const deepFind = (inFields, inUuids, inState, options = { key: '_uuid_' }) => {
	if (!inFields?.length || !inUuids?.length || inUuids.length !== inFields.length)
		throw Error('inUuids or inFields is incorrect')

	const newState = lodash.cloneDeep(inState)
	return _deepFind(inFields, inUuids, newState, options)
}

/**
 * ищет обьект внутри обьекта во вложенном массиве(без клонирования входа)
 * @param {Array<String>} inFields - массив полей
 * @param {Array<String>} inUuids - массив айдишников
 * @param {Object} inState - в чем искать
 * @param {any} value
 * @param {{key: string}} options
 */
export const _deepFind = (inFields, inUuids, inState, options = { key: '_uuid_' }) => {
	if (!inFields?.length || !inUuids?.length || inUuids.length !== inFields.length)
		throw Error('inUuids or inFields is incorrect')

	let locObj = inState.data || inState
	for (let i = 0; i < inFields.length; i++) {
		const indx = locObj[inFields[i]].findIndex((e) => String(e[options.key]) === String(inUuids[i]))
		if (indx === -1) throw Error('indx is -1 ')
		locObj = locObj[inFields[i]][indx]
	}
	return locObj
}

/**
 * возвращает тело запроса для обновления объекта на сервере
 * @param {Object} obj1 - старый объект
 * @param {Object} obj2 - новый объект
 * @returns {
 * 	id: obj1.id
 * 	field: [
 * 		новый элемент:
 * 		{
 * 			k: v
 * 		},
 * 		элемент удален:
 * 		{
 * 			id: 1,
 * 			k: v,
 * 			__toDelete: true
 * 		},
 * 		элемент изменен:
 * 		{
 * 			id: 1,
 * 			k: v
 * 		},
 * 	]
 * }
 */
export const getObjDiff = (obj1, obj2) => {
	const copyOfObj1 = lodash.cloneDeep(obj1)
	const obj1_noUuid = prepareObjToServer(copyOfObj1)
	const copyOfObj2 = lodash.cloneDeep(obj2)
	const obj2_noUuid = prepareObjToServer(copyOfObj2)
	const result = procObj(obj1_noUuid, obj2_noUuid)
	return { ...result, id: obj1.id }
}

export const getArrDiff = (obj1, obj2) => {
	const copyOfObj2 = lodash.cloneDeep(obj2)
	const obj1_noUuid = prepareObjToServer(obj1)
	const obj2_noUuid = prepareObjToServer(copyOfObj2)
	const result = procArr(obj1_noUuid, obj2_noUuid)
	return Object.values(result)
}

const procArr = (arr1, arr2) => {
	return arr1.reduce(
		(acc1, cur) => {
			const arr2index = arr2.findIndex((el) => cur.id && el.id === cur.id)
			if (arr2index !== -1) {
				const res = procObj(cur, arr2[arr2index])
				return Object.keys(res).length ? [...acc1, { ...res, id: cur.id }] : acc1
			}
			return cur.id ? [...acc1, { ...cur, __toDelete: true }] : acc1
		},
		arr2.filter((el) => !arr1.some((e) => (el.id ? e.id === el.id : e === el)))
	)
}

const procObj = (obj1, obj2) =>
	[null, undefined].includes(obj2)
		? null
		: !obj1
		? obj2
		: Object.keys(obj1).reduce(
				(acc1, key) => {
					if (moment.isMoment(obj1)) {
						if (obj1.toString() === obj2.toString()) {
							return acc1
						}
						return { ...acc1, [key]: obj2 }
					}
					if (typeof obj1[key] === 'object' && obj1[key] !== null) {
						if (Array.isArray(obj1[key])) {
							const arrResult = procArr(obj1[key], obj2[key])
							return arrResult.length
								? {
										...acc1,
										[key]: arrResult,
								  }
								: acc1
						} else {
							const objResult = procObj(obj1[key], obj2[key])
							return objResult !== null && (!objResult || !Object.keys(objResult).length)
								? acc1
								: {
										...acc1,
										[key]: objResult,
								  }
						}
					} else {
						if (obj1[key] !== undefined && obj1[key] === obj2[key]) {
							return key === 'id' && Object.keys(acc1).length ? { [key]: obj1[key], ...acc1 } : acc1
						}
						return {
							...acc1,
							[key]: obj2[key] === undefined ? null : obj2[key],
						}
					}
				},
				Object.keys(obj2).reduce((acc2, key) => {
					if (moment.isMoment(obj2)) {
						if (obj1.toString() === obj2.toString()) {
							return acc2
						}
						return { ...acc2, [key]: obj2 }
					}
					if (typeof obj1[key] === 'object' && obj1[key] !== null) {
						if (Array.isArray(obj1[key])) {
							const arrResult = procArr(obj1[key], obj2[key])
							return arrResult.length
								? {
										...acc2,
										[key]: arrResult,
								  }
								: acc2
						} else {
							const objResult = procObj(obj1[key], obj2[key])
							return !objResult || !Object.keys(objResult).length
								? acc2
								: { ...acc2, [key]: objResult }
						}
					} else {
						if (obj1[key] !== undefined && obj1[key] === obj2[key]) {
							return key === 'id' && Object.keys(acc2).length ? { ...acc2, [key]: obj1[key] } : acc2
						}
						return {
							...acc2,
							[key]: obj2[key] === undefined ? null : obj2[key],
						}
					}
				}, {})
		  )

/**
 * проверяет что среди полей объекта (включая вложенные) есть поля, отличные от указанных
 * @param {Object} obj - объект для проверки
 * @param {Array<String>} [fields] - массив полей
 */
export const isEdited = (obj, fields = []) => {
	const commonFields = ['id', '_uuid_', 'uid', '__serial']
	for (const prop in obj) {
		if (![...fields, ...commonFields].includes(prop)) {
			return true
		}
		if (![null, undefined].includes(obj[prop]) && typeof obj[prop] === 'object') {
			return isEdited(obj[prop])
		}
	}
	return false
}

/**
 * патчит определенные поля вложенных обьектов данными из глобального контекста
 * @param {Object} dataServerCtx
 * @param {Object} obj
 *  @param {String} field
 *  @param {String} url
 *  @param {{dataField:string}} options
 */
const patch = (dataServerCtx, obj, field, url, options = {}) => {
	if ([undefined, null].includes(obj[field])) return
	let realData
	if (![undefined, null].includes(options?.dataField)) {
		for (let i = 0; i < dataServerCtx.clonedState[url].data.length; i++) {
			const el = dataServerCtx.clonedState[url].data[i]
			const found = el[options.dataField]?.find((e) => e.id === obj[field].id)
			if (found) {
				realData = found
				break
			}
		}
	} else {
		realData = dataServerCtx.findRecord({ url: url, id: obj[field].id })
	}
	obj[field] = realData
	return
}

/**
 * патчит определенные поля вложенных обьектов данными из глобального контекста
 * @param {Object} dataServerCtx
 * @param {Object} obj
 * @param {Array<String>} inFields - массив полей
 *  @param {String} field
 *  @param {String} url
 *  @param {{dataField:string}} options
 */
export const actualizeData = (dataServerCtx, obj, inFields, field, url, options = {}) => {
	if (inFields.length === 0) return patch(dataServerCtx, obj, field, url, options)
	if ([undefined, null].includes(obj[inFields[0]])) return
	obj[inFields[0]].forEach((el) => {
		return actualizeData(dataServerCtx, el, inFields.slice(1), field, url, options)
	})
	return
}

/**
 * Получает потенциально свободный id для будущей записи
 * @param {Object} dataServerCtx
 * @param {Object} state
 * @param {string} url
 * @param {Array<String>} fields - путь в объекте к массиву с айдишниками
 */
export const getNextId = (array, state, url, fields = []) => {
	const getMax = (arr) =>
		arr.map((el) =>
			fields.length > 1
				? getNextId(array, el[fields[0]], url, fields.slice(1))
				: !el[fields[0]]?.length
				? 1
				: Math.max(
						...el[fields[0]]
							.filter((el) => !!el.id)
							.map((el) => el.id)
							.flat()
				  ) + 1
		)
	if (!array || !url) throw Error('неепроавильно переданы параметры')
	if (!fields.length) {
		return Math.max(...array.map((el) => el.id)) + 1
	}
	const dataMaxValues = getMax(array)
	const stateMaxValues = getMax([state])
	const result = Math.max(...[...dataMaxValues, ...stateMaxValues])
	if ([Infinity, -Infinity, NaN].includes(result)) throw Error('getNextId err ')
	return result
}

/**
 * Получает потенциально свободный id для будущей записи
 * @param {Object} dataServerCtx
 * @param {Object} state
 * @param {string} url
 * @param {Array<String>} fields - путь в объекте к массиву с айдишниками
 */
export const getNextIdV2 = (inArr, state = []) => {
	if (!inArr) throw Error('неепроавильно переданы параметры')
	return Math.max(...inArr.map((el) => el.id), ...state.map((e) => e.id)) + 1
}

/**
 * делает поиск в массиве
 * @param {Object} inArr - в чем ищем
 * @param {Object} inFields - карта. ключ - название поля
 *  @param {Array<string>} defaultFields - поля по которым будет проверяться. через дот нотацию
 *  @param {{exclude:Array<String>}} options
 */
export const findByFields = (inArr = [], inFields = {}, defaultFields, options = {}) => {
	if (!inArr || !inFields || !defaultFields) throw Error('неепроавильно переданы параметры')
	// if (Object.entries(inFields).every(([k, v]) => !v)) return inArr
	return inArr.filter((e) => {
		return defaultFields.every((df) => {
			if (inFields[df]) {
				if (String(inFields[df]) !== String(lodash.get(e, df))) return false
			} else {
				if (!options.exclude?.includes(df)) {
					if (lodash.get(e, df)) return false
				}
			}
			return true
		})
	})
}

/**
 * возвращает formData с прикрепленными файлами
 * @param {Object} inArr - массив элементов, содержащих файлы
 * @param {string} field - поле с файлом через дот-нотацию
 * @param {string} [filename=id] - поле, которое будет использоваться в качестве имени файлов
 * (если имя уже не указано в file.originFileObj.photoPath)
 * @param {string} [prefix] - префикс имени файлов
 *  @param {{key : string}} options - обязатеное
 */
export const appendFiles = (inArr = [], field, filename = 'id', prefix, options) => {
	const formData = new FormData()
	inArr.forEach((el) => {
		if (lodash.get(el, field)?.length) {
			for (const file of lodash.get(el, field)) {
				if (file.originFileObj) {
					formData.append(
						lodash.get(el, filename),
						file.originFileObj,
						file.originFileObj[options.key] ||
							`${prefix}_${v4()}.${file.originFileObj.name.split('.').pop()}`
					)
				}
			}
		} else {
			const file = lodash.get(el, field)
			if (file?.originFileObj) {
				formData.append(
					lodash.get(el, filename),
					file.originFileObj,
					file.originFileObj[options?.key] ||
						`${prefix}_${v4()}.${file.originFileObj.name.split('.').pop()}`
				)
			}
		}
	})
	return formData
}

/**
 * валидирует значение на обязательность заполнения
 * возвращает true если все ок или false если не ок
 * @param {function} setError - функция установки ошибки
 * @param {string} field - название поля содержащего ошибку
 * @param {string} name - название которое отобразится в сообщении валидации
 * @param {string} val - значение
 */
export const validateRequired = (setError, field, name, val) => {
	if (!basicValidator(val)) {
		setError([], [], field, `Поле${!!name ? ' ' : ''}${name} обязательно для заполнения!`)
		return false
	} else {
		setError([], [], field, undefined)
		return true
	}
}

/**
 * валидирует значение на обязательность заполнения
 * возвращает true если все ок или false если не ок
 * @param {function} setError - функция установки ошибки
 * @param {string} field - название поля содержащего ошибку
 * @param {string} text - иекст отобразится в сообщении валидации
 * @param {string} val - значение
 * @param {function} validator - функция валидатор
 */
export const validateByFunction = (setError, field, text, val, validator) => {
	if (validateRequired(setError, field, '', val)) {
		if (!validator(val)) {
			setError([], [], field, text)
			return false
		} else {
			setError([], [], field, undefined)
			return true
		}
	}
}

/**
 * валидирует значение на правильность числа
 * @param {function} setError - функция установки ошибки
 * @param {string} field - название поля содержащего ошибку
 * @param {string} name - название которое отобразится в сообщении валидации
 * @param {string} val - значение
 *  @param {{numType: ('int'|'float'), digits:[number,number]}} params - параметры валидации точности
 */
export const validateNum = (setError, field, name, val, params) => {
	if (validateRequired(setError, field, name, val)) {
		if (!isValidNum(val) || !isValidNumberDigits(+val, params.numType, params.digits)) {
			setError([], [], field, 'Поле заполнено не верно')
		} else {
			setError([], [], field, undefined)
		}
	}
}

/**
 * валидирует значение на правильность даты
 * @param {function} setError - функция установки ошибки
 * @param {string} field - название поля содержащего ошибку
 * @param {string} name - название которое отобразится в сообщении валидации
 * @param {string} val - значение
 */
export const validateDate = (setError, field, name, val) => {
	if (validateRequired(setError, field, name, val)) {
		if (!moment(val).isValid()) {
			setError([], [], field, 'Поле заполнено не верно')
		} else {
			setError([], [], field, undefined)
		}
	}
}

/**
 * валидирует значение на правильность числа
 * @param {function} setError - функция установки ошибки
 * @param {string} field - название поля содержащего ошибку
 * @param {string} val - значение
 * @param {array} inArr - массив в кором будем искать
 * @param {string} idId - id
 *  @param {{numType: ('int'|'float'), digits:[number,number]}} params - параметры валидации точности
 */

export const validateUniq = (setError, field, val, inArr, inId, fieldError, text) => {
	if (
		inArr.some(
			(item) =>
				String(item[field]).toLowerCase() === String(val).toLowerCase() &&
				String(item.id) !== String(inId)
		)
	) {
		setError([], [], `${fieldError}`, `${text}`)
	}
}

export const validateUniqV2 = (setError, field, val, inArr, inId, fieldError, text) => {
	if (
		inArr.some(
			(item) =>
				String(item[field]).toLowerCase() === String(val).toLowerCase() &&
				String(item.id) !== String(inId)
		)
	) {
		setError([], [], fieldError, text)
	} else {
		setError([], [], fieldError, undefined)
	}
}

/**
 * валидирует значение на уникальность
 * @param {array} inArr - массив в кором будем искать
 * @param {string} element - объект для сравнения
 * @param {string} field - поле
 * @param {boolean} caseSensitive - с учетом регистра
 * возвращает true если значение уникальное
 */

export const formValidateUniq = (inArr, element, field, caseSensitive = false) => {
	return !inArr.some(
		(el) =>
			!['', null, undefined].includes(element[field]) &&
			(caseSensitive
				? String(el[field]) === String(element[field])
				: String(el[field]).toLowerCase() === String(element[field]).toLowerCase()) &&
			(element.id ? el.id !== element.id : el._uuid_ !== element._uuid_)
	)
}

/**
 * фильтрует данные исключая уже существующие в массиве
 * @param {Array<Object>} inArrFrom - массив в кором будем искать
 * @param {Array<Object>} inBaseArr - объект для сравнения
 */

export const simpleFilterAlreadyExists = (inArr, inBaseArr, inBaseArrFields = []) => {
	return inArr.filter((e) => {
		return !inBaseArr?.some((s) => String(s.room?.id) === String(e.id))
	})
}

export const startUseEffectHandlerForList = async (inParams) => {
	const { executeDispatch, stateRef, toServerParams, setTotal, syncDepsCtx, dataUrl } = inParams
	executeDispatch({
		...stateRef.current,
		isLoading: true,
	})
	const fromServerResp = await axios(dataUrl, {
		params: toServerParams,
	})
	executeDispatch({
		...stateRef.current,
		fromServer: fromServerResp.data.data,
		fromServerFilters: fromServerResp.data.filters,
		fromServerSelectors: fromServerResp.data.selectors,
		fromServerTotalSum: fromServerResp.data.totalSum,
		stockNotify: fromServerResp.data.stockNotify,
		isInitialized: true,
		isLoading: false,
	})
	setTotal(fromServerResp.data.count)
	syncDepsCtx.setDepsInfo({
		deps: fromServerResp.data.syncDeps,
		isForList: true,
	})
}

export const everyOtherTimeUseEffectHandlerForList = async (inParams) => {
	const { executeDispatch, stateRef, setTotal, syncDepsCtx, toServerParams, dataUrl } = inParams

	if (!stateRef.current.isInitialized) return
	executeDispatch({
		...stateRef.current,
		isLoading: true,
	})
	const fromServerResp = await axios(dataUrl, {
		params: toServerParams,
	})
	executeDispatch({
		...stateRef.current,
		fromServer: fromServerResp.data.data,
		isLoading: false,
		fromServerFilters: fromServerResp.data.filters,
		fromServerTotalSum: fromServerResp.data.totalSum,
	})
	setTotal(fromServerResp.data.count)
	syncDepsCtx.setDepsInfo({
		deps: fromServerResp.data.syncDeps,
		isForList: true,
	})
}

export const getMainDataForItem = async (inParams) => {
	const { url, id, stateRef, executeDispatch, syncDepsCtx } = inParams
	const fromServerResp = await axios(`${url}/${id}`, {})

	executeDispatch({
		...stateRef.current,
		data: {
			...stateRef.current.data,
			...prepareObjFromServer(lodash.cloneDeep(fromServerResp.data.mainData)),
		},

		oldData: { ...stateRef.current.oldData, ...fromServerResp.data.mainData },
		isInitializedMain: true,
	})
	syncDepsCtx.setDepsInfo({
		deps: fromServerResp.data.syncDeps,
		isMerge: true,
	})
}

export const getTabDataForItem = async (inParams) => {
	const { url, id, stateRef, executeDispatch, syncDepsCtx, dataKey, isLoadingKey } = inParams
	const fromServerResp = await axios(url, {
		params: { id: id },
	})
	executeDispatch({
		...stateRef.current,
		data: {
			...stateRef.current.data,
			[dataKey]: fromServerResp.data.mainData
				? prepareObjFromServer(lodash.cloneDeep(fromServerResp.data.mainData))
				: [],
		},
		oldData: { ...stateRef.current.oldData, [dataKey]: fromServerResp.data.mainData },
		tabsLoading: { ...stateRef.current.tabsLoading, [isLoadingKey]: true },
	})
	syncDepsCtx.setDepsInfo({
		deps: fromServerResp.data.syncDeps,
		isMerge: true,
	})
}

export const getAdditionalForItem = async (inParams) => {
	const { url, id, stateRef, executeDispatch } = inParams
	const fromServerResp = await axios(`${url}/additional`, { params: { id } })
	executeDispatch({
		...stateRef.current,
		additional: fromServerResp.data.additional,
		isInitializedAdditional: true,
	})
}
