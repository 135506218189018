import { Table } from 'antd'
import React, { useContext, useState } from 'react'
import { showConfirmModal, TabItem } from '../../../../components'
import { getDisplayColumns } from './spec-ready-prod-tab'
import { ProductCatalogItemMainContext } from '../provider/main'
import { getProdCatKindReadyProdLabel } from '@berry/common-functions/cross-project-functions'
import { openNewTab } from '../../../../utils'
import { AuthContext, UserDataContext } from '../../../../contexts'
import { checkRole } from '@berry/common-functions/role'
import clickableRow from '../../../../utils/helpers/clickableRow'
import rowClassName from '../../../../utils/helpers/rowClassName'

const SpecReadyProdTab = () => {
	const mainCtx = useContext(ProductCatalogItemMainContext)
	const authCtx = useContext(AuthContext)
	const userDataCtx = useContext(UserDataContext)
	const [rowId, setRowId] = useState('')

	const toDisplayDataList = mainCtx.state.kindReadyProds
		?.map((item) => {
			return item.specs?.filter((el) => {
				el.quality = getProdCatKindReadyProdLabel(item)
				return el.status === 'Утверждено'
			})
		})
		.flat()

	const canViewSpec = checkRole(
		{
			'АРМ офиса': {
				'Протоколы контроля': {
					view: true,
				},
			},
		},
		userDataCtx.state.rights
	)

	return (
		<TabItem
			addButton={() => {}}
			table={() => {
				return (
					<Table
						onAdd={() => {}}
						size="small"
						rowKey="id"
						rowClassName={rowClassName(rowId)}
						columns={getDisplayColumns(toDisplayDataList)}
						dataSource={toDisplayDataList}
						onRow={clickableRow(setRowId, authCtx, '/reg/specifications', canViewSpec)}
						scroll={{ x: 800 }}
						pagination={false}
					/>
				)
			}}
		/>
	)
}

export default SpecReadyProdTab
