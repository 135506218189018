import { basicValidator } from '@berry/common-functions/validators'
import lodash from 'lodash'
import moment from 'moment'
import { FilterDropDown } from '../../components'
import { dateFormat } from '../constants/common'

/**
 * ыформирует массив из всех возмождных знвачений поля фильтрации
 * @param {String} inKey - ключ через дот нотацию по которому читать значение
 * @param {Object} inArr - массив с обьектами в которых искать поле
 * @param {Object} options - выбранные для фильтрации значения
 * @returns {Array<{text: string, value: string }>}
 */
const getFilters = (inArr = [], isNeedToFormat = false) =>
	inArr.map((e) => ({
		text:
			typeof e === 'object'
				? e.displayVal
				: isNeedToFormat
				? moment(e, 'YYYY-MM-DD').format(dateFormat)
				: e,
		value: typeof e === 'object' ? e.id : e,
	}))

/**
 * Возвращает обьект со свойствами нужными для колонки таблицы для отрисовки фильтра
 * @param {String} inKey - ключ через дот нотацию по которому читать значение
 * @param {Object} inArr - массив с обьектами в которых искать поле
 * @param {{pathFunc: Function, divider: string, filters: [{text,value}], onFilter: (value, record) => boolean, formatter: (record) => (String | Number)}} } options - объект опций для получения кастомных
 */
export const getColumnSearchProps = (inArr, frontFilters, inKey, isNeedToFormat) => {
	const result = {
		filterDropdown: (props) => {
			return <FilterDropDown {...props} filters={getFilters(inArr, isNeedToFormat)} />
		},
		...(frontFilters && {
			filtered: frontFilters[inKey]?.length,
			filteredValue: frontFilters[inKey] || [],
		}),
		onFilter: inKey
			? (text, record) => {
					let val = lodash.get(record, inKey)
					if (Array.isArray(val)) {
						return (
							!!(text && val) &&
							val.map((v) => String(v).toLowerCase()).includes(String(text).toLowerCase())
						)
					}
					return !!(text && val) && String(val).toLowerCase() === String(text).toLowerCase()
			  }
			: () => {
					return true
			  },
	}
	return result
}

/**
 * Возвращает функцию сортировки
 * @param {('string'|'number'|'date')} inType - ключ через дот нотацию по которому читать значение
 * @param {string} inKey - ключ через дот нотацию по которому читать значение
 * @returns {function}
 */
export const sorter = (inType, inKey, dateFormat = 'hh:mm') => {
	return () => 0
}

/**
 * Возвращает функцию сортировки
 * @param {('string'|'number'|'date')} inType - ключ через дот нотацию по которому читать значение
 * @param {string} inKey - ключ через дот нотацию по которому читать значение
 * @returns {function}
 */
export const frontSorter = (inType, inKey, dateFormat = 'hh:mm') => {
	if (!['string', 'number', 'date', 'boolean'].includes(inType))
		throw Error('неправильно переданы параметры')

	if (inType === 'number') {
		return (recA, recB) => {
			if (Number(lodash.get(recA, inKey)) > Number(lodash.get(recB, inKey))) return -1
			if (Number(lodash.get(recA, inKey)) < Number(lodash.get(recB, inKey))) return 1
			return 0
		}
	}
	if (inType === 'string')
		return (recA, recB, order) => {
			const val1 = lodash.get(recA, inKey)
			const val2 = lodash.get(recB, inKey)
			if ([null, undefined, ''].includes(val1)) return order === 'ascend' ? 1 : -1
			if ([null, undefined, ''].includes(val2)) return order === 'ascend' ? -1 : 1
			return val1?.localeCompare(val2)
		}
	if (inType === 'date')
		return (recA, recB) => {
			const result =
				(lodash.get(recA, inKey) ? moment(lodash.get(recA, inKey)) : moment()).unix() -
				(lodash.get(recB, inKey) ? moment(lodash.get(recB, inKey)) : moment()).unix()
			return result
		}

	if (inType === 'boolean')
		return (recA, recB) => {
			const valA = !!lodash.get(recA, inKey)
			const valB = !!lodash.get(recB, inKey)
			if (valA === valB) return 0
			if (valA === true && valB === false) return -1
			if (valA === false && valB === true) return 1
			return 0
		}
	return () => 0
}

/**
 * Сортирует данные сложной таблицы
 * @typedef {Object} SorterOpts - поля и критерии сортировки
 * @property {string} columnKey - 'ascend' | 'descend' | undefined
 * @param {Array} inArr
 * @param {string} field - поле, содержащее массив для сортировки
 */
export const sortWithRowSpan = (inArr, sorterOpts, fieldToSort, valueField) => {
	const result = lodash.cloneDeep(inArr)
	Object.values(sorterOpts).forEach((v) => {
		result.forEach((g) => {
			if (v === undefined) return
			g[fieldToSort] = g[fieldToSort].sort((a, b) => {
				if (v === 'ascend')
					return lodash.get(a, valueField)?.localeCompare(lodash.get(b, valueField))
				if (v === 'descend')
					return lodash.get(a, valueField)?.localeCompare(lodash.get(b, valueField)) * -1
				return 0
			})
		})
	})
	return result
}

/**
 * подготавливает даннные для отображения в фильтре
 * @param {Array<object>} inArr
 * @param {string} inKey - поле из которого будут браться значения
 */
export const getValuesForFilter = (inArr, inKey, options) => {
	const result = inArr
		.map((e) => {
			return lodash.get(e, inKey)
		})
		.filter((e) => basicValidator(e))
	const onlyUnique = [...new Set(result)]
	return onlyUnique.sort((a, b) =>
		typeof a === 'number' ? a - b : String(a).localeCompare(String(b))
	)
}

export const forSearchInFieldProps = {
	showSearch: true,
	filterOption: (input, option) => {
		return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
	},
}

/**
 * проверяет удовлетворяет ли значение критериям фильтра
 */
export const isAcceptedByFilters = (obj, filters) => {
	return Object.entries(filters).every(([name, val]) => {
		if (!val?.length) return true
		val = val.map((v) => v.toString())
		if (!obj[name]) {
			return false
		}
		if (typeof obj[name] === 'object' && !Array.isArray(obj[name])) {
			return val.includes(String(obj[name]['id']))
		}
		if (Array.isArray(obj[name]) && obj[name].some((e) => val.includes(String(e)))) return true
		if (!val.includes(String(lodash.get(obj, name)))) {
			return false
		}
		return true
	})
}
