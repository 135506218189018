import React, { useContext, useEffect, useRef } from 'react'
import { ListContext, SyncDepsContext } from '../../../../../contexts'
import { getStatus } from '../../../RawMat/RawMatList/provider/main'
import moment from 'moment'
import lodash from 'lodash'
import { getCommonProviderModalFunctions } from '../../../../../utils/helpers/generators'
import {
	calcProdTaskIsTolling,
	prepareParamsForStockRelocationModal,
	prepareParamsForStockSaleModal,
} from '../../../../../utils/helpers/cross-pages-funcs'
import {
	getStockCalculatedParams,
	numToFixed,
} from '@berry/common-functions/cross-project-functions'
import {
	everyOtherTimeUseEffectHandlerForList,
	startUseEffectHandlerForList,
	axios,
} from '../../../../../utils'

const dataUrl = '/stock/semifs'

export const reducer = (state) => {
	return {
		...state,
	}
}

const StockSemifListMainContext = React.createContext()
StockSemifListMainContext.displayName = 'StockSemifListMainContext'

const Provider = (props) => {
	const {
		state: { page, pageSize, mainFilter, filters, sorter, isInStock, weightFrom, weightTo },
		setTotal,
	} = useContext(ListContext)
	const { children } = props
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {
			idContrOrder: [],
			partyNum: [],
			articul: [],
			articul1C: [],
			prodCat: [],
			prodCatKind: [],
			pkgType: [],
			nettoPkgWeight: [],
			roomNum: [],
			status: [],
			_1c: [],
		},
		isInitialized: false,
		isLoading: false,
		saleModal: { __isOpen: false },
		relocationModal: { __isOpen: false },
		repackingModal: { __isOpen: false },
		changeStatusModal: { __isOpen: false },
		fromServerSelectors: {},
		isModalsServerEditSend: false,
		stockNotify: {},
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = newState
		dispatch({ ...state })
	}

	const resetModals = () => {
		executeDispatch({
			...stateRef.current,
			saleModal: { __isOpen: false },
			relocationModal: { __isOpen: false },
			repackingModal: { __isOpen: false },
			changeStatusModal: { __isOpen: false },
		})
	}

	const modalSetOpen = (val, modalName) => {
		executeDispatch({ ...stateRef.current, [modalName]: { isOpen: val } })
	}

	const selectors = {
		saleModal: {
			customer: lodash.sortBy(stateRef.current.fromServerSelectors.customers, ['label']),
		},
		relocationModal: {
			roomNum: stateRef.current.fromServerSelectors.room,
		},
		repackingModal: {
			roomNum: stateRef.current.fromServerSelectors.room?.filter((room) => {
				return room.storage.includes('Сырье') && room.type === 'Складское'
			}),
			containerType: stateRef.current.fromServerSelectors.vocContType,
		},
		changeStatusModal: {
			blockCauses: stateRef.current.fromServerSelectors.blockCause,
		},
		vocProduct1C: stateRef.current.fromServerSelectors.vocProduct1C,
	}
	useEffect(() => {
		startUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				isInStock,
				sorter,
				mainFilter,
				filters,
				offset: pageSize * page - pageSize,
				limit: pageSize,
				weightFrom,
				weightTo,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	}, [])
	const everyOtherTimeUseEffectHandlerForListFunc = () =>
		everyOtherTimeUseEffectHandlerForList({
			executeDispatch,
			stateRef,
			toServerParams: {
				offset: pageSize * page - pageSize,
				limit: pageSize,
				sorter,
				mainFilter,
				filters,
				search: stateRef.current.search,
				isInStock,
				isSelectorsLoaded: true,
				weightFrom,
				weightTo,
			},
			setTotal,
			syncDepsCtx,
			dataUrl,
		})
	useEffect(() => {
		everyOtherTimeUseEffectHandlerForListFunc()
	}, [
		page,
		sorter,
		mainFilter,
		filters,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
		isInStock,
		stateRef.current.search,
		weightFrom,
		weightTo,
	])

	const modalsServerEdit = async () => {
		let options = { url: dataUrl, method: 'PUT' }
		let item
		const { relocationModal, repackingModal, changeStatusModal, saleModal } = stateRef.current

		if (stateRef.current.relocationModal.storage) {
			item = relocationModal.storage.storages.find(
				(el) => el.id === relocationModal.storage.idStorage
			)
		}
		if (changeStatusModal.storage) {
			item = changeStatusModal.storage.storages.find(
				(el) => el.id === changeStatusModal.storage.idStorage
			)
		}
		if (repackingModal.storage) {
			item = repackingModal.storage.storages.find(
				(el) => el.id === repackingModal.storage.idStorage
			)
		}
		let body = {}
		if (saleModal.storage) {
			const preparedParams = prepareParamsForStockSaleModal(saleModal, { dataUrl })
			options = preparedParams.options
			body = preparedParams.body
			body.articul = saleModal.storage.articul
		}

		if (relocationModal.storage) {
			const preparedParams = prepareParamsForStockRelocationModal(
				relocationModal,
				{
					dataUrl,
					wasteKey: 'idStockSemif',
					status: getStatus(relocationModal, item),
				},
				selectors.vocProduct1C
			)
			options = preparedParams.options
			body = preparedParams.body
			body.articul = relocationModal.storage.articul
		}

		if (repackingModal.storage) {
			if (Number(repackingModal.weight) === Number(repackingModal.storage.weight)) {
				body = {
					id: repackingModal.storage.id,
					storages: [
						{
							id: repackingModal.storage.idStorage,
							room: repackingModal.storage.roomNum,
							contType: repackingModal.containerType,
							nettoPkgWeight: +repackingModal.nettoPkgWeight,
							date: moment(),
							status: repackingModal?.isDefrost ? 'Дефростация' : item.status,
						},
					],
				}
			}
			if (Number(repackingModal.weight) < Number(repackingModal.storage.weight)) {
				body = {
					id: repackingModal.storage.id,
					storages: [
						{
							id: repackingModal.storage.idStorage,
							weight: repackingModal.storage.weight - repackingModal.weight,
						},
						{
							...item,
							id: null,
							weight: repackingModal.weight,
							contType: repackingModal.containerType,
							nettoPkgWeight: Number(repackingModal.nettoPkgWeight),
							date: moment(),
							isNonStandardPkg: repackingModal?.isNonStandard,
						},
					],
				}
			}
		}
		if (changeStatusModal.storage) {
			if (Number(changeStatusModal.weight) === Number(changeStatusModal.storage.weight)) {
				body = {
					id: changeStatusModal.storage.id,
					storages: [
						{
							id: changeStatusModal.storage.idStorage,
							status: changeStatusModal.status,
							date: moment(),
							blockCauses: changeStatusModal.blockCauses?.map((bk) => {
								return {
									blockCause: { ...bk },
								}
							}),
						},
					],
					isChangeStatus: true,
				}
			}
			if (Number(changeStatusModal.weight) < Number(changeStatusModal.storage.weight)) {
				body = {
					id: changeStatusModal.storage.id,
					storages: [
						{
							id: changeStatusModal.storage.idStorage,
							weight: changeStatusModal.storage.weight - changeStatusModal.weight,
						},
						{
							...item,
							id: null,
							weight: changeStatusModal.weight,
							status: changeStatusModal.status,
							date: moment(),
							blockCauses: changeStatusModal.blockCauses?.map((bk) => {
								return {
									blockCause: { ...bk },
								}
							}),
						},
					],
					isChangeStatus: true,
				}
			}
		}
		executeDispatch({ ...stateRef.current, isModalsServerEditSend: true })
		const resp = await axios.put(options.url, body)
		executeDispatch({ ...stateRef.current, isModalsServerEditSend: false })
		resetModals()
		everyOtherTimeUseEffectHandlerForListFunc()
		return { method: options.method, resp: resp }
	}

	const dropStatus = async (el) => {
		let storages = []
		if (el.parent) {
			storages = [
				{
					id: el.idStorage,
					__toDelete: true,
				},
				{
					id: el.parent.id,
					weight: +el.weight + +el.parent.weight,
				},
			]
		} else {
			storages = [
				{
					id: el.idStorage,
					status: el.oldStatus,
					oldStatus: null,
				},
			]
		}
		const resp = await axios.put(dataUrl, {
			id: el.id,
			storages,
		})
		everyOtherTimeUseEffectHandlerForListFunc()
		return { method: 'PUT', resp }
	}

	const toDisplayDataList = () => {
		return stateRef.current.fromServer
	}

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{ field: 'sale', updateVal: 'common', modalName: 'saleModal' },
			{ field: 'refund', updateVal: 'common', modalName: 'saleModal' },
			{ field: 'customer', updateVal: 'obj', modalName: 'saleModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'saleModal' },
			{ field: 'isForSaleDepartment', updateVal: 'common', modalName: 'saleModal' },
			{ field: 'weight', updateVal: 'common', modalName: 'saleModal' },

			{ field: 'weight', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'isWriteOff', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'isDefrost', updateVal: 'common', modalName: 'relocationModal' },
			{ field: 'roomNum', updateVal: 'obj', modalName: 'relocationModal' },

			{ field: 'storage', updateVal: 'common', modalName: 'repackingModal' },
			{ field: 'weight', updateVal: 'common', modalName: 'repackingModal' },
			{ field: 'containerType', updateVal: 'obj', modalName: 'repackingModal' },
			{ field: 'nettoPkgWeight', updateVal: 'common', modalName: 'repackingModal' },
			{ field: 'isNonStandard', updateVal: 'common', modalName: 'repackingModal' },

			{ field: 'status', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'weight', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'blockCauses', updateVal: 'common', modalName: 'changeStatusModal' },
			{ field: 'storage', updateVal: 'common', modalName: 'changeStatusModal' },
		]
	)

	const value = {
		state: stateRef.current,
		toDisplayDataList: toDisplayDataList(),
		isModalsServerEditSend: stateRef.current.isModalsServerEditSend,
		selectors,
		fromServerTotalSum: stateRef.current.fromServerTotalSum,
		saleModal: lodash.cloneDeep(stateRef.current.saleModal),
		relocationModal: lodash.cloneDeep(stateRef.current.relocationModal),
		repackingModal: lodash.cloneDeep(stateRef.current.repackingModal),
		changeStatusModal: lodash.cloneDeep(stateRef.current.changeStatusModal),
		modalsServerEdit,
		resetModals,
		modalSetOpen,
		modalFunctions,
		dropStatus,
		setSearch,
	}

	return (
		<StockSemifListMainContext.Provider value={value}>
			{children}
		</StockSemifListMainContext.Provider>
	)
}

export const prepareList = (data) => {
	let preparedData = []

	data.forEach((it) => {
		it.storages.forEach((el) => {
			if (+el.weight === 0) return
			const { articul, articul1C, prodCatKind } = getStockCalculatedParams(it)
			it = {
				...it,
				vocContType: el.vocContType,
				nettoPkgWeight: numToFixed(el.nettoPkgWeight, 2),
				weight: numToFixed(Number(el.weight), 2),
				partyNum: it.taskRep?.partyNum,
				partyNumForSorter: it.taskRep?.partyNum.slice(5, 9),
				articul: articul,
				articul1C: articul1C,
				prodCat: it.taskRep.task.prodCat.label,
				_idProduct: it.taskRep.task.prodCat.id,
				prodCatKind: prodCatKind,
				isPermanent: el.isPermanent,
				pkgType: el.contType?.labelShort,
				isTolling: calcProdTaskIsTolling(it.taskRep?.task),
				roomNum: el.room?.displayCode,
				roomType: el.room?.roomType,
				status: el.status,
				idStorage: el.id,
				_1C: ['Утверждена', 'Принято в 1С', 'Ожидает отправки'].includes(it.taskRep.task.status)
					? 'Зарегистрирован в 1С'
					: 'Не зарегистрирован в 1С',
				idOrder: it.idContrOrder,
			}
			preparedData = [...preparedData, it]
		})
	})
	return preparedData
}
export { Provider, StockSemifListMainContext }
