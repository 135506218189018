import { Button, Form, Space } from 'antd'
import React, { useContext } from 'react'
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close.svg'
// import { ReactComponent as PrintIcon } from '../../../../assets/svg/print.svg'
import { ModContext } from '../../../../contexts'
import { calcMaxLvl, handleCancelMatch, modes } from '../../../../utils'
import { UserDataContext } from '../../../../contexts'
import { InputControlContext } from '../provider/main'
import { getRole } from './utils'
import { LoadingButton, showConfirmModal } from '../../../../components'
import { checkRole } from '@berry/common-functions/role'
import { showCancelMatchModal } from '../../../../utils/constants/for-components'

const ActionButtons = (props) => {
	const {
		isPendingReq = false,
		formName,
		allVocPosTypes,
		onSave = () => {},
		onEdit = () => {},
		onCancel = () => {},
		onClose = () => {},
		rights = {},
	} = props
	const { serverMatch, state } = useContext(InputControlContext)
	const { ctrlStatus, level, supply, invoice } = state
	const supplyStatus = supply?.status
	const invoiceStatus = invoice?.status
	const { mod } = useContext(ModContext)
	const userDataCtx = useContext(UserDataContext)

	const [form] = Form.useForm()

	const { role, currentApproveLvl = null } = getRole(userDataCtx.state.match.supply || {})

	const getButtons = () => {
		const needToApprove = ctrlStatus === 'Исследование завершено'
		const isApproving = ctrlStatus === 'Приемка на согласовании'
		const isDisableEditStatus =
			['Приемка на согласовании'].includes(ctrlStatus) ||
			supplyStatus !== 'Новая' ||
			['Принято в 1С', 'Отправлено в 1С'].includes(invoiceStatus)
		const canApprove = currentApproveLvl === level
		const isLastApprover =
			canApprove && calcMaxLvl(allVocPosTypes, 'lvlApproveSupply') === +currentApproveLvl
		const canEdit = checkRole(rights, userDataCtx.state.rights)
		const result = []
		if ([modes.edit, modes.new].includes(mod)) {
			result.push(
				<LoadingButton
					key="save"
					onClick={onSave}
					htmlType="submit"
					form={formName}
					isPendingReq={isPendingReq}
				>
					Сохранить
				</LoadingButton>
			)
		}
		if (role === 'approver' && isLastApprover && ctrlStatus === 'Приемка на согласовании') {
			result.push(
				...[
					<LoadingButton
						isPendingReq={isPendingReq}
						key="match"
						onClick={() =>
							serverMatch({
								ctrlStatus: 'Приемка разрешена',
								event: 'Утверждено',
								currentBtn: 'match',
							})
						}
					>
						Принять
					</LoadingButton>,
					<LoadingButton
						isPendingReq={isPendingReq}
						key="storage"
						onClick={() =>
							serverMatch({
								ctrlStatus: 'Ответственное хранение',
								event: 'Ответственное хранение',
								currentBtn: 'storage',
							})
						}
					>
						Ответственное хранение
					</LoadingButton>,
					<LoadingButton
						isPendingReq={isPendingReq}
						key="return"
						onClick={() =>
							serverMatch({ ctrlStatus: 'Возврат', event: 'Возврат', currentBtn: 'return' })
						}
					>
						Возврат
					</LoadingButton>,
				]
			)
		} else if (
			role === 'approver' &&
			!isLastApprover &&
			canApprove &&
			ctrlStatus === 'Приемка на согласовании'
		) {
			result.push(
				...[
					<LoadingButton
						isPendingReq={isPendingReq}
						key="match"
						onClick={() =>
							serverMatch({
								ctrlStatus: 'Приемка разрешена',
								event: 'Утверждено',
								currentBtn: 'match',
							})
						}
					>
						Принять
					</LoadingButton>,
					<LoadingButton
						isPendingReq={isPendingReq}
						key="reject"
						onClick={() =>
							handleCancelMatch(serverMatch, form, (values) => {
								serverMatch({
									event: 'Не утверждено',
									cause: values.comment,
									currentBtn: 'reject',
								})
							})
						}
					>
						Запретить приемку
					</LoadingButton>,
					<LoadingButton
						isPendingReq={isPendingReq}
						key="update"
						onClick={() =>
							handleCancelMatch(serverMatch, form, (values) => {
								serverMatch({
									ctrlStatus: 'Исследование завершено',
									event: 'Отправлено на доработку',
									cause: values.comment,
									currentBtn: 'update',
								})
							})
						}
					>
						Отправить на доработку
					</LoadingButton>,
				]
			)
		} else if (role === 'initiator' && needToApprove) {
			if (mod === modes.view) {
				result.push(
					<LoadingButton
						isPendingReq={isPendingReq}
						key="init"
						onClick={() =>
							serverMatch({
								ctrlStatus: 'Приемка на согласовании',
								event: 'Инициация согласования',
								currentBtn: 'init',
							})
						}
					>
						Отправить на согласование
					</LoadingButton>
				)
			}
		} else if (role === 'initiator' && isApproving) {
			result.push(
				<LoadingButton
					isPendingReq={isPendingReq}
					key="cancel"
					onClick={() =>
						showConfirmModal({
							...showCancelMatchModal,
							onOk: () =>
								serverMatch({
									ctrlStatus: 'Исследование завершено',
									event: 'Отмена согласования',
									currentBtn: 'cancel',
								}),
						})
					}
				>
					Отменить согласование
				</LoadingButton>
			)
		}
		if (mod === modes.view && canEdit && !isDisableEditStatus) {
			result.push(
				<Button key="edit" type="primary" onClick={onEdit} disabled={isPendingReq}>
					Редактировать
				</Button>
			)
		}
		if (mod === modes.edit) {
			result.push(
				<Button key="cancel" type="primary" onClick={onCancel} disabled={isPendingReq}>
					Отмена
				</Button>
			)
		}
		return result
	}
	return (
		<Space size="middle">
			{getButtons()}
			{[modes.view, modes.new].includes(mod) && (
				<Button
					type="text"
					style={{ lineHeight: 0.8 }}
					icon={<CloseIcon />}
					onClick={onClose}
					disabled={isPendingReq}
				/>
			)}
		</Space>
	)
}

export default ActionButtons
