import { Table } from 'antd'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext, ListContext } from '../../contexts'
import { applyFilterForList, goToItem, isAcceptedByFilters, openNewTab } from '../../utils'
import { showConfirmModal } from '..'
import moment from 'moment'
import rowClassName from '../../utils/helpers/rowClassName'

const StatefulTable = ({
	dataSource,
	searchFields,
	filterStatuses,
	columns,
	url,
	canViewRow = true,
	prepareDataFns,
	highlight,
	columnProps,
	...props
}) => {
	const {
		state: {
			textFilter,
			page,
			mainFilter,
			filters,
			sorter,
			dateFrom,
			dateTo,
			isInStock,
			total,
			pageSize,
		},
		setPage,
		setFilters,
		setSorter,
	} = useContext(ListContext)
	const [rowId, setRowId] = useState('')
	const authCtx = useContext(AuthContext)
	const initialDataSource = useMemo(() => dataSource, [dataSource])
	const getCurDs = useCallback(
		(d) => {
			let data = d
			if (prepareDataFns?.length) {
				for (const fn of prepareDataFns) {
					data = fn(data)
				}
			}
			if (dateFrom && dateTo) {
				data = data.filter((el) =>
					moment(el.date).isBetween(moment(dateFrom), moment(dateTo), 'day', '[]')
				)
			}
			if (isInStock && filterStatuses) {
				data = data.filter((el) => filterStatuses.includes(el.status))
			}
			if (Object.values(filters).some((v) => v && v.length)) {
				data = data.filter((d) => isAcceptedByFilters(d, filters))
			}
			return applyFilterForList(data, {
				search: textFilter,
				searchFields: searchFields || [],
			})
		},
		[dateFrom, dateTo, filterStatuses, filters, isInStock, textFilter, prepareDataFns, searchFields]
	)
	const history = useHistory()
	const [cds, setCds] = useState(initialDataSource)
	const didMountRef = useRef(false)
	useEffect(() => {
		if (!didMountRef.current) {
			didMountRef.current = true
		}
	}, [])

	const columnsMemo = useMemo(() => {
		return columns({ cds, ...columnProps }).map((col) => {
			if (col.children) {
				col.children.forEach((c) => {
					c.filteredValue =
						mainFilter[c.dataIndex] ||
						mainFilter[c.key] ||
						filters[c.dataIndex] ||
						filters[c.key] ||
						[]
					c.sortOrder = [c.dataIndex, c.key].includes(sorter.field) && sorter.order
				})
				return col
			}
			return {
				...col,
				filteredValue:
					mainFilter[col.dataIndex] ||
					mainFilter[col.key] ||
					filters[col.dataIndex] ||
					filters[col.key] ||
					[],
				sortOrder: [col.dataIndex, col.key].includes(sorter.field) && sorter.order,
			}
		})
	}, [columns, cds, columnProps, mainFilter, filters, sorter.field, sorter.order])

	useEffect(() => {
		let data = dataSource
		if (prepareDataFns?.length) {
			for (const fn of prepareDataFns) {
				data = fn(data)
			}
		}
		if (dateFrom && dateTo) {
			data = dataSource.filter((el) =>
				moment(el.date).isBetween(moment(dateFrom), moment(dateTo), 'day', '[]')
			)
		}
		setCds(
			applyFilterForList(data, {
				search: textFilter,
				searchFields: searchFields || [],
			})
		)
	}, [
		dataSource,
		dateFrom,
		dateTo,
		filterStatuses,
		isInStock,
		textFilter,
		prepareDataFns,
		searchFields,
	])
	return (
		<Table
			rowKey={(record) => (record.idStorage ? `${record.id}-${record.idStorage}` : record.id)}
			size="small"
			rowClassName={rowClassName(rowId)}
			dataSource={didMountRef.current ? getCurDs(initialDataSource) : []}
			columns={columnsMemo}
			scroll={{ x: 800 }}
			pagination={{
				pageSize,
				showSizeChanger: false,
				current: page,
				total: total,
				...props.pagination,
			}}
			onChange={(pagination, filters, sorter, { currentDataSource }) => {
				setPage(pagination.current)
				setFilters(filters)
				setSorter(sorter)
				setCds(getCurDs(currentDataSource))
			}}
			onRow={(data) => ({
				onClick: (e) => {
					if (e.target.className === 'ant-checkbox-input' && !e.target.disabled) {
						return
					}
					if (!canViewRow)
						return showConfirmModal({
							title:
								'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
							okText: 'Ок',
							width: 450,
							showCancel: false,
						})
					if (data.id) {
						if (e.ctrlKey) {
							openNewTab(`${url}/${data.id}`, {
								authCtx: authCtx.state,
							})
							return
						}
						goToItem(history, { url, id: data.id }, data)
					}
				},
				onMouseEnter: () => {
					if (url) setRowId(data.idStorage || data.id)
				},
				onMouseLeave: () => {
					if (url) setRowId('')
				},
			})}
			{...props}
		/>
	)
}

export default StatefulTable
