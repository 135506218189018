import { useContext } from 'react'
import { Button, Card, Checkbox, Col, Modal, Row, Typography } from 'antd'
import { RequestItemMainContext } from '../provider/main'
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close.svg'
import { getColumns } from '../../../Contract/ContractsProvidersList/contract-provider-list'
import { showConfirmModal, TableWithListLoader } from '../../../../components'

const SelectContractModal = () => {
	const mainCtx = useContext(RequestItemMainContext)

	return (
		<Modal
			visible={mainCtx.addContract.__isOpen}
			destroyOnClose
			centered
			bodyStyle={{ paddingTop: '10px' }}
			footer={null}
			closable={false}
			width="90%"
		>
			<Card
				bodyStyle={{
					margin: 0,
					padding: 0,
				}}
				bordered={false}
			>
				<Row gutter={[16, 16]} style={{ padding: '0 0 10px 0' }}>
					<Col flex={1}>
						<Typography.Title level={4} style={{ margin: 0 }}>
							Договор
						</Typography.Title>
					</Col>
					<Col>
						<Button
							type="primary"
							onClick={() => {
								try {
									mainCtx.validateContract()
									for (const contract of mainCtx.addContract.addContractContracts) {
										mainCtx.stateFunctions.products.create({ contract })
									}
									mainCtx.modalFunctions.contractModalFunctions.resetAddContract()
								} catch (err) {
									return showConfirmModal({
										title: err.message,
										showCancel: false,
										okText: 'Ок',
									})
								}
							}}
						>
							Сохранить
						</Button>
					</Col>
					<Col>
						<Button
							type="text"
							style={{ lineHeight: 0.8 }}
							icon={<CloseIcon />}
							onClick={() => mainCtx.modalFunctions.contractModalFunctions.resetAddContract()}
						/>
					</Col>
				</Row>
			</Card>
			<TableForSelectComplect mainCtx={mainCtx}></TableForSelectComplect>
		</Modal>
	)
}
export default SelectContractModal

const TableForSelectComplect = (props) => {
	const mainCtx = useContext(RequestItemMainContext)
	const modalFunctions = mainCtx.modalFunctions.contractModalFunctions
	let url = null
	let calcColumns = null
	let filters = {}
	let defaultSorter = null
	let permanentFilters = {}

	url = '/rm/contracts-providers'
	calcColumns = (props) => {
		const columns = getColumns(props)
		return [
			{
				key: 'checkbox',
				width: '2%',
				render: (_, record) => (
					<Checkbox
						checked={mainCtx.addContract.addContractContracts?.find((c) => c.id === record.id)}
						onChange={(e) => {
							if (e.target.checked) {
								return modalFunctions.addContractContracts.create(record)
							}
							return modalFunctions.addContractContracts.delete(
								mainCtx.addContract.addContractContracts.find((c) => c.id === record.id)?._uuid_
							)
						}}
					/>
				),
			},
			...columns,
		]
	}
	filters = {}
	defaultSorter = {}
	permanentFilters = {
		include: { providerQuotas: ['isArrOrStrLength'] },
		exclude: {},
	}

	return (
		<TableWithListLoader
			url={url}
			calcColumns={calcColumns}
			filters={filters}
			defaultSorter={defaultSorter}
			permanentFilters={permanentFilters}
			rowKey={(rec) => {
				return `${rec.id || rec._uuid_}_${rec.indxMain}_${
					rec.isDiplayedRowBySpan ? 'displ' : 'not_displ'
				}`
			}}
		></TableWithListLoader>
	)
}
