import { getColumnSearchProps } from '../../../../utils'
import {
	RightSquareOutlined,
	BlockOutlined,
	UndoOutlined,
	StopOutlined,
	RotateRightOutlined,
} from '@ant-design/icons'
import { Row } from 'antd'
import { colorizeIntegrationListStatus } from '../../../../utils/helpers/for-integration'
import { LeftTooltip, ShelfLifeDateCell, Status } from '../../../../components'
import clickableStatus from '../../../../utils/helpers/clickableStatus'
import { dateFormat } from '../../../../utils/constants/common'
import moment from 'moment'

/**
 * @param {Object} inArr - массив с записями таблицы
 */

export const getColumns = (props) => {
	const {
		mainCtx,
		canEdit,
		fromServerFilters,
		frontFilters,
		sorter,
		authCtx,
		userDataCtx,
		stockNotify,
	} = props
	let items = [
		{
			title: 'ID ДЗ',
			dataIndex: 'idContrOrder',
			sorter: true,
			sortOrder: sorter.columnKey === 'idContrOrder' && sorter.order,
			key: 'idContrOrder',
			width: '2.81%',
			...getColumnSearchProps(fromServerFilters.idContrOrder, frontFilters, 'idContrOrder'),
		},

		{
			title: 'Партия №',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '8%',
			sorter: true,
			sortOrder: sorter.columnKey === 'partyNum' && sorter.order,
			render: (_, record) => record.partyNum,
			...getColumnSearchProps(fromServerFilters.partyNum, frontFilters, 'partyNum'),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			...getColumnSearchProps(fromServerFilters.articul, frontFilters, 'articul'),
			key: 'articul',
			width: '5%',
		},
		{
			title: 'Артикул 1C',
			dataIndex: 'articul1C',
			...getColumnSearchProps(fromServerFilters.articul1C, frontFilters, 'articul1C'),
			key: 'articul1C',
			width: '5%',
		},
		{
			title: 'Продукт',
			dataIndex: 'prodCat',
			key: 'prodCat',
			...getColumnSearchProps(fromServerFilters.prodCat, frontFilters, 'prodCat'),
			sorter: true,
			sortOrder: sorter.columnKey === 'prodCat' && sorter.order,
			width: '5%',
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKind',
			key: 'prodCatKind',
			width: '21%',
			sorter: true,
			sortOrder: sorter.columnKey === 'prodCatKind' && sorter.order,
			...getColumnSearchProps(fromServerFilters.prodCatKind, frontFilters, 'prodCatKind'),
		},
		{
			title: 'Дата производства',
			dataIndex: 'taskDate',
			key: 'taskDate',
			width: '7%',
			render: (taskDate) => moment(taskDate).format(dateFormat),
			...getColumnSearchProps(fromServerFilters.taskDate, frontFilters, 'taskDate', true),
		},
		{
			title: 'Годен до',
			dataIndex: 'shelfLifeDate',
			key: 'shelfLifeDate',
			width: '5.87%',
			render: (date) => <ShelfLifeDateCell date={date} stockNotify={stockNotify} />,
			sorter: true,
			sortOrder: sorter.columnKey === 'shelfLifeDate' && sorter.order,
		},

		{
			title: '№ пом',
			dataIndex: 'roomNum',
			key: 'roomNum',
			width: '6%',
			...getColumnSearchProps(fromServerFilters.roomNum, frontFilters, 'roomNum'),
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '6%',
			sorter: true,
			sortOrder: sorter.columnKey === 'weight' && sorter.order,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '6%',
			...getColumnSearchProps(fromServerFilters.status, frontFilters, 'status'),
			onCell: clickableStatus(authCtx, {}, userDataCtx),
			render: (status, record) => {
				if (['Удержано', 'Заблокировано'].includes(status)) {
					return (
						<div style={{ display: 'flex' }}>
							<LeftTooltip
								label={''}
								tooltip={
									<span style={{ whiteSpace: 'pre-wrap', wdith: '100%' }}>
										{record.blockCauses.map((p) => p.blockCause.label + '\n').join('')}
									</span>
								}
							/>
							<Status style={{ marginLeft: '3px' }} status={status} />
						</div>
					)
				}
				return colorizeIntegrationListStatus(record.status, false, {
					marginLeft: '17px',
				})
			},
		},
		{
			title: '1C',
			dataIndex: '_1c',
			key: '_1c',
			width: '3%',
			render: (__1C) => {
				if (__1C === 'Не зарегистрирован в 1С') {
					return (
						<StopOutlined
							style={{ fontSize: 19, color: 'rgb(207,9,9)' }}
							title="Не зарегистрирован в 1С"
							onClick={(e) => {}}
						/>
					)
				}
			},
			...getColumnSearchProps(fromServerFilters._1c, frontFilters, '_1c'),
		},
		...(canEdit
			? [
					{
						title: '',
						dataIndex: 'sale',
						key: 'sale',
						width: '5%',
						render: (_, record) =>
							[
								'Допущено',
								'Удержано',
								'Заблокировано',
								'Дефростация',
								'Брак',
								'Отклонено 1С',
								'Ошибка отправки в 1С',
							].includes(record.status) && canEdit ? (
								<Row style={{ justifyContent: 'space-around', fontSize: 21, flexWrap: 'nowrap' }}>
									{['Допущено', 'Удержано', 'Заблокировано'].includes(record.status) &&
										record._1c !== 'Не зарегистрирован в 1С' && (
											<RotateRightOutlined
												title="Смена статуса партии"
												className={'pictogram'}
												onClick={(e) => {
													e.stopPropagation()
													mainCtx.modalFunctions.openChangeStatusModal()
													mainCtx.modalFunctions.setChangeStatusModalStorage(record)
												}}
											/>
										)}
									{['Отклонено 1С', 'Ошибка отправки в 1С'].includes(record.status) ? (
										<UndoOutlined
											title="Вернуть статус"
											className={'pictogram'}
											onClick={(e) => {
												e.stopPropagation()
												mainCtx.dropStatus(record)
											}}
										/>
									) : ['Утверждена', 'Принято в 1С', 'Ожидает отправки'].includes(
											record.taskRep.task.status
									  ) ? (
										<>
											<RightSquareOutlined
												title="Перемещение"
												className={'pictogram'}
												onClick={(e) => {
													e.stopPropagation()
													mainCtx.modalFunctions.openRelocationModal()
													mainCtx.modalFunctions.setRelocationModalStorage(record)
												}}
											/>
											<BlockOutlined
												title="Перетарка"
												className={'pictogram'}
												onClick={(e) => {
													e.stopPropagation()
													mainCtx.modalFunctions.openRepackingModal()
													mainCtx.modalFunctions.setRepackingModalStorage(record)
												}}
											/>
										</>
									) : null}{' '}
								</Row>
							) : null,
					},
			  ]
			: []),
	]
	return items
}

const xlsxColumnTitles = ['Партия №', 'Артикул', 'Артикул 1C', 'Продукт', 'Вид', 'Масса, кг']

export const getToXlsx = (dataSource, columns = [], renameColumnDataIndexToKey = []) => {
	if (renameColumnDataIndexToKey.length)
		columns.forEach((c) => {
			if (renameColumnDataIndexToKey.includes(c.dataIndex)) c.dataIndex = c.key
		})
	return {
		dataSource,
		columns: columns.filter((c) => xlsxColumnTitles.includes(c.title)),
		tableName: 'Реестр запасов ПФ',
		fileName: 'Реестр_запасов_ПФ.xlsx',
	}
}
