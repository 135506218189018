import { getProdCatCaliber } from '@berry/common-functions/cross-project-functions'
import { basicValidator } from '@berry/common-functions/validators'
import { axios } from '../../utils'
import moment from 'moment'
import { dateFormat } from '../constants/common'
/**
 * вычисляет артикул упаковки продукта из прод каталога
 * @param {Object} inRecord
 * @param {Object} mainCtx
 * @param {Object} dataServerCtx
 */
export const getProdCatPkgDisplayCode = (inRecord, prodCat, allProdCats = []) => {
	return `${
		getProdCatKindReadyProdCode(
			prodCat.displayCode || allProdCats.length,
			inRecord.kindReadyProd
		) || ''
	}-${inRecord.vocQuality?.displayCode || ''}-${inRecord.vocContType?.displayCode}${
		inRecord.weight
	}`
}

/**
 * вычисляет артикул вида готовой продукции продукта из прод каталога
 * @param {number} inDisplayCode
 * @param {Object} inRecord
 */
export const getProdCatKindReadyProdCode = (inDisplayCode, inRecord) => {
	const caliberDisplayCode = inRecord.caliber?.displayCode || ''
	let additAttr = inRecord.additAttr?.displayCode || ''
	if (additAttr !== '') additAttr = `/${additAttr}`
	return `${inDisplayCode || ''}${additAttr}.${inRecord.vocReadyProdForm?.displayCode || ''}${
		caliberDisplayCode ? '.' + caliberDisplayCode : ''
	}`
}

/**
 * вычисляет наименование упаковки
 * @param {string} inLabel
 * @param {Object} inRecord
 */
export const getProdCatPackageLabel = (inRecord) => {
	if (!inRecord) return ''
	return `${getProdCatKindReadyProdLabel(inRecord?.kindReadyProd)} - ${
		inRecord?.vocQuality?.label
	} - ${inRecord?.vocContType?.labelShort} ${inRecord?.weight} кг`
}

/**
 * получить наименование вида готовой продукции
 * @param {number} inId
 * @param {Object} inRecord
 * @param {Object} mainCtx
 */
const getProdCatKindReadyProdLabel = (inRecord) => {
	let precalculatedCaliber = getProdCatPrecalculatedCaliber(inRecord)
	let caliber = ''
	if (inRecord.vocReadyProdForm?.isBindCaliber) {
		caliber =
			(precalculatedCaliber?.slice(0, precalculatedCaliber.indexOf('.') + 1) || '') +
			' ' +
			(precalculatedCaliber?.slice(precalculatedCaliber.indexOf('.') + 1) || '')
		return `${inRecord.additAttr?.label || ''} б/з ${inRecord.vocReadyProdForm?.label || ''}${
			caliber !== ' ' ? ' ' + caliber : ''
		}`
	}
	return `${inRecord.additAttr?.label || ''} б/з ${
		inRecord.vocReadyProdForm?.labelShort || ''
	} ${caliber}`
}

/**
 * получить наименование калибра
 * @param {Object} inRecord
 */
export const getProdCatPrecalculatedCaliber = (inRecord = {}) => {
	let precalculatedCaliber = ''
	if (inRecord?.caliber?.id === -1 || inRecord?.isNotCalibratedCaliber) {
		precalculatedCaliber = 'некалиброван.'
	} else {
		if (basicValidator(inRecord?.caliber)) {
			precalculatedCaliber = `калиброван. ${getProdCatCaliber(inRecord?.caliber)}`
		}
	}
	return precalculatedCaliber
}

/**
 * поиск процеесса в зависимости от вида в справочникуе основных пф
 * @param {number} inKindId
 * @param {Object} mainCtx - основной контекст
 */
export const findProdCatVocMainSemiKind = (inKindId, mainCtx) => {
	const found = mainCtx.additional.allVocMainSemifKinds.find((e) =>
		e.kinds.some((k) => +k.id === +inKindId)
	)
	return found || null
}

/**
 * вычисляет артикул
 * @param {number} inId
 * @param {Object} inRecord
 */
export const getProdCatKindRawMatsDisplayCode = (idDisplayCode, inRecord) => {
	return `${idDisplayCode || ''}.С${inRecord.vocTempRegime?.displayCode || ''}${
		inRecord.vocRawMatState?.displayCode || ''
	}`
}

/**
 * вычисляент ставится ли галочка Давалец (Д ) у задачи на производство
 */
export const calcProdTaskIsTolling = (inTask, isEmptyTask = false) => {
	if (isEmptyTask && inTask === undefined) return
	return !!(inTask?.complectations || inTask?.productions || [])[0]?.stockRawMatStor?.stockRawMat
		?.supplProd?.contrQuota?.contract?.tolling
}

export const getTaskRepTypeByStock = (inData) => {
	if (inData.taskRep) {
		return inData.taskRep.type
	}
	if (inData.stockSemif) return inData.stockSemif?.taskRep.type
	if (inData.stockReadyProd) return inData.stockReadyProd?.taskRep.type
	return null
}

/**
 * получить давальца
 */
export const getStockProvider = (inTask) => {
	let stockStor =
		inTask.complectations?.[0]?.stockRawMatStor ||
		inTask.complectations?.[0]?.stockSemifStor ||
		inTask.complectations?.[0]?.stockReadyProdStor ||
		inTask.productions?.[0]?.stockRawMatStor ||
		inTask.productions?.[0]?.stockSemifStor ||
		inTask.productions?.[0]?.stockReadyProdStor

	return (
		stockStor.stockRawMat.supplProd.contrQuota.contract ||
		stockStor.stockSemif.supplProd.contrQuota.contract
	)
}

/**
 * подготавливает опции и тело запроса для мод окна продажа
 */
export const prepareParamsForStockSaleModal = (inModalData, params) => {
	const options = { dataUrl: params.dataUrl, url: params.dataUrl + '/sell', method: 'PUT' }
	let body = {}
	let item = null
	if (inModalData.storage) {
		item = inModalData.storage.storages.find((el) => el.id === inModalData.storage.idStorage)
	}
	if (inModalData.weight === inModalData.storage.weight.toString()) {
		body = {
			id: inModalData.storage.id,
			storages: [
				{
					id: inModalData.storage.idStorage,
					weight: inModalData.weight,
					status: 'На оформлении в 1С',
					oldStatus: item.status,
					newStatus: 'Продажа',
					date: moment(),
				},
			],
			to1cData: {
				customer: inModalData.customer,
				isForSaleDepartment: inModalData.isForSaleDepartment,
			},
		}
	}
	if (+inModalData.weight < +inModalData.storage.weight) {
		body = {
			id: inModalData.storage.id,
			storages: [
				{
					id: inModalData.storage.idStorage,
					newWeight: inModalData.storage.weight - inModalData.weight,
					status: 'На оформлении в 1С',
					oldStatus: item.status,
					newStatus: item.status,
				},
				{
					...item,
					weight: inModalData.weight,
					date: moment(),
					status: 'Продажа',
					isPermanent: false,
				},
			],
			to1cData: {
				customer: inModalData.customer,
				isForSaleDepartment: inModalData.isForSaleDepartment,
			},
		}
		delete body.storages[1].id
	}
	return { options, body }
}

/**
 * Получает задачу с поставкой
 */

export const getTaskWithStockRawMatCompl = (complectation, mainCtx, tasksForComplectations) => {
	if (!complectation) return
	if (complectation.idStockRawMatStor || complectation.stockRawMatStor?.id)
		return mainCtx?.state?.taskRep?.task?.complectations?.[0] || complectation
	let curTask = ''
	const tasks = mainCtx?.selectors?.tasksForComplectations || tasksForComplectations

	curTask = tasks?.find((t) => {
		if (
			complectation.stockSemifStor?.stockSemif?.taskRep?.idProductionTask === t.id ||
			complectation.stockSemifStor?.stockSemif?.taskRep?.task?.id === t.id
		)
			return t
		if (
			complectation.stockReadyProdStor?.stockReadyProd?.taskRep?.idProductionTask === t.id ||
			complectation.stockReadyProdStor?.stockReadyProd?.taskRep?.task?.id
		)
			return t
		return undefined
	})
	if (
		(!curTask?.complectations?.[0]?.idStockRawMatStor ||
			!curTask?.complectations?.[0]?.stockRawMat?.id) &&
		curTask?.complectations?.length
	) {
		curTask = getTaskWithStockRawMatCompl(
			curTask?.complectations[0],
			mainCtx,
			tasksForComplectations
		)
	}
	return curTask?.complectations?.[0]
}

export const getImplmntOrderId = (prod, allProdTasks) => {
	if (!prod) {
		return null
	}
	if (prod.stockRawMatStor) {
		return prod.stockRawMatStor.stockRawMat.supplProd.idContrOrder
	}
	const { taskRep, idProdTaskRep, idContrOrder, isCreatedFromBal } =
		prod.stockReadyProdStor?.stockReadyProd ||
		prod.stockSemifStor?.stockSemif ||
		prod.stockWasteStor?.stockWaste ||
		prod.stockSampleStor?.stockSample ||
		{}

	if (idContrOrder || isCreatedFromBal) {
		return idContrOrder
	}
	if (!taskRep && !idProdTaskRep) {
		return null
	}
	const id =
		taskRep?.idProductionTask ||
		allProdTasks.find((el) => el.reports.some((rep) => rep.id === idProdTaskRep)).id
	const parentTask = allProdTasks.find((task) => task.id === id)
	if (!parentTask.complectations.some((c) => c.stockRawMatStor)) {
		return getImplmntOrderId(parentTask.complectations[0], allProdTasks)
	}
	return (
		parentTask.complectations.find((c) => c.stockRawMatStor)?.stockRawMat?.supplProd
			?.idContrOrder || null
	)
}

/**
 *  Получает все данные для excel c учетом фильтров
 */

export const getAllDataForXl = async (
	dataUrl,
	filters,
	mainFilter,
	isInStock,
	weightFrom,
	weightTo
) => {
	const fromServerResp = await axios(dataUrl, {
		params: { allDataForXl: true, filters, mainFilter, isInStock, weightFrom, weightTo },
	})
	return fromServerResp
}

/**
 *  Плучаетс статус заказа
 */

export const getOrderStatus = (record, allRequests) => {
	const forStatusInWorkLength = allRequests
		?.map((req) => req.products)
		.flat()
		?.filter((el) => el.order)
		?.map((p) => {
			if (p?.order?.id === record.id) return p
			return undefined
		})
		?.filter((el) => el)?.length
	if (
		forStatusInWorkLength &&
		record.status !== 'Новый' &&
		['Ошибка отправки в 1С', 'Отправляется в 1С', 'Отправлено в 1С', 'Выполнен', 'Новый'].includes(
			record.status
		)
	)
		return record.status
	if (forStatusInWorkLength) return 'В работе'

	return record.status
}

/**
 * подготавливает опции и тело запроса для мод окна продажа
 */
export const prepareParamsForStockRelocationModal = (inModalData, params, vocProduct1C) => {
	const options = { dataUrl: params.dataUrl, url: params.dataUrl + '/relocation', method: 'PUT' }
	let item = null
	let body = {}
	if (inModalData.storage) {
		item = inModalData.storage.storages.find((el) => el.id === inModalData.storage.idStorage)
	}

	if (+inModalData.weight === +inModalData.storage.weight) {
		body = {
			id: inModalData.storage.id,
		}
		if (inModalData.isWriteOff) {
			body.storages = [
				{
					id: inModalData.storage.idStorage,
					newVocContType: item.vocContType || item.contType,
					newRoom: inModalData.roomNum,
					date: moment(),
					oldStatus: item.status,
					newStatus: params.status,
					status: params.status,
				},
			]
			body.newDefrostCount =
				item.status === 'Дефростация' && !inModalData.isDefrost && !inModalData.isWriteOff
					? inModalData.storage.defrostCount + 1
					: inModalData.storage.defrostCount
			let bodyForWaste
			if (inModalData?.isWriteOff) {
				bodyForWaste = {
					id: null,
					...inModalData.storage,
					storages: [
						{
							...item,
							isPermanent: false,
							weight: inModalData.weight,
							room: inModalData.roomNum,
							vocContType: item.vocContType || item.contType,
							date: moment(),
							status: 'Хранение',
						},
					],
					[params.wasteKey]: inModalData.storage.id,
					parties1Cs: [
						{
							vocProd1c: vocProduct1C.find((e) =>
								[inModalData.storage?.articul1C, inModalData.storage?.vendorCode].includes(
									e.vendorCode
								)
							),

							partyNum: inModalData.storage?.assignedPartyNum || inModalData.storage?.partyNum,
							weight: inModalData.weight,
							date: moment(),
						},
					],
				}
			}
			body = { ...body, waste: bodyForWaste }
		} else {
			body.storages = [
				{
					id: inModalData.storage.idStorage,
					room: inModalData.roomNum,
					date: moment(),
					status: params.status,
				},
			]
			body.defrostCount =
				item.status === 'Дефростация' && !inModalData.isDefrost && !inModalData.isWriteOff
					? inModalData.storage.defrostCount + 1
					: inModalData.storage.defrostCount
		}
	}
	if (+inModalData.weight < +inModalData.storage.weight) {
		body = {
			id: inModalData.storage.id,
		}
		if (inModalData.isWriteOff) {
			body.storages = [
				{
					id: inModalData.storage.idStorage,
					weight: inModalData.storage.weight - inModalData.weight,
					oldWeight: inModalData.storage.weight,
				},
				{
					idParent: inModalData.storage.idStorage,
					isPermanent: false,
					vocContType: item.vocContType || item.contType,
					weight: inModalData.weight,
					room: inModalData.roomNum,
					date: moment(),
					newStatus: params.status,
					status: 'На оформлении в 1С',
				},
			]
			body.newDefrostCount =
				item.status === 'Дефростация' && !inModalData.isDefrost && !inModalData.isWriteOff
					? inModalData.storage.defrostCount + 1
					: inModalData.storage.defrostCount
			let bodyForWaste
			if (+inModalData.weight < +inModalData.storage.weight && inModalData.isWriteOff) {
				bodyForWaste = {
					id: null,
					...inModalData.storage,
					storages: [{ ...body.storages[1], status: 'Хранение' }],
					[params.wasteKey]: inModalData.storage.id,
					parties1Cs: [
						{
							vocProd1c: vocProduct1C.find((e) =>
								[inModalData.storage?.articul1C, inModalData.storage?.vendorCode].includes(
									e.vendorCode
								)
							),

							partyNum: inModalData.storage?.assignedPartyNum || inModalData.storage?.partyNum,
							weight: inModalData.weight,
							date: moment(),
						},
					],
				}
				body = { ...body, waste: bodyForWaste }
			}
		} else {
			body.storages = [
				{
					id: inModalData.storage.idStorage,
					weight: inModalData.storage.weight - inModalData.weight,
				},
				{
					...item,
					weight: inModalData.weight,
					room: inModalData.roomNum,
					date: moment(),
					status: inModalData?.isWriteOff
						? 'Списано'
						: inModalData?.isDefrost
						? 'Дефростация'
						: item.status,
				},
			]
			body.defrostCount =
				item.status === 'Дефростация' && !inModalData.isDefrost && !inModalData.isWriteOff
					? inModalData.storage.defrostCount + 1
					: inModalData.storage.defrostCount
		}
		delete body.storages[1].id
		delete body.storages[1].idVocRoom
	}

	return { options, body }
}

/**
 * рассчет № протокола испытаний
 * @param {Object} mainCtx
 */
export const calcTestReportNum = async (products, { date, index = 0 } = {}) => {
	let num
	const response = await axios('/rm/supplies/get-report-nums', {
		params: {
			date: moment(date).format(dateFormat),
		},
	})
	if (!response.data?.reportNums.length) {
		num = 1
	} else {
		num = Math.max(...response.data.reportNums.map((num) => num.split('.')[1])) + 1
	}
	const newProductsLength = products.filter((p) => p.id === undefined).length
	const [day, month, year] = (date ? moment(date) : moment()).format(dateFormat).split('.')
	return `${year.slice(-2)}${month}${day}.${num + newProductsLength + index}`
}
