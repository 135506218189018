import { Button, Form, Space, Spin } from 'antd'
import React, { useContext } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg'
// import { ReactComponent as PrintIcon } from '../../../../assets/svg/print.svg'
import { CopyOutlined } from '@ant-design/icons'
import { handleCancelMatch, modes } from '../../utils'
import { showCancelMatchModal } from '../../utils/constants/for-components'
import { ModContext, UserDataContext } from '../../contexts'
import { getIsDoneMatch } from './utils'
import { showConfirmModal, LoadingButton } from '../../components'
import { checkRole } from '@berry/common-functions/role'
import { getRole } from '@berry/common-functions/cross-project-functions'

const CommonMatchActionButtons = (props) => {
	const {
		isPendingReq = false,
		formName,
		onSave = () => {},
		onEdit = () => {},
		onCancel = () => {},
		onDelete = () => {},
		onCopy,
		onClose = () => {},
		canEdit,
		canDelete,
		rights = {},
		ctx,
		matchObject,
		originalStatus = 'Не утверждено',
		additionalButtonsPre = [],
		allowMatchOrApproveForTask,
	} = props
	const {
		serverMatch,
		stateFunctions: { setStatus },
		serverEdit,
	} = ctx

	const { status, eventHistories = [], level } = ctx.state.data ? ctx.state.data : ctx.state
	const { mod } = useContext(ModContext)
	const {
		state: { match = {}, rights: currentRights, isDevAdmin },
	} = useContext(UserDataContext)
	const [form] = Form.useForm()

	const {
		role,
		currentMatchLvl = null,
		currentApproveLvl = null,
	} = getRole(match[ctx.isProdTaskPf ? 'taskPf' : 'task'])

	const getButtons = () => {
		if (allowMatchOrApproveForTask && !allowMatchOrApproveForTask.isLoaded && mod !== modes.new)
			return <Spin />
		const isApproved = ['Утверждено', 'Утвержден'].includes(status)
		const isNotApproved = [originalStatus, 'Отклонено 1С'].includes(status)
		const isApproving = ['На утверждении', 'На согласовании'].includes(status)
		const isArchived = status === 'В архиве'
		const isDoneMatch = getIsDoneMatch(eventHistories)
		const canMatch = currentMatchLvl && currentMatchLvl === level && !isDoneMatch
		const canApprove = currentApproveLvl && currentApproveLvl === level
		const hasRights = checkRole(rights, currentRights)
		const showDelBtn = canDelete && !isArchived
		const result = []
		if ([modes.edit, modes.new].includes(mod)) {
			result.push(
				<LoadingButton
					key="save"
					onClick={onSave}
					htmlType="submit"
					form={formName}
					isPendingReq={isPendingReq}
				>
					Сохранить
				</LoadingButton>
			)
		}
		if (mod === modes.view && hasRights && canEdit && !isApproved && !isApproving && !isArchived) {
			result.push(
				<Button key="edit" type="primary" onClick={onEdit} disabled={isPendingReq}>
					Редактировать
				</Button>
			)
		}
		if (mod === modes.view && hasRights && !isArchived && isApproved) {
			result.push(
				<Button
					key="archive"
					type="primary"
					onClick={() => {
						setStatus('В архиве')
						serverEdit()
					}}
					disabled={isPendingReq}
				>
					В архив
				</Button>
			)
		}
		if (mod === modes.edit) {
			result.push(
				<Button key="cancel" type="primary" onClick={onCancel} disabled={isPendingReq}>
					Отмена
				</Button>
			)
		}
		if (mod === modes.view && hasRights && canEdit && showDelBtn && !isApproving) {
			result.push(
				<Button key="delete" type="primary" onClick={onDelete} disabled={isPendingReq}>
					Удалить
				</Button>
			)
		}
		if (mod === modes.view && (hasRights || isDevAdmin) && onCopy) {
			result.push(
				<Button
					key="copy"
					type="text"
					icon={<CopyOutlined style={{ fontSize: 28, color: '#08c' }} />}
					onClick={onCopy}
					title="Создать копию"
					disabled={isPendingReq}
				/>
			)
		}
		switch (true) {
			case role === 'matcher' &&
				canMatch &&
				status === 'На согласовании' &&
				(!allowMatchOrApproveForTask || allowMatchOrApproveForTask.isAllowMatchOrApproveForTask) &&
				mod !== modes.edit:
				return [
					checkRole(
						{
							'АРМ офиса': {
								'Задачи на производство': {
									'Изменить статус': {
										edit: true,
									},
								},
							},
						},
						currentRights
					) &&
						['taskPf', 'task'].includes(matchObject) && (
							<Button key="status" type="primary" onClick={onEdit} disabled={isPendingReq}>
								Изменить статус
							</Button>
						),
					<LoadingButton
						isPendingReq={isPendingReq}
						key="match"
						onClick={() => serverMatch({ event: 'Согласовано', currentBtn: 'match' })}
					>
						Согласовать
					</LoadingButton>,
					<LoadingButton
						isPendingReq={isPendingReq}
						key="dismatch"
						onClick={() => {
							return handleCancelMatch(
								serverMatch,
								form,
								(values) =>
									serverMatch({
										event: 'Не утверждено',
										cause: values.cause,
										currentBtn: 'dismatch',
										idVocRejectReason: values.idVocRejectReason,
									}),
								ctx,
								matchObject
							)
						}}
					>
						Вернуть на доработку
					</LoadingButton>,
				]
			case role === 'approver' &&
				canApprove &&
				status === 'На утверждении' &&
				(!allowMatchOrApproveForTask || allowMatchOrApproveForTask.isAllowMatchOrApproveForTask) &&
				mod !== modes.edit:
				return [
					checkRole(
						{
							'АРМ офиса': {
								'Задачи на производство': {
									'Изменить статус': {
										edit: true,
									},
								},
							},
						},
						currentRights
					) &&
						['taskPf', 'task'].includes(matchObject) && (
							<Button key="status" type="primary" onClick={onEdit} disabled={isPendingReq}>
								Изменить статус
							</Button>
						),
					<LoadingButton
						isPendingReq={isPendingReq}
						key="approve"
						onClick={() => serverMatch({ event: 'Утверждено', currentBtn: 'approve' })}
					>
						Утвердить
					</LoadingButton>,
					<LoadingButton
						isPendingReq={isPendingReq}
						key="disapprove"
						onClick={() => {
							return handleCancelMatch(
								serverMatch,
								form,
								(values) =>
									serverMatch({
										event: 'Не утверждено',
										cause: values.cause,
										currentBtn: 'disapprove',
										idVocRejectReason: values.idVocRejectReason,
									}),
								ctx,
								matchObject
							)
						}}
					>
						Вернуть на доработку
					</LoadingButton>,
				]
			case role === 'initiator' && isNotApproved:
				if (mod === modes.view) {
					result.unshift(
						<LoadingButton
							isPendingReq={isPendingReq}
							key="init"
							onClick={() =>
								serverMatch({
									status: 'На согласовании',
									event: 'Инициация согласования',
									currentBtn: 'init',
								})
							}
						>
							На согласование
						</LoadingButton>
					)
				}
				break
			case role === 'initiator' && isApproving:
				result.unshift(
					<LoadingButton
						isPendingReq={isPendingReq}
						key="cancel-match"
						onClick={() =>
							showConfirmModal({
								...showCancelMatchModal,
								onOk: () =>
									serverMatch({
										status: originalStatus,
										event: 'Отмена согласования',
										currentBtn: 'cancel-match',
									}),
							})
						}
					>
						Отменить согласование
					</LoadingButton>
				)
				break
			default:
				return result
		}
		return result
	}
	return (
		<Space size="middle">
			{additionalButtonsPre}
			{getButtons()}
			{[modes.view, modes.new].includes(mod) && (
				<Button
					type="text"
					style={{ lineHeight: 0.8 }}
					icon={<CloseIcon />}
					onClick={onClose}
					disabled={isPendingReq}
				/>
			)}
		</Space>
	)
}

export default CommonMatchActionButtons
