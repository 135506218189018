import React, { useContext, useEffect, useRef, useCallback } from 'react'
import lodash from 'lodash'
import { ModContext, FileContext, UserDataContext, SyncDepsContext } from '../../../../contexts'
import moment from 'moment'
import { modes, prepareObjFromServer, appendFiles, useItemFetchers, axios } from '../../../../utils'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
	getNextCode,
} from '../../../../utils/helpers/generators'
import { basicValidator, isValidNum } from '@berry/common-functions/validators'
import staticData from '@berry/static-data'
import { showConfirmModal } from '../../../../components'
import { showHasDepsModal } from '../../../../utils/constants/for-components'
import { message } from 'antd'
import { addFile as addNewFile } from '../../../../utils/helpers/for-files'
import { checkInputCtrlLabIndicatorColor } from '@berry/common-functions/cross-project-functions'
import { calcTestReportNum } from '../../../../utils/helpers/cross-pages-funcs'
import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'
import {
	commonBlockForEditing,
	commonUnblockForEditing,
} from '../../../../utils/helpers/for-block-unblock'
import useInactivityDetector from '../../../../utils/hooks/useInactivityDetector'
import useCloseOrReloadTab from '../../../../utils/hooks/useCloseOrReloadTab'
const dataUrl = '/rm/supply-input-controls'
const tabs = { 'lab-indics': 'ctrlLabIndicators', 'event-histories': 'eventHistories' }
export const reducer = (state) => {
	return {
		...state,
	}
}

const InputControlContext = React.createContext()
InputControlContext.displayName = 'InputControlContext'

const Provider = (props) => {
	const { children, params } = props
	const userDataCtx = useContext(UserDataContext)
	const modCtx = useContext(ModContext)
	const fileCtx = useContext(FileContext)
	const syncDepsCtx = useContext(SyncDepsContext)
	const [state, dispatch] = React.useReducer(reducer, {
		data: {
			ctrlLabIndicators: [],
		},
		blockUnblock: {
			isBlocked: true,
			depsBlocked: [],
		},
		oldData: { ctrlLabIndicators: [] },
		isInitializedMain: false,
		formErrors: {},
		tabsLoading: {
			ctrlLabIndicators: false,
		},
		additional: {
			allCtrlSpecs: [],
			allVocPosTypes: [],
		},
		copyModal: { __isOpen: false },
	})
	const stateRef = useRef(state)

	const executeDispatch = useInactivityDetector(
		stateRef,
		dispatch,
		modCtx,
		blockForEditing,
		async () => {
			modCtx.set(modes.view)
			await unblockForEditing()
			await axios.post(`/logs`, {
				time: new Date().toISOString(),
				action: 'User inactivity',
				entity: 'rmSupplProd',
				idEntity: params.id,
			})
			stateRef.current = { ...stateRef.current, reloadUuid: v4() }
			dispatch({ ...stateRef.current })
			showConfirmModal({
				title:
					'Ваша сессия истекла из-за неактивности в течение определенного времени, введенные данные не сохранены',
				onOk: () => {},
				showCancel: false,
				okText: 'Ок',
			})
		},
		{
			activityCallbackIntervalSeconds: 10,
			inactivityLimitSeconds: 60 * 5,
		}
	)

	const history = useHistory()
	const { refetch } = useItemFetchers(
		dataUrl,
		params.id,
		tabs,
		stateRef,
		useCallback(executeDispatch, [])
	)
	const downloadPhotos = () => {
		const ctrlLabIndPhotos = lodash.cloneDeep(stateRef.current.data.ctrlLabIndicators)
		const ctrlLabIndAllPhotos = ctrlLabIndPhotos.flatMap((item) => item.photos || [])
		fileCtx.addFiles(ctrlLabIndAllPhotos, 'photoPath')
		const specAllPhotos = ctrlLabIndPhotos.flatMap((item) => item.photos || [])
		fileCtx.addFiles(specAllPhotos, 'photoPath')
	}
	useEffect(() => {
		downloadPhotos()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateRef.current.data.ctrlSpec?.id])

	const setError = (err) => {
		executeDispatch({
			...stateRef.current,
			formErrors: { ...stateRef.current.formErrors, ...err },
		})
	}

	const requiredFields = {
		main: [
			'ctrlShelfLife',
			'ctrlDate',
			'ctrlRawMatManuf',
			'ctrlRawMatCountry',
			'ctrlStaff',
			'ctrlMeasure',
			...(!stateRef.current.data.isReadyProdResale
				? ['ctrlControlWeight', 'ctrlExampleWeight']
				: []),
		],
		ctrlLabIndicators: ['factVal', 'factText'],
	}

	const validateBeforeSave = () => {
		if (
			requiredFields.main.some((k) => {
				return !basicValidator(stateRef.current.data[k])
			})
		)
			return false
		if (
			(!stateRef.current.data.isReadyProdResale || stateRef.current.data.ctrlSpec) &&
			(!stateRef.current.data.ctrlLabIndicators.length ||
				stateRef.current.data.ctrlLabIndicators.some(
					(e) => !basicValidator(e.factVal) && !basicValidator(e.factText)
				) ||
				stateRef.current.data.ctrlLabIndicators.some((e) => {
					if (
						e.factVal?.length &&
						e?.specParam?.vocSpecParamParam?.vocMeasure?.labelShort === '°C' &&
						e.factVal?.split(' - ').length === 2 &&
						+e.factVal?.split(' - ')[0] < +e.factVal?.split(' - ')[1]
					)
						return false
					return basicValidator(e.factVal) && !isValidNum(e.factVal)
				}))
		)
			return false
		return true
	}
	/**
	 * подготавливает данные которые нужно выбират
	 */
	const selectors = {
		ctrlStaff: stateRef.current.additional.allCtrlStaff,
		ctrlRawMatManuf: stateRef.current.additional.allSelectCtrlRawMatManufacturers,
		ctrlRawMatCountry: stateRef.current.additional.allSelectRawMatCountries,
		ctrlMeasure: staticData.measure.filter((e) => ['мес', 'сут'].includes(e.labelShort)),
		ctrlSpec: stateRef.current.additional.allCtrlSpecs.filter((e) => {
			const rmProvProd = (stateRef.current.data.contrQuota || stateRef.current.data.order)
				?.rmProvProd
			const prodCatKindRawMat = rmProvProd?.prodCatKindRawMat
			const prodCatPkg = rmProvProd?.prodCatPkg
			if (prodCatKindRawMat) {
				return prodCatKindRawMat.id === e.kindRawMat?.id && rmProvProd.prodCat?.id === e.idProdCat
			} else {
				return (
					prodCatPkg?.kindReadyProd?.id === e?.idKindReadyProd &&
					prodCatPkg?.prodCat?.id === e?.idProdCat
				)
			}
		}),
		ctrlLabIndicators: {
			factText: ['C', 'C - NC', 'NC'],
		},
	}
	const serverEdit = async () => {
		try {
			const formData = appendFiles(
				stateRef.current.data.ctrlLabIndicators,
				'photos',
				'_uuid_',
				'lab_ind',
				{
					key: 'photoPath',
				}
			)
			let body
			let options = { url: dataUrl }
			if (params.id === 'new') {
				body = lodash.cloneDeep(stateRef.current.data)
				options.method = 'POST'
			} else {
				body = getEditedData()
				options.method = 'PUT'
			}
			if (body.ctrlDate) {
				body.ctrlDate = moment(body.ctrlDate).format('YYYY-MM-DD')
			}
			if (body.ctrlControlDate) {
				body.ctrlControlDate = moment(body.ctrlControlDate).format('YYYY-MM-DD')
			}
			formData.append('data', JSON.stringify(body))
			executeDispatch({ ...stateRef.current, isPendingReq: true })
			const response = await axios[options.method.toLowerCase()](options.url, formData)
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			modCtx.set(modes.view)
			if (response.data?.data.id) {
				refetch()
			}
		} catch (err) {
			message.error('Ошибка при сохранении')
			console.log(err.message)
		}
	}

	const {
		getEditedData,
		isEdited,
		stateFunctions,
		serverDelete,
		commonFieldUpdate,
		commonObjUpdate,
		commonDeepFieldUpdate,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{ modCtx, dataUrl, params, pageUrl: '/rm/supply-input-controls', history, requiredFields },
		selectors,
		{
			ctrlShelfLife: 'common',
			ctrlControlDate: 'common',
			ctrlDate: 'common',
			ctrlControlWeight: 'common',
			ctrlExampleWeight: 'common',
			ctrlPkgCount: 'common',
			ctrlStatus: 'common',
			ctrlLabIndicators: 'common',
			/* ctrlSpec: 'obj', */
			ctrlRawMatManuf: 'obj',
			ctrlRawMatCountry: 'obj',
			ctrlStaff: 'obj',
			ctrlMeasure: 'obj',
		},
		{
			ctrlLabIndicators: {
				factVal: 'common',
				factText: 'common',
				comment: 'common',
			},
		}
	)
	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		selectors,
		[
			{
				field: 'ctrlLabIndicators',
				updateVal: 'common',
				modalName: 'copyModal',
			},
			{
				field: 'prod',
				updateVal: 'common',
				modalName: 'copyModal',
			},
		]
	)

	const getAssignedPartyNum = (year) => {
		if (userDataCtx.state.isDevAdmin && !moment.isMoment(year)) return year
		const isAcceptFromZagotovka = stateRef.current.data.supply.isAcceptFromZagotovka
		const __defaultValueForIsAcceptFromZagotovka = 1000
		const _defaultPref = 'ФР'
		const lastTwoNumsOfCtrlDate = moment(
			stateRef.current.data.supply.supplyReq.products.find(
				(product) => product.contrQuota?.id === stateRef.current.data.contrQuota?.id
			)?.harvestYear
		).format('YY')
		if (stateRef.current.data?.assignedPartyNum?.slice(0, 2) === lastTwoNumsOfCtrlDate)
			return stateRef.current.data.assignedPartyNum
		const productsDisplayCodes = stateRef.current.additional.allSupplProdDisplayCodes.filter(
			(p) => p.isAcceptFromZagotovka === isAcceptFromZagotovka
		)
		const filtredForYearsNums = productsDisplayCodes.filter(
			(el) => el.assignedPartyNum?.slice(0, 2) === lastTwoNumsOfCtrlDate
		)
		let nextPartyNum = getNextCode(filtredForYearsNums.map((e) => +e.assignedPartyNum.slice(5, 9)))
		if (isAcceptFromZagotovka && nextPartyNum === 1)
			nextPartyNum = nextPartyNum + __defaultValueForIsAcceptFromZagotovka
		return `${lastTwoNumsOfCtrlDate}${_defaultPref}-${String(nextPartyNum).padStart(4, '0')}`
	}

	stateFunctions.setAssignedPartyNum = (val) => {
		commonFieldUpdate('assignedPartyNum', getAssignedPartyNum(val))
	}
	stateFunctions.setCtrlControlDate = async (val) => {
		commonFieldUpdate('ctrlControlDate', val)
		commonFieldUpdate('ctrlTestReportNum', await calcTestReportNum([], { date: val }))
	}

	stateFunctions.setCtrlSpec = (val, isCopy) => {
		if (isEdited().ctrlLabIndicators?.length) {
			showConfirmModal({
				...showHasDepsModal,
				title: (
					<>
						<span>
							При смене спецификации все внесенные данные в табличной вкладке Лабораторные
							показатели будут удалены
						</span>
					</>
				),
				onOk: () => {
					commonFieldUpdate('ctrlLabIndicators', [])

					handleUnblockOfSpecParams()
					const parameters = stateRef.current.additional.allCtrlSpecs.find(
						(e) => String(e.id) === String(val)
					)?.parameters
					parameters?.forEach((e) => {
						stateFunctions.ctrlLabIndicators.create({ specParam: e })
					})
					handleBlockOfSpecParams(
						parameters?.map((p) => {
							return { id: p.id }
						}) || []
					)
					commonObjUpdate('ctrlSpec', val)
				},
			})
		} else {
			commonFieldUpdate('ctrlLabIndicators', [])
			handleUnblockOfSpecParams()
			if (isCopy) {
				stateRef.current.copyModal.ctrlLabIndicators.forEach((e) => {
					const { id, idSpecParam, idSupplProd, photos, ...els } = e
					els.photos = photos.flatMap((p) => p.photos)

					stateFunctions.ctrlLabIndicators.create({ ...els })
				})
				handleBlockOfSpecParams(
					stateRef.current.copyModal.ctrlLabIndicators?.map((li) => {
						return { id: li.idSpecParam || li.specParam?.id }
					}) || []
				)
			} else {
				const parameters = stateRef.current.additional.allCtrlSpecs.find(
					(e) => e.id === val
				)?.parameters
				parameters.forEach((e) => {
					stateFunctions.ctrlLabIndicators.create({ specParam: e })
				})
				handleBlockOfSpecParams(
					parameters?.map((p) => {
						return { id: p.id }
					}) || []
				)
			}
			commonObjUpdate('ctrlSpec', val)
		}
	}
	stateFunctions.ctrlLabIndicators.addPhoto = (inUuid, vals) => {
		addNewFile(vals, 'photoPath', 'lab_ind')
		commonDeepFieldUpdate(['ctrlLabIndicators'], [inUuid], 'photos', vals)
		fileCtx.addFiles(lodash.cloneDeep(vals), 'photoPath')
	}
	stateFunctions.ctrlLabIndicators.removePhoto = (inUuid, photo) => {
		let newFiles = stateFunctions.ctrlLabIndicators.get(inUuid)?.photos.filter((p) => {
			return photo.photoPath
				? p.photoPath !== photo.photoPath
				: p.photoPath !== photo.originFileObj?.photoPath
		})
		commonDeepFieldUpdate(['ctrlLabIndicators'], [inUuid], 'photos', newFiles)
	}

	const serverMatch = async (obj) => {
		try {
			let options = { url: dataUrl, method: 'PUT' }
			const response = await axios.put(options.url, { ...obj, id: stateRef.current.data.id })
			executeDispatch({ ...stateRef.current, reloadUuid: v4() })
			modCtx.set(modes.view)
			if (response.data?.data?.id) {
				refetch()
			}
		} catch (err) {
			message.error('Ошибка при сохранении')
			console.log(err.message)
		}
	}
	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			copyModal: { __isOpen: false },
		})
		return
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
		return
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, params.id])

	/**
	 * Валидация всех полей в соответствии с конфлюенсом
	 * @returns {string} - если вернулась строка и не пустая значит есть ошибка валидации
	 */
	const validate = () => {
		let newFormErrors = {}
		const data = stateRef.current.data
		if (!data.ctrlLabIndicators.length) {
			// return 'Не добавлена ни одна строка в табличной вкладке Лабораторные показатели'
		}
		data.ctrlLabIndicators.forEach((d) => {
			if (!Object.keys(d).some((f) => requiredFields.ctrlLabIndicators.includes(f))) {
				requiredFields.ctrlLabIndicators.forEach((f) => {
					if (!basicValidator(d[f])) {
						newFormErrors = { ...newFormErrors, [f + '.' + d._uuid_]: true }
					}
				})
			}
			requiredFields.ctrlLabIndicators.forEach((f) => {
				if (Object.keys(d).includes(f)) {
					if (!basicValidator(d[f])) {
						newFormErrors = { ...newFormErrors, [f + '.' + d._uuid_]: true }
					}
					if (basicValidator(d[f]) && f === 'factVal' && !isValidNum(d[f])) {
						if (
							d[f]?.length &&
							d?.specParam?.vocSpecParamParam?.vocMeasure?.labelShort === '°C' &&
							d[f]?.split(' - ').length === 2 &&
							+d[f]?.split(' - ')[0] < +d[f]?.split(' - ')[1]
						)
							return
						newFormErrors = { ...newFormErrors, [f + '.' + d._uuid_]: true }
					}
				}
			})
		})
		if (Object.keys(newFormErrors).length) {
			executeDispatch({ ...stateRef.current, formErrors: newFormErrors })
			// return 'Не заполнены обязательные поля'
		}
		return ''
	}

	const validators = {
		kinds: (inUuid) => {
			const found = stateFunctions.kinds.get(inUuid)
			const whereToFind = stateRef.current.kinds.filter((e) => e._uuid_ !== inUuid)
			if (!basicValidator(found.label)) throw Error()
			if (whereToFind.some((e) => e.label.toLowerCase() === String(found.label).toLowerCase())) {
				throw Error('Вид не уникален')
			}
			return
		},
	}

	const getActualState = () => {
		const obj = lodash.cloneDeep(stateRef.current.data)
		return obj
	}
	const validateTemp = (rec, val) => {
		val = val.split(' - ')
		const result = val[1]?.length ? +val[0] >= +val[1] : true
		if (result)
			executeDispatch({
				...stateRef.current,
				formErrors: { ['factVal.' + rec.ctrlLabIndic._uuid_]: true },
			})
	}

	const getQualityIndic = () => {
		if (!stateRef.current.data.ctrlLabIndicators.length) return null
		const k = stateRef.current.data.ctrlLabIndicators.length * 5
		const v =
			stateRef.current.data.ctrlLabIndicators.filter((ind) =>
				ind.specParam.etalonText || ind.specParam.deviationText
					? ind.factText === 'C'
					: checkInputCtrlLabIndicatorColor(ind, 'etalon')
			).length * 5
		const d =
			stateRef.current.data.ctrlLabIndicators.filter(
				(ind) =>
					!checkInputCtrlLabIndicatorColor(ind, 'etalon') &&
					checkInputCtrlLabIndicatorColor(ind, 'deviation')
			).length * 4
		const point = (v + d) / k
		switch (true) {
			case point > 0.8:
				return 'Высокий'
			case point >= 0.6:
				return 'Средний'
			case point < 0.6:
				return 'Низкий'
			default:
				throw Error('Показатель рассчитан неверно')
		}
	}

	const getDepsOn = () => []

	const depsFunctions = {
		ctrlLabIndicators: (inId) => [],
	}

	const checkIsBlocked = async () => {
		const res = await axios(`${dataUrl}/check-block/${params.id}`)
		if (res.status === 200) {
			return res.data.mainData.isBlocked
		}
		return true
	}

	useCloseOrReloadTab(
		async (event) => {
			if (modCtx.mod === modes.edit) {
				event.preventDefault()
				await unblockForEditing()
			}
		},
		[
			dataUrl,
			params.id,
			modCtx.mod,
			tabs,
			syncDepsCtx.state.reloadUuids['office-ms'],
			stateRef.current.reloadUuid,
		]
	)

	const handleBlockOfChangedSelect = async (entity, val) => {
		const foundIndx = stateRef.current.blockUnblock.depsBlocked.findIndex(
			(d) => d.entity === entity
		)
		let newDepsBlocked = [...stateRef.current.blockUnblock.depsBlocked]
		if (foundIndx !== -1) {
			axios.post(`/unblock-entity`, [{ ...newDepsBlocked[foundIndx] }])
			newDepsBlocked.splice(foundIndx, 1)
		}
		newDepsBlocked.push({ entity, id: val.id, blockedByEntity: 'rmSupplyInputCtrl' })
		executeDispatch({
			...stateRef.current,
			blockUnblock: { ...stateRef.current.blockUnblock, depsBlocked: newDepsBlocked },
		})
		axios.post(`/block-entity`, [{ entity, id: val.id, blockedByEntity: 'rmSupplyInputCtrl' }])
	}

	const handleBlockOfSpecParams = async (vals) => {
		let newDepsBlocked = [...stateRef.current.blockUnblock.depsBlocked]

		newDepsBlocked.push(
			...vals.map((val) => {
				return { entity: 'specParam', id: val.id, blockedByEntity: 'rmSupplyInputCtrl' }
			})
		)
		axios.post(
			`/block-entity`,
			vals.map((val) => {
				return { entity: 'specParam', id: val.id, blockedByEntity: 'rmSupplyInputCtrl' }
			})
		)
		executeDispatch({
			...stateRef.current,
			blockUnblock: { ...stateRef.current.blockUnblock, depsBlocked: newDepsBlocked },
		})
	}

	const handleUnblockOfSpecParams = async () => {
		const foundDeps = stateRef.current.blockUnblock.depsBlocked.filter(
			(d) => d.entity === 'specParam'
		)
		if (foundDeps.length > 0) {
			axios.post(`/unblock-entity`, foundDeps)
			executeDispatch({
				...stateRef.current,
				blockUnblock: {
					...stateRef.current.blockUnblock,
					depsBlocked: [
						...stateRef.current.blockUnblock.depsBlocked.filter((d) => d.entity !== 'specParam'),
					],
				},
			})
		}
		return
	}

	const entityToSetFuncNameMapForMainCard = {
		regStaff: 'setCtrlStaff',
		vocEditableRawMatManuf: 'setCtrlRawMatManuf',
		vocEditableRawMatCountry: 'setCtrlRawMatCountry',
		regSpecification: 'setCtrlSpec',
	}

	Object.entries(entityToSetFuncNameMapForMainCard).forEach(([entity, funcName]) => {
		let oldF = stateFunctions[funcName]
		stateFunctions[funcName] = (inVal) => {
			oldF(inVal)
			handleBlockOfChangedSelect(entity, inVal)
		}
	})

	async function blockForEditing() {
		return await commonBlockForEditing(dataUrl, params.id)
	}

	async function unblockForEditing() {
		return await commonUnblockForEditing(
			stateRef.current.blockUnblock.depsBlocked,
			dataUrl,
			params.id
		)
	}
	const rerender = () => {
		executeDispatch(stateRef.current)
	}

	const value = {
		state: getActualState(),
		rerender,
		isPendingReq: stateRef.current.isPendingReq,
		blockUnblock: stateRef.current.blockUnblock,
		blockForEditing,
		unblockForEditing,
		checkIsBlocked,
		additional: stateRef.current.additional,
		tabsLoading: stateRef.current.tabsLoading,
		isInitializedMain: stateRef.current.isInitializedMain,
		stateFunctions,
		selectors,
		validators,
		serverEdit,
		getEditedData,
		isEdited,
		validate,
		validateBeforeSave,
		serverDelete,
		serverMatch,
		reset,
		getDepsOn,
		depsFunctions,
		setError,
		formErrors: stateRef.current.formErrors,
		qualityIndic: getQualityIndic(),
		validateTemp,
		copyModal: lodash.cloneDeep(stateRef.current.copyModal),
		modalFunctions,
		downloadPhotos: downloadPhotos,
	}

	return <InputControlContext.Provider value={value}>{children}</InputControlContext.Provider>
}

export { Provider, InputControlContext }
