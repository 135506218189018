import { getProdCatKindRawMatLabel } from '@berry/common-functions/cross-project-functions'
import { Checkbox } from 'antd'
import { CommonSelect, EditDelBtns } from '../../../../components'
import { getColumnSearchProps, modes, openNewTab } from '../../../../utils'
import { getProdCatKindRawMatsDisplayCode } from '../../../../utils/helpers/cross-pages-funcs'

/**
 * определяет сколько строк обьединять
 * @param {Object} inRecord
 * @returns {number}
 */
const getRowSpan = (inRecord, inLength) => {
	if (inRecord.indxVocTempRegime === 0) {
		return inLength || 1
	} else {
		return 0
	}
}

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} mainCtx - основной контекст
 */
export const getDisplayColumns = (mainCtx, modCtx, authCtx) => {
	const stateFunctions = mainCtx.stateFunctions.kindRawMats
	const isDisabled = modCtx.mod === modes.view
	const isDisabledByArchive = (record) => {
		const sameArrs = mainCtx.state.kindRawMats.filter(
			(e) =>
				e.vocRawMatState?.id === record.vocRawMatState?.id &&
				e.vocTempRegime?.id === record.vocTempRegime?.id
		)
		if (record.isArchive && sameArrs.length > 1 && record._uuid_ !== sameArrs.at(-1)._uuid_) {
			return true
		}
		return false
	}
	return [
		{
			title: 'Артикул',
			dataIndex: '',
			key: 'code',
			width: '10%',
			render: (text, record) => {
				return getProdCatKindRawMatsDisplayCode(
					mainCtx.state.displayCode || mainCtx.additional.allProdCats.length,
					record
				)
			},
		},
		{
			title: 'Наименование',
			dataIndex: '',
			key: 'label',
			width: '30%',
			render: (text, record) => {
				return getProdCatKindRawMatLabel(record)
			},
		},
		{
			title: 'Артикул 1С',
			dataIndex: ['vocProd1c', 'vendorCode'],
			key: 'displayCode1c',
			width: '8%',
		},
		{
			title: 'Наименование 1С',
			dataIndex: '',
			key: 'label1c',
			width: '48%',
			render: (record) => {
				return (
					<CommonSelect
						value={record.vocProd1c}
						setValue={(data) => {
							stateFunctions.setVocProd1c(record._uuid_, data)
						}}
						isDisabled={isDisabled}
						showSearch
						dataArr={[
							{ id: null, displayVal: '', isEmpty: true },
							...(mainCtx.additional.allVocProd1Cs?.filter((e) => {
								return !mainCtx.state.kindRawMats.some((rm) => {
									return rm.vocProd1c?.id === e.id && record.vocProd1c?.id !== e.id && !rm.isArchive
								})
							}) || []),
						]}
					/>
				)
			},
		},
		{
			title: 'Архив',
			dataIndex: 'isArchive',
			key: 'isArchive',
			width: '3%',
			render: (_, record) => (
				<Checkbox
					disabled={isDisabled || isDisabledByArchive(record)}
					checked={record.isArchive}
					onChange={(e) => {
						stateFunctions.setIsArchive(record._uuid_, e.target.checked)
					}}
				/>
			),
			...getColumnSearchProps(
				[
					...new Set(
						mainCtx.state.kindRawMats
							?.map((e) => {
								return e.isArchiveForFilter
							})
							.filter((e) => e)
					),
				],
				undefined,
				'isArchiveForFilter'
			),
		},
		{
			title: null,
			key: 'action',
			width: '4%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={false}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						deps={mainCtx.delEditDeps?.kindRawMats?.[record.id]}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}

/**
 * поля таблицы модального окна
 * @param {Object} inMainCtx - основной контекст
 * @param {Object} inDataFromServerCtx - контекст данных с сервера
 */
export const getDisplayModalColumns = (inMainCtx) => {
	return [
		{
			title: 'Температурный режим',
			key: 'vocTempRegime',
			dataIndex: ['vocTempRegime', 'label'],
			onCell: (record) => ({
				rowSpan: getRowSpan(record, inMainCtx.additional.allVocRawMatStates.length),
			}),
		},

		{
			title: 'Состояние',
			dataIndex: 'vocRawMatState',
			render: (inData, record, index) => {
				return record.vocRawMatState?.label
			},
		},
		{
			title: 'Используется',
			dataIndex: 'quota',
			render: (inData, record, index) => {
				return (
					<Checkbox
						onChange={(e) => {
							if (e.target.checked) {
								inMainCtx.stateFunctions.kindRawMats.create({
									vocRawMatState: record.vocRawMatState,
									vocTempRegime: record.vocTempRegime,
									isArchive: false,
								})
							} else {
								const found = inMainCtx.stateFunctions.kindRawMats.findByFields({
									'vocRawMatState.id': record?.vocRawMatState?.id,
									'vocTempRegime.id': record?.vocTempRegime?.id,
								})
								if (found?.length) inMainCtx.stateFunctions.kindRawMats.delete(found[0]._uuid_)
							}
						}}
						checked={
							!!inMainCtx.stateFunctions.kindRawMats.findByFields({
								'vocRawMatState.id': record?.vocRawMatState?.id,
								'vocTempRegime.id': record?.vocTempRegime?.id,
							})?.length &&
							inMainCtx.state.kindRawMats
								.filter(
									({ vocRawMatState, vocTempRegime }) =>
										record.vocRawMatState?.id === vocRawMatState?.id &&
										record.vocTempRegime?.id === vocTempRegime?.id
								)
								.some((e) => !e.isArchive)
						}
					></Checkbox>
				)
			},
		},
	]
}

/**
 * подготавливает данные для отрисовки таблицы
 * @param {Object} inMainCtx - рсновной контекст
 *
 */
export const prepareData = (inMainCtx) => {
	if (
		!inMainCtx.additional.allVocTempRegimes.length ||
		!inMainCtx.additional.allVocRawMatStates.length
	)
		return []
	const result = inMainCtx.additional.allVocTempRegimes.reduce((acc, cur) => {
		const rows = inMainCtx.additional.allVocRawMatStates.map((el, indx) => {
			return { vocTempRegime: cur, indxVocTempRegime: indx, vocRawMatState: el }
		})
		if (!rows.length) return [...acc, { vocTempRegime: cur, indxVocTempRegime: 0 }]
		return [...acc, ...rows]
	}, [])
	return result
}
