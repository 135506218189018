import React, { useContext, useState } from 'react'
import { Card, Table } from 'antd'
import { UserDataContext, ListProvider } from '../../contexts'
import { HeaderMenu } from '..'
import { vocabularies } from './vocabularies'
import { getColumnSearchProps, applyFilterForList, goToItem } from '../../utils'
import { useHistory } from 'react-router'
import SetNotEditIcon from './SetNotEditIcon'
import { checkRole } from '@berry/common-functions/role'
import rowClassName from '../../utils/helpers/rowClassName'

const searchFields = ['title']
const pageUrl = '/vocabularies'

const VocabulariesList = () => {
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const [state, setState] = useState({
		search: null,
		fromServer: vocabularies.filter((voc) =>
			checkRole(
				{
					Справочники: {
						[voc.title]: {
							view: true,
						},
					},
				},
				currentRights
			)
		),
	})
	const history = useHistory()
	const [rowId, setRowId] = useState('')

	const setSearch = (value) => {
		const newState = { ...state, search: value?.toString() || '' }
		setState(newState)
	}

	const toDisplayDataList = () => {
		let result = applyFilterForList(state.fromServer, {
			search: state.search,
			searchFields: searchFields,
		})
		return result
	}

	return (
		<ListProvider>
			<HeaderMenu search={setSearch} />
			<Card>
				<Table
					dataSource={toDisplayDataList()}
					columns={[
						{
							title: 'Наименование',
							dataIndex: 'title',
							key: 'title',
							...getColumnSearchProps(
								toDisplayDataList().map((e) => e.title),
								'title'
							),

							render: (text, record) => SetNotEditIcon(record),
						},
					]}
					onRow={(data) => {
						return {
							onClick: () => {
								goToItem(history, { url: pageUrl, id: data.link }, data)
							},
							onMouseEnter: () => {
								setRowId(data.link)
							},
							onMouseLeave: () => {
								setRowId('')
							},
						}
					}}
					rowKey={'link'}
					pagination={{ pageSize: 17 }}
					size="small"
					scroll={{ x: 800 }}
					rowClassName={rowClassName(rowId)}
				/>
			</Card>
		</ListProvider>
	)
}

export default VocabulariesList
