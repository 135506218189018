import { Checkbox } from 'antd'
import { dateFormat } from '../../../../utils/constants/common'
import { calcProdTaskIsTolling } from '../../../../utils/helpers/cross-pages-funcs'
import moment from 'moment'
import { numToFixed } from '@berry/common-functions/cross-project-functions'
const layout1Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 0 },
	},
	wrapperCol: { span: 13 },
}
const layout2Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 10, offset: 0 },
	},
	wrapperCol: { span: 15 },
}
const layout3Col = {
	labelCol: {
		sm: { span: 14, offset: 0 },
		md: { span: 14, offset: 0 },
		lg: { span: 9, offset: 0 },
		xl: { span: 11, offset: 0 },
	},
	wrapperCol: { span: 13 },
}

export const getFormItemsMain = (mainCtx) => {
	const weightAccepted = numToFixed(
		mainCtx.state.storages?.reduce(
			(sum, cur) => (cur.status === 'Допущено' ? sum + +cur.weight : sum),
			0
		),
		2
	)

	return [
		[
			{
				layout: layout1Col,
				label: 'Продукт',
				name: 'prodCat',
				field: mainCtx.state.taskRep?.task.prodCat.label,
			},
			{
				layout: layout1Col,
				label: 'Вид',
				name: 'prodCatKind',
				field: mainCtx.state.prodCatKind,
			},
			{
				layout: layout1Col,
				label: 'Артикул',
				name: 'articul',
				field: mainCtx.state.articul,
			},
			{
				layout: layout1Col,
				label: 'Артикул 1С',
				name: 'articul1C',
				field: mainCtx.state.articul1C,
			},
		],
		[
			{
				layout: layout2Col,
				label: 'Партия №',
				name: 'partyNum',
				field: mainCtx.state.taskRep?.partyNum,
			},
			{
				key: 'fabricatedNumVal',
				name: 'fabricatedNumVal',
				layout: layout2Col,
				label: 'Сборная партия №',
				field: mainCtx.state.taskRep?.task.fabricatedNumVal,
			},
			{
				layout: layout2Col,
				label: 'Качество',
				name: 'vocQuality',
				field: mainCtx.state.taskRep?.prodCatPkg.vocQuality?.label || 'Отсутствует',
			},
			{
				layout: layout2Col,
				label: 'Спецификация',
				name: 'spec',
				field: mainCtx.state.taskRep?.task.spec?.label,
			},
			{
				layout: layout2Col,
				label: 'Заказчик',
				name: 'customer',
				field: mainCtx.state.taskRep?.task.customer?.label,
			},
		],
		[
			{
				layout: layout3Col,
				label: 'Масса, кг допущено',
				name: 'weightAccepted',
				field: weightAccepted,
			},
			{
				layout: layout3Col,
				label: 'Дата производства',
				name: 'prodDate',
				field: mainCtx.state.taskRep?.task.date
					? moment(mainCtx.state.taskRep?.task.date).format(dateFormat)
					: null,
			},
			{
				layout: layout3Col,
				label: 'Годен до',
				name: 'shelfLifeDate',
				field: mainCtx.state.shelfLifeDate
					? moment(mainCtx.state.shelfLifeDate).format(dateFormat)
					: null,
			},
			{
				layout: layout3Col,
				label: 'Давалец',
				name: 'provider',
				field: calcProdTaskIsTolling(mainCtx.state.taskRep?.task),
			},
			{
				layout: layout3Col,
				label: 'ID ДЗ',
				name: 'idOrder',
				field: mainCtx.state.idContrOrder,
			},
			...(!['Утверждена', 'Принято в 1С', 'Ожидает отправки'].includes(
				mainCtx.state.taskRep?.task.status
			)
				? [
						{
							layout: layout3Col,
							label: 'Не зарегистрирован в 1С',
							name: 'isNotRegIn1C',
							field: (
								<Checkbox
									disabled
									checked={mainCtx.state.taskRep?.task.status !== 'Принято в 1С'}
								></Checkbox>
							),
						},
				  ]
				: []),
		],
	]
}
