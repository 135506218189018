import { getColumnSearchProps, sorter } from '../../../../utils'
import { dateFormat } from '../../../../utils/constants/common'
import { colorizeIntegrationListStatus } from '../../../../utils/helpers/for-integration'
import moment from 'moment'

/**
 * @param {Object} fromServerFilters - массив с записями таблицы
 */
export const getColumns = (props) => {
	const { fromServerFilters } = props

	return [
		{
			title: 'ID утилизации',
			dataIndex: 'id',
			key: 'id',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.id),
			sorter: sorter('number', 'id'),
		},
		{
			title: 'Дата утилизации',
			dataIndex: 'date',
			key: 'date',
			width: '10%',
			render: (date) => (date ? moment(date).format(dateFormat) : null),
			...getColumnSearchProps(fromServerFilters.date, undefined, null, true),
		},
		{
			title: 'ID ДЗ',
			dataIndex: 'idOrder',
			key: 'idOrder',
			width: '10%',
			...getColumnSearchProps(fromServerFilters.idOrder),
			sorter: sorter('number', 'idOrder'),
		},
		{
			title: 'Масса, кг',
			dataIndex: 'weight',
			key: 'weight',
			width: '10%',
			sorter: sorter('number', 'weight'),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '10%',
			render: (status) => colorizeIntegrationListStatus(status),
			...getColumnSearchProps(fromServerFilters.status),
		},
	]
}
