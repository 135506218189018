import React, { useContext } from 'react'
import { Form as FormAntd, Input, Select } from 'antd'
import { getDepsList } from '../../../utils/helpers/for-deps'
import { modes } from '../../../utils'
import { ModContext } from '../../../contexts'

const formItemLayout = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
}

const Form = (props) => {
	const { label, status, id, dataDependency } = props
	const modCtx = useContext(ModContext)
	return (
		<FormAntd labelAlign="left">
			<FormAntd.Item
				{...formItemLayout}
				label="Причина блокировки"
				getValueProps={(value) => value}
				name="label"
				onChange={label.onChange}
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Input
					value={label.value}
					disabled={
						getDepsList({ voc_editable_block_cause: [id] }, dataDependency).length &&
						modCtx.mod !== modes.new
					}
				/>
			</FormAntd.Item>
			<FormAntd.Item
				{...formItemLayout}
				label="Статус"
				name="status"
				rules={[
					{
						required: true,
						message: false,
					},
				]}
			>
				<Select
					defaultValue={status.value}
					onChange={status.onChange}
					style={{ width: '100%', minWidth: '120px' }}
				>
					{[
						{ label: 'Действующая', id: 1 },
						{ label: 'Недействующая', id: 2 },
					].map((it) => {
						return (
							<Select.Option key={it.id} value={it.label}>
								{it.label}
							</Select.Option>
						)
					}) || []}
				</Select>
			</FormAntd.Item>
		</FormAntd>
	)
}

export default Form
