import React, { useContext, useState } from 'react'
import { StockSemifItemMainContext } from '../provider/main'
import { TabItem, TableWithListLoader, showConfirmModal } from '../../../../../components'
import { AuthContext, UserDataContext } from '../../../../../contexts'
import { openNewTab } from '../../../../../utils'
import { getDisplayColumns } from './parent-tab'
import { checkRole } from '@berry/common-functions/role'

const ParentTab = ({ modalItem, setModalItem, isModalOpen, setIsModalOpen }) => {
	const mainCtx = useContext(StockSemifItemMainContext)
	const authCtx = useContext(AuthContext)
	const {
		state: { rights: currentRights },
	} = useContext(UserDataContext)
	const [className, setClassName] = useState('')
	const canViewStocks = checkRole(
		{
			'АРМ офиса': {
				'Реестр Запасы': {
					view: true,
				},
			},
		},
		currentRights
	)
	return (
		<TabItem
			addButton={{ show: false }}
			table={() => (
				<TableWithListLoader
					size="small"
					url="/stock/semifs/parents"
					params={{ id: mainCtx.state.id }}
					calcColumns={getDisplayColumns}
					pagination={false}
					onRow={(record) => ({
						onClick: () => {
							const id =
								record.stockRawMatStor?.idStockRawMat ||
								record.stockReadyProdResaleStor.idStockReadyProdResale
							const url = record.stockRawMatStor
								? `/stock/raw-mats/${id}`
								: `/stock/ready-prod-resales/${id}`
							if (!canViewStocks) {
								return showConfirmModal({
									title:
										'Ограничены права доступа к данному объекту. Обратитесь к администратору системы',
									okText: 'Ок',
									width: 450,
									showCancel: false,
								})
							}
							openNewTab(url, { authCtx })
						},
						onMouseEnter: () => setClassName('highlight'),
						onMouseLeave: () => setClassName(''),
					})}
					columnProps={{
						fromServerFilters: mainCtx.state.parents.filters,
						className,
						setClassName,
						authCtx,
					}}
					highlight
					filters={{}}
					defaultSorter={{}}
					permanentFilters={{}}
					rowKey="_uuid_"
					authCtx={authCtx}
					canViewStocks={canViewStocks}
				/>
			)}
		/>
	)
}

export default ParentTab
