import moment from 'moment'
import { CommonSelect, EditDelBtns, InputError } from '../../../components'
import { modes, openNewTab } from '../../../utils'
import { DatePicker, Input, Select } from 'antd'
import { setNumField } from '@berry/front-common/for-fields'
import { dateFormat } from '../../../utils/constants/common'
import { calcSupplyInpCtrlFactWeight } from '@berry/common-functions/cross-project-functions'
export const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

const wasteManagement = [
	'Возврат давальцу в полном объёме',
	'Утилизация за счет производства',
	'Утилизация за счет давальца',
]

export const getSupplyFactWeight = (orderOrQuotaId, supplies, type) => {
	if (!orderOrQuotaId) return 0
	return supplies
		.flatMap((sup) => sup.products)
		.filter((p) =>
			type === 'quota' ? p.contrQuota?.id === orderOrQuotaId : p.order?.id === orderOrQuotaId
		)
		.reduce((acc, cur) => {
			if (cur.invoice?.status === 'Принято в 1С') {
				return acc + +calcSupplyInpCtrlFactWeight(cur)
			}
			return acc
		}, 0)
}

export const getColumns = (mainCtx, modCtx, authCtx, className) => {
	const orderFunctions = mainCtx.stateFunctions.orders
	const isDisabled = modCtx.mod === modes.view
	return [
		...(mainCtx.state.frame
			? [
					{
						title: '№ ДС',
						dataIndex: ['application', 'displayCode'],
						width: '4.98%',
						required: true,
						render: (value, record) => (
							<InputError
								hasError={mainCtx.formErrors[`application.${record._uuid_}`]}
								component={
									<CommonSelect
										value={value}
										setValue={(value) =>
											orderFunctions.setApplication(record._uuid_, value, 'orders')
										}
										isDisabled={isDisabled || (record.status && record.status !== 'Новый')}
										showSearch
										dataArr={mainCtx.additional.allApplications}
									/>
								}
							/>
						),
						onCell: () => ({ className }),
					},
			  ]
			: []),
		{
			title: 'ID ДЗ',
			dataIndex: 'id',
			width: '4.98%',
			onCell: () => ({ className }),
		},
		{
			title: 'Продукт',
			dataIndex: 'rmProvProd',
			width: '12.57%',
			required: true,
			render: (_, record) => (
				<InputError
					hasError={mainCtx.formErrors[`rmProvProd.${record._uuid_}`]}
					component={
						<CommonSelect
							value={record.rmProvProd}
							setValue={(value) => orderFunctions.setRmProvProd(record._uuid_, value)}
							isDisabled={
								isDisabled ||
								(mainCtx.state.frame && !record.application) ||
								(record.status && record.status !== 'Новый')
							}
							showSearch
							dataArr={mainCtx.additional.allProducts.filter((p) =>
								mainCtx.state.providerQuotas.some((q) => q.rmProvProd && q.rmProvProd.id === p.id)
							)}
						/>
					}
				/>
			),
			onCell: () => ({ className }),
		},
		{
			title: 'Поставка план, кг',
			dataIndex: 'supplyPlanWeight',
			width: '8.42%',
			render: (_, record) => {
				const remainder = mainCtx.getRemainderForOrder(record)
				return (
					<InputError
						showCaption={!!record.rmProvProd}
						hasError={mainCtx.formErrors?.[`supplyPlanWeight.${record._uuid_}`]}
						message={`Доступная масса ${remainder} кг`}
						style={{ width: '100%' }}
						value={record.supplyPlanWeight}
						disabled={isDisabled || (record.status && record.status !== 'Новый')}
						onChange={(e) => {
							setNumField(
								e.target.value,
								record.supplyPlanWeight,
								orderFunctions.setSupplyPlanWeight,
								'float',
								[9, 2],
								{
									_uuid_: record._uuid_,
									isOnlyNums: true,
								}
							)
							mainCtx.checkAvailable(record)
						}}
					/>
				)
			},
			onCell: () => ({ className }),
		},
		{
			title: 'Поставка факт, кг',
			dataIndex: 'supplyFactWeight',
			width: '8.42%',
			render: (_, record) => getSupplyFactWeight(record.id, mainCtx.additional.supplies),
			onCell: () => ({ className }),
		},
		{
			title: 'Целевой продукт',
			dataIndex: 'prodCatPkg',
			width: '15.45%',
			required: true,
			render: (_, record) => (
				<InputError
					style={{ maxWidth: 300 }}
					hasError={mainCtx.formErrors[`prodCatPkg.${record._uuid_}`]}
					component={
						<CommonSelect
							value={record.prodCatPkg}
							setValue={(value) => orderFunctions.setProdCatPkg(record._uuid_, value)}
							isDisabled={isDisabled || (record.status && record.status !== 'Новый')}
							showSearch
							dataArr={mainCtx.additional.allProdCatPkgs.filter(
								(pgk) => record.rmProvProd && pgk.prodCat.id === record.rmProvProd.prodCat.id
							)}
						/>
					}
				/>
			),
			onCell: () => ({ className }),
		},
		{
			title: 'Использование отходов',
			dataIndex: 'wasteManagement',
			width: '14%',
			render: (_, record) => (
				<InputError
					hasError={mainCtx.formErrors[`wasteManagement.${record._uuid_}`]}
					component={
						<Select
							value={record.wasteManagement}
							disabled={isDisabled || (record.status && record.status !== 'Новый')}
							showSearch
							onChange={(e) => orderFunctions.setWasteManagement(record._uuid_, e)}
							options={wasteManagement.map((el) => ({
								label: el,
								value: el,
							}))}
						/>
					}
				/>
			),
			onCell: () => ({ className }),
		},

		{
			title: 'Комментарий',
			dataIndex: 'comment',
			width: '12.32%',
			render: (_, record) => (
				<Input
					disabled={isDisabled || (record.status && record.status !== 'Новый')}
					value={record?.comment}
					maxLength={255}
					onChange={(e) => {
						orderFunctions.setComment(record._uuid_, e.target.value)
					}}
				/>
			),
			onCell: () => ({ className }),
		},
		{
			title: 'Дата отгрузки план',
			dataIndex: 'unloadDatePlan',
			width: '9.45%',
			render: (_, record) => (
				<InputError
					className={className}
					hasError={mainCtx.formErrors[`unloadDatePlan.${record._uuid_}`]}
					component={
						<DatePicker
							disabled={isDisabled || (record.status && record.status !== 'Новый')}
							name="date"
							placeholder="ДД.ММ.ГГГГ"
							showToday={false}
							format={dateFormat}
							allowClear={false}
							suffixIcon={null}
							value={record.unloadDatePlan ? moment(record.unloadDatePlan) : null}
							onChange={(e) => {
								orderFunctions.setUnloadDatePlan(record._uuid_, e)
							}}
						/>
					}
				/>
			),
			onCell: () => ({ className }),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			width: '5.87%',
			render: (status) => status || 'Новый',
			onCell: () => ({ className }),
		},
		{
			title: null,
			key: 'action',
			width: '3.54%',
			render: (text, record) => (
				<EditDelBtns
					record={record}
					canDelete={(!record.status || record.status === 'Новый') && !isDisabled}
					onDelete={(record) => {
						orderFunctions.delete(record._uuid_)
					}}
					deps={[]}
					openNewPage={(url) => {
						return openNewTab(url, {
							authCtx: authCtx.state,
						})
					}}
				/>
			),
			onCell: () => ({ className }),
		},
	]
}
