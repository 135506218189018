import { Input } from 'antd'
import { EditDelBtns } from '../../../../components'
import { modes, openNewTab } from '../../../../utils'

/**
 * столбцы для отрисовки внутри таблицы которая отображается на вкладке
 * @param {Object} mainCtx - основной контекст
 * @param {Object} modCtx
 * @param {function} editHandler
 */
export const getDisplayColumns = (mainCtx, modCtx, editHandler, authCtx) => {
	const stateFunctions = mainCtx.stateFunctions.additAttrs
	const isDisabled = modCtx.mod === modes.view
	return [
		{
			title: 'Номер',
			dataIndex: 'displayCode',
			key: 'index',
			width: '25%',
		},
		{
			title: 'Признак',
			dataIndex: 'label',
			key: 'label',
			width: '65%',
		},

		{
			title: null,
			key: 'action',
			width: '10%',
			render: (text, record) => {
				return (
					<EditDelBtns
						record={record}
						canDelete={!isDisabled}
						canUpdate={!isDisabled}
						onDelete={(record) => {
							stateFunctions.delete(record._uuid_)
						}}
						deps={mainCtx.delEditDeps?.additAttrs?.[record.id]}
						onEdit={editHandler}
						openNewPage={(url) => {
							return openNewTab(url, {
								authCtx: authCtx.state,
							})
						}}
					/>
				)
			},
		},
	]
}

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 },
}

export const getFormItems = (mainCtx, modCtx, params = {}) => {
	if (!params?._uuid_) return []

	const { _uuid_ } = params
	const isDisabled = modCtx.mod === modes.view
	const stateFunctions = mainCtx.stateFunctions.additAttrs

	return [
		[
			{
				layout: layout,
				label: 'Признак',
				name: 'label',
				rules: [{ required: true }],
				validateStatus: mainCtx.formErrors?.['additAttr.label'] && 'error',
				help: mainCtx.formErrors?.['additAttr.label'] && mainCtx.formErrors['additAttr.label'],

				field: (
					<Input
						style={{ width: '100%' }}
						value={stateFunctions.get(_uuid_).label}
						disabled={isDisabled}
						onChange={(e) => {
							if (e.target.value?.length && e.target.value?.length > 100) {
								stateFunctions.setLabel(_uuid_, stateFunctions.get(_uuid_).label)
								return
							}
							stateFunctions.setLabel(_uuid_, e.target.value)
						}}
					/>
				),
			},
		],
	]
}
