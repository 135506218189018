import { Checkbox, Col, DatePicker, Select, Typography } from 'antd'
import { modes } from '../../../utils'
import moment from 'moment'
import { colorizeIntegrationStatus } from '../../../utils/helpers/for-integration'
import { CommonSelect } from '../../../components'
import { dateFormat } from '../../../utils/constants/common'
import { checkRole } from '@berry/common-functions/role'

const layout1Col = {
	labelCol: { xs: 24, sm: 8, xl: 8 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}
const layout2Col = {
	labelCol: { xs: 24, sm: 8, xl: 8 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}
const layout3Col = {
	labelCol: { xs: 24, sm: 8, xl: 10, xxl: { offset: 2, span: 8 } },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}
export const calcTotalWeight = (mainCtx) => {
	const totalWeight = (mainCtx.state.productions || []).reduce((acc, cur) => {
		return acc + (+cur.weight || 0)
	}, 0)
	return totalWeight
}
export const getFormItems = (mainCtx, modCtx, userDataCtx) => {
	const isDisabled = modCtx.mod === modes.view
	const totalWeight = calcTotalWeight(mainCtx)

	const {
		state: { isDevAdmin, rights },
	} = userDataCtx
	const isUserCanEdit = checkRole(
		{
			'АРМ офиса': {
				Реализации: {
					edit: true,
				},
			},
		},
		rights
	)
	const date = !mainCtx.state.date
		? null
		: moment.isMoment(mainCtx.state.date)
		? mainCtx.state.date
		: moment(mainCtx.state.date)

	const firstCol = [
		{
			field: <Typography.Title level={5}>Основные данные</Typography.Title>,
			key: 'main',
		},
		...(modCtx.mod !== modes.new
			? [
					{
						layout: layout1Col,
						label: 'ID реализации',
						key: 'id',
						field: mainCtx.state.id,
					},
			  ]
			: []),
		{
			layout: layout1Col,
			label: 'Дата реализации',
			key: 'date',
			rules: [{ required: true }],
			field:
				isDevAdmin || isUserCanEdit ? (
					<DatePicker
						style={{ width: '100%' }}
						placeholder="ДД.ММ.ГГГГ"
						showToday={false}
						format="DD.MM.YYYY"
						allowClear={false}
						suffixIcon={null}
						onChange={mainCtx.stateFunctions.setDate}
						value={date}
						disabled={isDisabled}
						disabledDate={(current) =>
							isDevAdmin ? null : moment().subtract(1, 'day').isAfter(current)
						}
					/>
				) : (
					date.format(dateFormat)
				),
		},
		{
			layout: layout1Col,
			label: 'Бесплатные образцы',
			name: 'isFreeSamples',
			key: 'isFreeSamples',
			width: '3%',
			field: (
				<Checkbox
					disabled={isDisabled || mainCtx.state.productions[0]?.idContrOrder}
					checked={mainCtx.state.isFreeSamples}
					onChange={(e) => {
						mainCtx.stateFunctions.setIsFreeSamples(e.target.checked)
					}}
				/>
			),
		},
	]
	const secondCol = [
		{ key: 'second-col' },
		{
			layout: layout2Col,
			label: 'Заказчик',
			key: 'customer',
			name: 'customer',
			rules: [
				{
					required: !mainCtx.state.productions[0]?.idContrOrder && !mainCtx.state.isFreeSamples,
					message: 'Обязательное поле',
				},
			],
			field: mainCtx.state.productions[0]?.idContrOrder ? (
				mainCtx.state.productions[0]._fullProvider?.label || mainCtx.state?.provider?.displayVal
			) : (
				<CommonSelect
					value={mainCtx.state.customer}
					setValue={mainCtx.stateFunctions.setCustomer}
					isDisabled={isDisabled}
					dataArr={mainCtx.selectors.customer}
					showSearch
				/>
			),
		},
		{
			key: 'outdoorWh',
			layout: layout2Col,
			label: 'Склад отгрузки',
			name: 'outdoorWh',
			rules: [{ required: true }],
			field: (
				<Select
					disabled={isDisabled}
					value={mainCtx.state.outdoorWh?.label}
					onChange={(el) => {
						mainCtx.stateFunctions.setOutdoorWh(el)
					}}
					notFoundContent={<Col>Не найдено</Col>}
				>
					{mainCtx.additional.allOutdoorWhs.map((it) => {
						return (
							<Select.Option key={it.id} value={it.id}>
								{it.label}
							</Select.Option>
						)
					}) || []}
				</Select>
			),
		},
		{
			layout: layout1Col,
			label: 'Общая масса, кг',
			key: 'totalWeight',
			name: 'totalWeight',
			field: totalWeight,
		},
	]

	const thirdCol = [
		{ key: 'third-col' },
		{
			layout: layout3Col,
			key: 'status',
			name: 'status',
			...colorizeIntegrationStatus(mainCtx.state.status),
		},
		{
			layout: layout3Col,
			label: 'ID ДЗ',
			key: 'id',
			name: 'id',
			field: mainCtx.state.productions[0]?.idContrOrder,
		},
	]

	return [firstCol, secondCol, thirdCol]
}
