import { Checkbox, DatePicker, Input, Radio, Typography } from 'antd'
import { CommonSelect, InputError } from '../../../components'
import { modes } from '../../../utils'

import { colorizeIntegrationStatus } from '../../../utils/helpers/for-integration'
import moment from 'moment'
import { setNumField } from '@berry/front-common/for-fields'
import { numToFixed } from '@berry/common-functions/cross-project-functions'

const layout1Col = {
	labelCol: { xs: 24, sm: 8, xl: 8 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}
const layout2Col = {
	labelCol: { xs: 24, sm: 8, xl: 8 },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}
const layout3Col = {
	labelCol: { xs: 24, sm: 8, xl: 10, xxl: { offset: 2, span: 8 } },
	wrapperCol: { xs: 24, sm: 14, xl: 14 },
}

export const getFormItems1 = (mainCtx, modCtx, form, userDataCtx) => {
	const isDisabled = modCtx.mod === modes.view || mainCtx.isDisabledByChangeStatusBtn
	const getDisabledDate = (current) => {
		if (!mainCtx.state.complectations.length) {
			return false
		}
		return current.isBefore(
			moment.min(
				mainCtx.state.complectations.map((c) =>
					moment(
						c.stockRawMatStor?.supplyDate ||
							c.stockReadyProdResaleStor?.supplyDate ||
							(
								c.stockSemifStor?.stockSemif ||
								c.stockReadyProdStor?.stockReadyProd ||
								c.stockSemifStor?.taskRep?.task ||
								c.stockReadyProdStor?.taskRep?.task
							)?.date ||
							c.stockSemifStor?.date ||
							c.stockReadyProdStor?.date,
						'YYYY-MM-DD'
					)
				)
			)
		)
	}
	const idContrOrder =
		mainCtx.state.complectations?.[0]?.stockRawMatStor?.idContrOrder ||
		mainCtx.state.complectations?.[0]?.stockSemifStor?.idContrOrder ||
		mainCtx.state.complectations?.[0]?.stockReadyProdStor?.idContrOrder
	const firstCol = [
		{
			field: <Typography.Title level={5}>Основные данные</Typography.Title>,
			key: 'main',
		},
		{
			layout: layout1Col,
			label: 'ID задачи',
			key: 'id',
			name: 'id',
			field: mainCtx.state.id,
		},
		{
			layout: layout1Col,
			label: 'Дата производства',
			key: 'date',
			name: 'date',
			rules: [{ required: true }],
			field: (
				<DatePicker
					style={{ width: '100%' }}
					placeholder="ДД.ММ.ГГГГ"
					showToday={false}
					format="DD.MM.YYYY"
					allowClear={false}
					suffixIcon={null}
					onChange={(date) => {
						date = moment(date).format('YYYY-MM-DD')
						mainCtx.stateFunctions.setDate(date)
					}}
					disabled={isDisabled}
					disabledDate={getDisabledDate}
				/>
			),
		},
		{
			layout: layout1Col,
			label: 'Смена',
			key: 'workingTime',
			name: 'workingTime',
			rules: [{ required: true }],
			field: (
				<CommonSelect
					value={mainCtx.state.workingTime}
					setValue={mainCtx.stateFunctions.setWorkingTime}
					isDisabled={isDisabled}
					dataArr={mainCtx.additional.allSelectWorkingTimes}
				/>
			),
		},
		{
			layout: layout1Col,
			label: 'Партия №',
			key: 'partyNum',
			name: 'partyNum',
			field:
				!userDataCtx.state.isDevAdmin && !userDataCtx.state.isAdmin ? (
					mainCtx.state.partyNum
				) : (
					<InputError
						hasError={mainCtx.formErrors?.partyNum}
						style={{ width: '100%' }}
						value={mainCtx.state.partyNum}
						disabled={isDisabled}
						onChange={(e) => {
							mainCtx.stateFunctions.setPartyNum(e.target.value)
						}}
					/>
				),
		},
	]
	if (['Производство ГП', 'Производство ПФ'].includes(mainCtx.state.taskKind)) {
		firstCol.push(
			{
				layout: layout1Col,
				label: 'Артикул',
				key: 'articul',
				name: 'articul',
				field: getField('articul', mainCtx, isDisabled),
			},
			{
				layout: layout1Col,
				label: 'Артикул 1С',
				key: 'articul1C',
				name: 'articul1C',
				field: getField('articul1C', mainCtx, isDisabled),
			}
		)
	}

	const secondCol = [
		{ key: 'second-col' },
		{
			layout: layout2Col,
			label: 'Вид работ',
			key: 'taskKind',
			name: 'taskKind',
			rules: [{ required: true }],
			field: (
				<CommonSelect
					plainValue={mainCtx.state.taskKind}
					setValue={mainCtx.stateFunctions.setTaskKind}
					isDisabled={isDisabled}
					dataArr={['Производство ГП', 'Производство ПФ']}
				/>
			),
		},
		{
			layout: layout2Col,
			label: 'Продукт',
			key: 'prodCat',
			name: 'prodCat',
			rules: [{ required: true }],
			field: (
				<CommonSelect
					value={mainCtx.state.prodCat}
					setValue={mainCtx.stateFunctions.setProdCat}
					isDisabled={isDisabled}
					showSearch={true}
					dataArr={mainCtx.additional.allSelectProdCats}
				/>
			),
		},
	]
	if ('Производство ГП' === mainCtx.state.taskKind) {
		secondCol.push({
			layout: layout2Col,
			label: 'Вид выпуск. продукции',
			key: 'prodCatPkg',
			name: 'prodCatPkg',
			rules: [{ required: true }],
			field: (
				<CommonSelect
					showSearch={true}
					plainValue={mainCtx.state.prodCatPkg?.prodCatKind}
					setValue={mainCtx.stateFunctions.setProdCatPkg}
					isDisabled={isDisabled}
					dataArr={mainCtx.selectors.allProdCatPkg}
				/>
			),
		})
		secondCol.push({
			layout: layout2Col,
			label: 'Спецификация',
			key: 'spec',
			name: 'spec',
			field: mainCtx.state.spec?.label,
		})
	}
	if ('Производство ПФ' === mainCtx.state.taskKind) {
		secondCol.push({
			layout: layout2Col,
			label: 'Вид выпуск. продукции',
			key: 'prodCatKindSemif',
			name: 'prodCatKindSemif',
			rules: [{ required: true }],
			field: (
				<CommonSelect
					showSearch={true}
					plainValue={mainCtx.state.prodCatKindSemif?.prodCatKind}
					setValue={mainCtx.stateFunctions.setProdCatKindSemif}
					isDisabled={isDisabled}
					dataArr={mainCtx.selectors.allProdCatKindSemifs}
				/>
			),
		})
		secondCol.push({
			layout: layout2Col,
			label: 'Спецификация',
			key: 'spec',
			name: 'spec',
			field: (
				<CommonSelect
					value={mainCtx.state.spec}
					setValue={mainCtx.stateFunctions.setSpec}
					isDisabled={isDisabled || !mainCtx.state.prodCat?.id}
					dataArr={mainCtx.state.prodCat?.id ? mainCtx.selectors.allSpecs : []}
				/>
			),
		})
	}
	if (['Производство ГП', 'Производство ПФ'].includes(mainCtx.state.taskKind)) {
		secondCol.push({
			layout: layout2Col,
			label: 'Операция',
			key: 'operation',
			name: 'operation',
			rules: [{ required: true }],
			field: (
				<Input
					style={{ minWidth: '85px' }}
					value={mainCtx.state.operation}
					disabled={isDisabled}
					onChange={(e) => {
						return mainCtx.stateFunctions.setOperation(e.target.value)
					}}
				/>
			),
		})
	}
	if ('Перетарка' === mainCtx.state.taskKind) {
		secondCol.push({
			layout: layout2Col,
			label: 'Тип тары',
			key: 'contType',
			name: 'contType',
			field: (
				<CommonSelect
					value={mainCtx.state.contType}
					setValue={mainCtx.stateFunctions.setContType}
					isDisabled={isDisabled}
					dataArr={mainCtx.additional.allSelectContTypes}
				/>
			),
		})
	}
	if ('Производство ГП' === mainCtx.state.taskKind) {
		secondCol.push({
			layout: layout2Col,
			label: 'Сохранить № партии',
			key: 'isSavePartyNum',
			name: 'isSavePartyNum',
			field: (
				<Checkbox
					disabled={isDisabled}
					checked={mainCtx.state.isSavePartyNum}
					onChange={(e) => mainCtx.stateFunctions.setIsSavePartyNum(e.target.checked)}
				/>
			),
		})
	}

	const thirdCol = [
		{ key: 'third-col' },
		{
			layout: layout3Col,
			key: 'status',
			name: 'status',
			...colorizeIntegrationStatus(mainCtx.state.status),
		},
		...(mainCtx.state.taskKind === 'Производство ПФ'
			? [
					{
						layout: layout3Col,
						label: 'Общая масса план, кг',
						key: 'planWeight',
						name: 'planWeight',
						field: (
							<Input
								style={{ width: '100%' }}
								value={mainCtx.state.planWeight}
								disabled={isDisabled}
								onChange={(e) =>
									setNumField(
										e.target.value,
										mainCtx.state.planWeight,
										mainCtx.stateFunctions.setPlanWeight,
										'float',
										[9, 2]
									)
								}
							/>
						),
					},
			  ]
			: []),
		{
			layout: layout3Col,
			label: 'Общая масса, кг',
			key: 'totalWeight',
			name: 'totalWeight',
			field: numToFixed(
				mainCtx.state.complectations?.reduce((sum, cur) => sum + (+cur.weight || 0), 0),
				2
			),
		},
	]

	if (mainCtx.state.taskKind === 'Производство ПФ') {
		thirdCol.push({
			layout: layout3Col,
			label: 'Выполнение плана, %',
			key: 'planFulfillment',
			name: 'planFulfillment',
			field: !mainCtx.state.planWeight
				? null
				: numToFixed(
						(mainCtx.state.complectations.reduce((sum, cur) => sum + (+cur.weight || 0), 0) /
							+mainCtx.state.planWeight) *
							100,
						2
				  ),
		})
	}
	if ('Производство ГП' === mainCtx.state.taskKind) {
		thirdCol.push({
			layout: layout3Col,
			label: 'Заказчик',
			key: 'customer',
			name: 'customer',
			field: (
				<CommonSelect
					value={mainCtx.state.customer}
					setValue={mainCtx.stateFunctions.setCustomer}
					isDisabled={isDisabled}
					showSearch={true}
					dataArr={mainCtx.additional.allSelectCustomers}
				/>
			),
		})
	}
	thirdCol.push({
		layout: layout3Col,
		label: 'ID ДЗ',
		key: 'idContrOrder',
		name: 'idContrOrder',
		field: idContrOrder,
	})
	if (['Производство ГП', 'Производство ПФ'].includes(mainCtx.state.taskKind)) {
		thirdCol.push({
			layout: layout3Col,
			label: 'Образцы',
			key: 'isSamples',
			name: 'isSamples',
			field: (
				<Checkbox
					disabled={isDisabled}
					checked={mainCtx.state.isSamples}
					onChange={(e) => mainCtx.stateFunctions.setIsSamples(e.target.checked)}
				/>
			),
		})
	}

	return [firstCol, secondCol, thirdCol]
}

export const getFormItems2 = (mainCtx, modCtx) => {
	const isDisabled = modCtx.mod === modes.view || mainCtx.isDisabledByChangeStatusBtn
	const result = [
		[
			{
				field: <Typography.Title level={5}>Формирование сборной партии</Typography.Title>,
				key: 'main',
			},
			{
				layout: layout1Col,
				label: 'Сборная партия',
				key: 'isFabricatedParty',
				name: 'isFabricatedParty',
				field: (
					<Checkbox
						disabled={isDisabled}
						checked={mainCtx.state.isFabricatedParty}
						onChange={(e) => mainCtx.stateFunctions.setIsFabricatedParty(e.target.checked)}
					/>
				),
			},
		],
	]
	if (mainCtx.state.isFabricatedParty) {
		result.push([
			{ key: 'second-col' },
			{
				key: 'fabricatedNumText',
				name: 'fabricatedNumText',
				layout: layout2Col,
				label: 'Сборный номер',
				field: (
					<Radio.Group
						disabled={isDisabled}
						defaultValue={'Создать новый'}
						onChange={(e) => {
							mainCtx.stateFunctions.setFabricatedNumText(e.target.value)
						}}
					>
						<Radio value={'Выбрать сущ.'}>Выбрать сущ.</Radio>
						<Radio value={'Создать новый'}>Создать новый</Radio>
					</Radio.Group>
				),
			},
		])
		if (mainCtx.state.fabricatedNumText === 'Выбрать сущ.') {
			result.push([
				{ key: 'third-col' },
				{
					key: 'fabricatedNumVal',
					name: 'fabricatedNumVal',
					layout: layout3Col,
					label: 'Сборная партия №',
					rules: [
						{
							required:
								mainCtx.state.taskKind === 'Производство ГП' &&
								mainCtx.state.isFabricatedParty &&
								mainCtx.state.fabricatedNumText === 'Выбрать сущ.',
						},
					],
					field: (
						<CommonSelect
							plainValue={mainCtx.state.fabricatedNumVal}
							setValue={mainCtx.stateFunctions.setFabricatedNumVal}
							isDisabled={isDisabled}
							showSearch={true}
							dataArr={mainCtx.additional.allStockReadyProds
								.filter(
									(stock) =>
										stock.taskRep?.task.prodCat.id === mainCtx.state.prodCat?.id &&
										stock.taskRep?.task.prodCatPkg &&
										stock.taskRep?.task.prodCatPkg.id === mainCtx.state.prodCatPkg?.id
								)
								.map((stock) => stock.taskRep.task.fabricatedNumVal)
								.filter((partyNum, index, self) => self.indexOf(partyNum) === index)}
						/>
					),
				},
			])
		} else {
			result.push([
				{ key: 'third-col' },
				{
					key: 'fabricatedNumVal',
					name: 'fabricatedNumVal',
					layout: layout3Col,
					label: 'Сборная партия №',
					field: mainCtx.state.fabricatedNumVal,
				},
			])
		}
	}
	return result
}

export const getField = (field, mainCtx, isDisabled) => {
	const dataArr = (
		mainCtx.state.taskKind === 'Производство ГП'
			? mainCtx.selectors.allProdCatPkg
			: mainCtx.selectors.allProdCatKindSemifs
	)
		.filter((e) => e[field])
		.map((e) => ({ ...e, displayVal: e[field] }))
	const key = mainCtx.state.taskKind === 'Производство ГП' ? 'prodCatPkg' : 'prodCatKindSemif'
	const fn =
		mainCtx.state.taskKind === 'Производство ГП'
			? mainCtx.stateFunctions.setProdCatPkg
			: mainCtx.stateFunctions.setProdCatKindSemif
	return (
		<CommonSelect
			showSearch
			plainValue={mainCtx.state[key]?.[field] || mainCtx.state[field]}
			setValue={fn}
			isDisabled={isDisabled}
			dataArr={dataArr}
		/>
	)
}
