import { getColumnSearchProps, sorter } from '../../../utils'
import { dateFormat } from '../../../utils/constants/common'
import { colorizeIntegrationListStatus } from '../../../utils/helpers/for-integration'
import moment from 'moment'

export const getColumns = (props) => {
	const { fromServerFilters } = props

	return [
		{
			title: 'Смена',
			dataIndex: 'workingTime',
			key: 'workingTime',
			width: '5.54%',
			render: (text) => <span style={{ wordBreak: 'break-all' }}>{text}</span>,
			...getColumnSearchProps(fromServerFilters.workingTime),
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: '5.54%',
			sorter: sorter('number', 'id'),
			...getColumnSearchProps(fromServerFilters.id),
		},
		{
			title: 'Дата производства',
			dataIndex: 'date',
			key: 'date',
			width: '10.26%',
			render: (date) => (date ? moment(date).format(dateFormat) : null),
			sorter: sorter('date', 'date'),
			...getColumnSearchProps(fromServerFilters.date, undefined, null, true),
		},
		{
			title: 'ID ДЗ',
			dataIndex: 'idContrOrder',
			key: 'idContrOrder',
			width: '6.26%',
			sorter: sorter('number', 'idContrOrder'),
			...getColumnSearchProps(fromServerFilters.idContrOrder),
		},
		{
			title: 'Вид работ',
			dataIndex: 'taskKind',
			key: 'taskKind',
			width: '8.85%',
			...getColumnSearchProps(fromServerFilters.taskKind),
		},
		{
			title: 'Операция',
			dataIndex: 'operation',
			key: 'operation',
			width: '7.96%',
			...getColumnSearchProps(fromServerFilters.operation),
		},
		{
			title: 'Партия №',
			dataIndex: 'partyNum',
			key: 'partyNum',
			width: '7.96%',
			sorter: sorter('string', 'partyNum'),
			...getColumnSearchProps(fromServerFilters.partyNum),
		},
		{
			title: 'Артикул',
			dataIndex: 'articul',
			key: 'articul',
			width: '6.69%',
			...getColumnSearchProps(fromServerFilters.articul),
		},
		{
			title: 'Артикул 1С',
			dataIndex: 'articul1C',
			key: 'articul1C',
			width: '7.39%',
			...getColumnSearchProps(fromServerFilters.articul1C),
		},
		{
			title: 'Продукт',
			dataIndex: 'productLabel',
			key: 'productLabel',
			width: '9.68%',
			sorter: sorter('string', 'productLabel'),
			...getColumnSearchProps(fromServerFilters.productLabel),
		},
		{
			title: 'Вид',
			dataIndex: 'prodCatKind',
			key: 'prodCatKind',
			width: '18.99%',
			sorter: sorter('string', 'prodCatKind'),
			...getColumnSearchProps(fromServerFilters.prodCatKind),
		},
		{
			title: 'Масса, кг',
			key: 'weight',
			dataIndex: 'weight',
			width: '7.58%',
			sorter: sorter('number', 'weight'),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: '13.1%',
			render: (_, record) => colorizeIntegrationListStatus(record.status),
			...getColumnSearchProps(fromServerFilters.status),
		},
	]
}
