import { Table } from 'antd'
import React, { useContext, useState } from 'react'
import { TabItem } from '../../../../components'
import { getDisplayColumns } from './spec-raw-mat-tab'
import { ProductCatalogItemMainContext } from '../provider/main'
import { getProdCatKindRawMatLabel } from '@berry/common-functions/cross-project-functions'
import { AuthContext, UserDataContext } from '../../../../contexts'
import { checkRole } from '@berry/common-functions/role'
import clickableRow from '../../../../utils/helpers/clickableRow'
import rowClassName from '../../../../utils/helpers/rowClassName'

const SpecRawMatTab = () => {
	const mainCtx = useContext(ProductCatalogItemMainContext)
	const authCtx = useContext(AuthContext)
	const userDataCtx = useContext(UserDataContext)
	const [rowId, setRowId] = useState('')

	const toDisplayDataList = mainCtx.state.kindRawMats
		?.map((item) => {
			return item.specs?.filter((el) => {
				el.quality = getProdCatKindRawMatLabel(item)
				return el.status === 'Утверждено'
			})
		})
		.flat()

	const canViewSpec = checkRole(
		{
			'АРМ офиса': {
				'Протоколы контроля': {
					view: true,
				},
			},
		},
		userDataCtx.state.rights
	)
	return (
		<TabItem
			addButton={() => {}}
			table={() => {
				return (
					<Table
						onAdd={() => {}}
						size="small"
						rowKey="id"
						rowClassName={rowClassName(rowId)}
						columns={getDisplayColumns(toDisplayDataList)}
						scroll={{ x: 800 }}
						dataSource={toDisplayDataList}
						onRow={clickableRow(setRowId, authCtx, '/reg/specifications', canViewSpec)}
						pagination={false}
					/>
				)
			}}
		/>
	)
}

export default SpecRawMatTab
