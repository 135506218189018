import React, { useCallback, useContext, useEffect, useRef } from 'react'
import lodash from 'lodash'
import { message, Space, Typography } from 'antd'
import { ModContext, AuthContext, FileContext } from '../../../../contexts'
import {
	prepareObjFromServer,
	modes,
	openNewTab,
	useItemFetchers,
	axios,
	getNewObj,
	appendFiles,
	goToItem,
} from '../../../../utils'
import { isTextParam, dataUrl, groupBy } from '../specification-item'
import { symbolSelectValues } from '../../../Vocabulary/SpecParam/SpecParamItem/provider/main'
import { addFile as addNewFile } from '../../../../utils/helpers/for-files'
import { showSaveAsDraft } from '../../../../utils/constants/for-components'
import { basicValidator } from '@berry/common-functions/validators'
import { getCommonProviderFunctions } from '../../../../utils/helpers/generators'
import { useHistory } from 'react-router-dom'
import { asyncShowConfirmModal } from '../../../../components'
import { sortWithSpec } from '../modals/utils'
import { v4 } from 'uuid'
import { commonCheckIsBlocked } from '../../../../utils/helpers/for-block-unblock'

const reducer = (state) => ({
	...state,
})
const getInitialState = (inData) => {
	let data
	let specCopy = null
	if (localStorage.getItem('specCopy')) {
		specCopy = JSON.parse(localStorage.getItem('specCopy'))
		if (specCopy) {
			localStorage.removeItem('specCopy')
			data = specCopy
		}
	} else {
		data = lodash.cloneDeep(inData)
	}
	prepareObjFromServer(data)
	return { specCopy, data }
}

const tabs = { parameters: 'parameters', 'event-histories': 'eventHistories' }

const SpecificationItemContext = React.createContext()
SpecificationItemContext.displayName = 'SpecificationItemContext'

const Provider = (props) => {
	const {
		children,
		params: { id },
	} = props
	const authCtx = useContext(AuthContext)
	const modCtx = useContext(ModContext)
	const fileCtx = useContext(FileContext)
	const history = useHistory()
	const initialState = getInitialState({})
	const [state, dispatch] = React.useReducer(reducer, {
		formErrors: {},
		data: initialState.specCopy || { status: 'Черновик', parameters: [] },
		oldData: initialState.specCopy || { status: 'Черновик', parameters: [] },
		additional: {
			allSelectVocProdTypes: [],
			allSelectProdCats: [],
			allQualities: [],
			allMeasures: [],
			allSpecParams: [],
			allSpecNames: [],
		},
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}

	useEffect(() => {
		if (stateRef.current.data.parameters?.length) {
			const allFiles = stateRef.current.data.parameters.flatMap((param) => param.photos || [])
			fileCtx.addFiles(allFiles, 'photoPath')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateRef.current.data])

	const setErrors = (errors) => {
		if (modCtx.mod === modes.view) return
		executeDispatch({
			...stateRef.current,
			formErrors: errors,
		})
	}

	useItemFetchers(dataUrl, id, tabs, stateRef, useCallback(executeDispatch, []))

	const selectors = {
		vocProdType: stateRef.current.additional.allSelectVocProdTypes,
		prodCat: stateRef.current.additional.allSelectProdCats,
		kindRawMat:
			stateRef.current.data.prodCat?.kindRawMats ||
			stateRef.current.additional.allSelectProdCats.find(
				(p) => p.id === stateRef.current.data.prodCat?.id
			)?.kindRawMats ||
			[],
		kindReadyProd:
			stateRef.current.data.prodCat?.kindReadyProds ||
			stateRef.current.additional.allSelectProdCats.find(
				(p) => p.id === stateRef.current.data.prodCat?.id
			)?.kindReadyProds ||
			[],
		vocQuality:
			stateRef.current.data.vocProdType?.displayVal === 'Готовая продукция'
				? stateRef.current.additional.allQualities.filter((q) => q.isReadyProd)
				: stateRef.current.data.vocProdType?.displayVal === 'Сырье'
				? [
						{ displayVal: 'Не выбрано', value: null },
						...stateRef.current.additional.allQualities.filter((q) => q.isRawMat),
				  ]
				: [],
		vocMeasure: stateRef.current.additional.allMeasures,
		vocSpecParam: stateRef.current.additional.allSpecParams,
		parameters: {
			vocMeasure: stateRef.current.additional.allMeasures,
			vocSpecParam: stateRef.current.additional.allSpecParams,
			vocSpecParamParam: stateRef.current.additional.allSpecParams.reduce(
				(acc, cur) => [
					...acc,
					...cur.params.map((param) => ({
						...param,
						spec: { id: cur.id, displayVal: cur.displayVal },
					})),
				],
				[]
			),
			etalonSymbol: (inUuid) => symbolSelectValues,
			deviationSymbol: (inUuid) => symbolSelectValues,
		},
	}
	const {
		stateFunctions,
		commonFieldUpdate,
		commonDeepFieldUpdate,
		commonObjUpdate,
		setError,
		serverDelete,
		isEdited,
		getEditedData,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{
			modCtx,
			dataUrl,
			pageUrl: dataUrl,
			history,
			params: { id },
		},
		selectors,
		{
			label: 'common',
			shelfLife: 'common',
			vocMeasure: 'common',
			isDraft: 'common',
			status: 'common',
		},
		{
			parameters: {
				etalonVal1: 'common',
				etalonVal2: 'common',
				etalonSymbol: 'common',
				etalonText: 'common',
				deviationVal1: 'common',
				deviationVal2: 'common',
				deviationSymbol: 'common',
				deviationText: 'common',
			},
		}
	)

	const copySpecification = () => {
		const fieldsToDrop = [
			'label',
			'dateOfApprove',
			'idKindReadyProd',
			'idKindRawMat',
			'idVocQuality',
		]
		let specCopyData = lodash.cloneDeep(stateRef.current.oldData)
		for (const field of fieldsToDrop) {
			specCopyData[field] = undefined
		}
		specCopyData.version = 0
		specCopyData['versions'] = []
		specCopyData['eventHistories'] = []
		specCopyData['status'] = 'Не утверждено'
		openNewTab(`${dataUrl}/new`, {
			authCtx: authCtx.state,
			modCtx: {
				mod: modes.new,
			},
			specCopy: specCopyData,
		})
	}

	/**
	 * Валидация всех полей в соответствии с конфлюенсом
	 * @returns {Array} - если вернулся массив и не пустой значит есть ошибка валидации
	 */
	const errorFields = {
		parameter: [
			'parameter.etalonSymbol',
			'parameter.etalonVal1',
			'parameter.etalonVal2',
			'parameter.deviationSymbol',
			'parameter.deviationVal1',
			'parameter.deviationVal2',
			'parameter.etalonText',
		],
	}
	const validators = {
		parameters: (inUuid) => {
			const found = stateFunctions.parameters.get(inUuid)
			const isText = isTextParam(
				found.vocSpecParamParam?.id,
				selectors.parameters.vocSpecParamParam
			)
			if (!isText) {
				if (!found.deviationSymbol) {
					setError([], [], `parameter.${found._uuid_}`)
				}
				if (found.etalonSymbol === '-') {
					if (!basicValidator(found.etalonVal1) || !basicValidator(found.etalonVal2)) {
						setError([], [], `parameter.${found._uuid_}`)
					}
				}
				if (['>', '<', '='].includes(found.etalonSymbol)) {
					if (!basicValidator(found.etalonVal2)) {
						setError([], [], `parameter.${found._uuid_}`)
					}
				}
				if (found.deviationSymbol === '-') {
					if (!basicValidator(found.deviationVal1) || !basicValidator(found.deviationVal2)) {
						setError([], [], `parameter.${found._uuid_}`)
					}
				}
				if (['>', '<', '='].includes(found.deviationSymbol)) {
					if (!basicValidator(found.deviationVal2)) {
						setError([], [], `parameter.${found._uuid_}`)
					}
				}
			} else {
				if (!found.deviationText?.trim()) {
					setError([], [], `parameter.${found._uuid_}`)
				}
			}
			if (stateRef.current.formErrors[`parameter.${found._uuid_}`]) {
				return false
			}
			return true
		},
	}
	const confirmDraftText = (
		<Space direction="vertical" size="small">
			<Typography.Text>Не заполнены обязательные поля или не добавлены параметры.</Typography.Text>
			<Typography.Text>
				При сохранении карточка будет сохранена со статусом "Черновик".
			</Typography.Text>
			<Typography.Text>При отмене продолжится редактирование карточки.</Typography.Text>
		</Space>
	)
	const confirmDraft = async (title) => {
		const isDraft = await asyncShowConfirmModal({
			...showSaveAsDraft,
			title: title ? (
				<Space direction="vertical" size="small">
					{title.split('.').map((txt) => (
						<Typography.Text>{txt}</Typography.Text>
					))}
				</Space>
			) : (
				confirmDraftText
			),
		})
		if (isDraft) stateFunctions.setStatus('Черновик')
		return isDraft
	}
	const validate = ({ pre = false } = {}) => {
		const errors = {}
		if (!stateRef.current.data.parameters?.length) {
			errors.parameters = true
		}
		for (const param of stateRef.current.data.parameters) {
			validators.parameters(param._uuid_)
		}
		if (Object.keys(errors).length && pre) {
			return 'Не заполнены обязательные поля или не добавлены параметры. При сохранении карточка будет сохранена со статусом "Черновик". При отмене продолжится редактирование карточки.'
		}
		executeDispatch({
			...stateRef.current,
			data: {
				...stateRef.current.data,
				isDraft: true,
				status: 'Не утверждено',
			},
		})
		return ''
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			formErrors: {},
		})
	}, [stateRef.current.oldData])
	const reset = () => {
		modCtx.set(modes.view)
		setErrors([])
		_reset()
	}

	/**
	 * подготавливает данные которые нужно выбират
	 */

	const serverMatch = async (obj) => {
		try {
			let options = { url: dataUrl, method: 'PUT' }
			const responseId = await axios.put(
				options.url,
				{ ...obj, id: stateRef.current.data.id },
				options
			)
			executeDispatch({ ...stateRef.current, reloadUuid: v4() })
			modCtx.set(modes.view)
			setErrors([])
			return { method: options.method, id: responseId }
		} catch (err) {
			console.log(err.message)
		}
	}

	useEffect(() => {
		if (id === 'new') return
		_reset()
	}, [_reset, stateRef.current.oldData, id])
	const serverEdit = async () => {
		try {
			const formData = appendFiles(
				stateRef.current.data.parameters,
				'photos',
				'_uuid_',
				'lab_ind',
				{
					key: 'photoPath',
				}
			)
			let body
			let options = { url: dataUrl }
			if (id === 'new') {
				body = lodash.cloneDeep(stateRef.current.data)
				options.method = 'POST'
			} else {
				body = getEditedData()
				options.method = 'PUT'
			}
			formData.append('data', JSON.stringify(body))
			executeDispatch({ ...stateRef.current, isPendingReq: true })
			const res = await axios[options.method.toLowerCase()](options.url, formData)
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			modCtx.set(modes.view)
			if (res.data?.data?.id) {
				if (options.method === 'POST') {
					goToItem(history, { url: dataUrl, id: res.data.data.id })
				}
				executeDispatch({ ...stateRef.current, reloadUuid: v4() })
				modCtx.set(modes.view)
				return { method: options.method, id: res.data.data.id }
			}
			return res
		} catch (err) {
			message.error('Ошибка при сохранении')
			console.log(err.message)
		}
	}
	stateFunctions.setStatus = (val) => {
		commonFieldUpdate('status', val, false, true)
	}
	stateFunctions.setProductType = (val) => {
		commonObjUpdate('vocProdType', val)
		commonFieldUpdate('kindRawMat', null)
		commonFieldUpdate('kindReadyProd', null)
		commonFieldUpdate('vocQuality', null)
	}
	stateFunctions.setProduct = (val) => {
		commonObjUpdate('prodCat', val)
		commonFieldUpdate('kindReadyProd', null)
		commonFieldUpdate('kindRawMat', null)
	}
	stateFunctions.setProductKind = (val) => {
		let kind
		if (stateRef.current.data.vocProdType?.displayVal === 'Сырье') {
			kind = 'kindRawMat'
		}
		if (stateRef.current.data.vocProdType?.displayVal === 'Готовая продукция') {
			kind = 'kindReadyProd'
		}
		commonFieldUpdate(kind, val)
	}
	stateFunctions.setVocQuality = (val) => {
		if (!val) {
			commonFieldUpdate('vocQuality', val)
		} else {
			commonObjUpdate('vocQuality', val)
		}
	}
	stateFunctions.parameters.addPhoto = (inUuid, vals) => {
		addNewFile(vals, 'photoPath', 'spec')
		commonDeepFieldUpdate(['parameters'], [inUuid], 'photos', vals)
		fileCtx.addFiles(lodash.cloneDeep(vals), 'photoPath')
	}
	stateFunctions.parameters.removePhoto = (inUuid, photo) => {
		const newPhotos = stateFunctions.parameters
			.get(inUuid)
			?.photos.filter((p) =>
				photo.id ? p.id !== photo.id : p.photoPath !== photo.originFileObj?.photoPath
			)
		commonDeepFieldUpdate(['parameters'], [inUuid], 'photos', newPhotos)
	}
	stateFunctions.parameters.create = (inData) => {
		let newParams = []
		for (const param of inData) {
			let newParam = getNewObj(
				param.isText
					? {
							etalonText: param.etalonText,
							deviationText: param.deviationText,
					  }
					: param.isNumber
					? {
							etalonVal1: param.etalonVal1,
							etalonSymbol: param.etalonSymbol,
							etalonVal2: param.etalonVal2,
							deviationVal1: param.deviationVal1,
							deviationSymbol: param.deviationSymbol,
							deviationVal2: param.deviationVal2,
					  }
					: {}
			)
			newParam.vocSpecParamParam = selectors.parameters.vocSpecParamParam.find(
				(select) => select.id === param.id
			)
			newParams = [...newParams, newParam]
		}
		commonFieldUpdate('parameters', [...(stateRef.current.data.parameters || []), ...newParams])
	}
	stateFunctions.parameters.delete = (ids) => {
		const newParams = stateRef.current.data.parameters.filter(
			(p) => !ids.includes(p.vocSpecParamParam.id)
		)
		commonFieldUpdate('parameters', newParams)
	}
	const getActualState = () => {
		const obj = lodash.cloneDeep(stateRef.current)
		if (obj.data.parameters) {
			obj.data.parameters = groupBy(obj.data.parameters, 'vocSpecParamParam.spec.label').sort(
				(a, b) => sortWithSpec(a.vocSpecParamParam, b.vocSpecParamParam)
			)
		}
		return obj
	}

	const checkIsBlocked = async () => {
		const result = await commonCheckIsBlocked([
			{ entity: 'regSpecification', id: stateRef.current.id },
		])
		return result[0]?.isBlocked
	}

	const value = {
		state: getActualState(),
		checkIsBlocked,
		isPendingReq: stateRef.current.isPendingReq,
		additional: stateRef.current.additional,
		selectors,
		stateFunctions,
		serverEdit,
		serverDelete,
		isEdited,
		copySpecification,
		validate,
		confirmDraft,
		reset,
		setErrors,
		formErrors: stateRef.current.formErrors,
		errorFields,
		validators,
		serverMatch,
	}
	return (
		<SpecificationItemContext.Provider value={value}>{children}</SpecificationItemContext.Provider>
	)
}

export { Provider, SpecificationItemContext }
