import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Card, Col, Row, Form } from 'antd'
import {
	ModContext,
	ModProvider,
	FileProvider,
	AuthContext,
	UserDataContext,
} from '../../../contexts'
import { Provider, ProductionTaskItemMainContext } from './provider/main'
import { Main } from '../../../layouts'
import moment from 'moment'
import {
	CommonMatchActionButtons,
	CustomForm,
	HeaderMenu,
	LoadingButton,
	showConfirmModal,
	TabList,
} from '../../../components'
import { getBreadcrumbsText } from '../../../utils/helpers/generators'
import { actBtnOnClose, actBtnOnDelete, actBtnOnSaveDraft, modes, openNewTab } from '../../../utils'
import { getFormItems1, getFormItems2 } from './production-task-item'
import ComplectationTab from './ComplectationTab/ComplectationTab'
import ReportTab from './ReportTab/ReportTab'
import EventHistoryTab from './EventHistoryTab'
import MatchQueueTab from './MatchQueueTab/MatchQueueTab'

const pageUrl = '/production/tasks'
const rights = {
	'АРМ офиса': {
		'Задачи на производство': {
			edit: true,
			'Изменить статус': {
				edit: true,
			},
		},
	},
}

const ProductionTaskItem = (props) => {
	return (
		<ModProvider mod={props.match.params.id === 'new' ? modes.new : undefined}>
			<FileProvider>
				<Provider params={{ id: props.match.params.id }}>
					<Main title="Задачи на производство">
						<InfoComponent />
					</Main>
				</Provider>
			</FileProvider>
		</ModProvider>
	)
}
const InfoComponent = () => {
	const mainCtx = useContext(ProductionTaskItemMainContext)
	const modCtx = useContext(ModContext)
	const authCtx = useContext(AuthContext)
	const userDataCtx = useContext(UserDataContext)
	const {
		state: { rights: currentRights, isDevAdmin, match },
	} = userDataCtx
	const history = useHistory()
	const [form] = Form.useForm()

	const maxTaskMatchLvl =
		mainCtx.isInitializedMain && mainCtx.isInitializedAdditional
			? Math.max(
					...mainCtx.additional.allPosTypes
						?.filter((el) => {
							return el[mainCtx.keysForMatch.lvlApprove] !== '-'
						})
						?.map((pos) => pos[mainCtx.keysForMatch.lvlApprove])
			  )
			: null
	const isVisibleSendTo1cBtn =
		+match?.[mainCtx.keyForMatchType]?.[mainCtx.keysForMatch.lvlApprove] === maxTaskMatchLvl ||
		(mainCtx.state.idContrOrder && mainCtx.state.status !== 'Утверждена')

	if (mainCtx.state.__notFound) {
		history.replace(pageUrl)
		return null
	}

	const bread = getBreadcrumbsText(pageUrl, mainCtx.state)

	let isShowSendTo1cButton = false
	if (
		['Производство ГП', 'Производство ПФ'].includes(mainCtx.state.taskKind) &&
		modCtx.mod === modes.view
	) {
		if (
			isDevAdmin &&
			['Ожидает отправки', 'Отклонено 1С', 'Ошибка отправки в 1С'].includes(mainCtx.state.status)
		)
			isShowSendTo1cButton = true

		if (isVisibleSendTo1cBtn && ['Ошибка отправки в 1С'].includes(mainCtx.state.status))
			isShowSendTo1cButton = true
	}

	return (
		<>
			<HeaderMenu
				breadcrumbsTitle={bread.head}
				recordLabel={mainCtx.state.id ? bread.label : 'Новая'}
			>
				<Row>
					<CommonMatchActionButtons
						isPendingReq={mainCtx.isPendingReq}
						ctx={mainCtx}
						matchObject={mainCtx.isProdTaskPf ? 'taskPf' : 'task'}
						allowMatchOrApproveForTask={{
							isLoaded: mainCtx.calcDataLoaded.isAllowMatchOrApproveForTask,
							isAllowMatchOrApproveForTask: mainCtx.isAllowMatchOrApproveForTask,
						}}
						originalStatus="Ожидает отправки"
						rights={rights}
						formName="productionTask"
						canEdit={
							mainCtx.isInitializedAdditional &&
							['Черновик', 'Ожидает отправки', 'Отклонено 1С'].includes(mainCtx.state.status)
						}
						canDelete={
							modCtx.mod !== modes.new &&
							['Черновик', 'Ожидает отправки', 'Отклонено 1С'].includes(mainCtx.state.status)
						}
						onClose={() => {
							actBtnOnClose({
								history,
								pageUrl,
								showConfirmModal,
								isEdited: mainCtx.isEdited,
							})
						}}
						onSave={async () => {
							const result = await actBtnOnSaveDraft({
								serverEdit: mainCtx.serverEdit,
								validate: mainCtx.validate,
								pageUrl: pageUrl,
								history: history,
								isShowErrorModal: true,
								showConfirmModal,
								confirmModalWidth: 624,
								form,
								canSaveWithErrors: true,
								confirmDraft: mainCtx.confirmDraft,
								draft: true,
							})
							mainCtx.setDeletedProds()
							if (result) {
								mainCtx.dropErrors()
							}
						}}
						onEdit={() => {
							modCtx.set(modes.edit)
						}}
						onDelete={async () => {
							await actBtnOnDelete({
								history: history,
								pageUrl: pageUrl,
								showConfirmModal,
								deleteFun: mainCtx.serverDelete,
								deps: ['Черновик', 'Ожидает отправки'].includes(mainCtx.state.status)
									? []
									: mainCtx.delEditDeps?.['prdctn_task'][mainCtx.state.id],

								openNewPage: (url) => {
									return openNewTab(url, {
										authCtx: authCtx.state,
									})
								},
								currentRights,
							})
						}}
						onCancel={() => {
							mainCtx.setDeletedProds()
							const allFields = form.getFieldsError()
							allFields.forEach((f) => {
								f.errors = []
							})
							form.setFields(allFields)
							mainCtx.reset()
						}}
						additionalButtonsPre={
							isShowSendTo1cButton
								? [
										<LoadingButton key="send-to-1c" onClick={mainCtx.serverSendTo1c}>
											Отправить в 1С
										</LoadingButton>,
								  ]
								: null
						}
					/>
				</Row>
			</HeaderMenu>
			<Card style={{ marginBottom: 10 }}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<CustomForm
							form={form}
							fields={[
								{
									name: 'date',
									value: mainCtx.state.date ? moment(mainCtx.state.date) : null,
								},
								{ name: 'partyNum', value: mainCtx.state.partyNum },
								{
									name: 'articul',
									value:
										mainCtx.state.prodCatPkg?.articul || mainCtx.state.prodCatKindSemif?.articul,
								},
								{
									name: 'articul1C',
									value:
										mainCtx.state.prodCatPkg?.articul1C ||
										mainCtx.state.prodCatKindSemif?.articul1C,
								},
								{ name: 'taskKind', value: mainCtx.state.taskKind },
								{ name: 'prodCat', value: mainCtx.state.prodCat },
								{ name: 'prodCatPkg', value: mainCtx.state.prodCatPkg },
								{ name: 'spec', value: mainCtx.state.spec },
								{
									name: 'prodCatKindSemif',
									value: mainCtx.state.prodCatKindSemif,
								},
								{ name: 'operation', value: mainCtx.state.operation },
								{ name: 'contType', value: mainCtx.state.contType },
								{ name: 'workingTime', value: mainCtx.state.workingTime },
								{ name: 'status', value: mainCtx.state.status },
								{ name: 'totalWeight', value: '' },
								{ name: 'customer', value: mainCtx.state.customer },
								{ name: 'isTolling', value: true },
								{ name: 'isSamples', value: mainCtx.state.isSamples },
								{ name: 'isSavePartyNum', value: mainCtx.state.isSavePartyNum },
							]}
							columns={[
								{ size: { xs: 24, md: 12, xl: 8 }, num: 0 },
								{ size: { xs: 24, md: 12, xl: 9 }, num: 1 },
								{ size: { xs: 24, md: 12, xl: 7 }, num: 2 },
							]}
							items={getFormItems1(mainCtx, modCtx, form, userDataCtx)}
						/>

						{'Производство ГП' === mainCtx.state.taskKind && (
							<CustomForm
								form={form}
								name="productionTask2"
								fields={[
									{ name: 'isFabricatedParty', value: mainCtx.state.isFabricatedParty },
									{ name: 'fabricatedNumText', value: mainCtx.state.fabricatedNumText },
									{ name: 'fabricatedNumVal', value: mainCtx.state.fabricatedNumVal },
								]}
								items={getFormItems2(mainCtx, modCtx)}
							/>
						)}
					</Col>
				</Row>
			</Card>

			<TabList
				tabs={[
					{ key: 0, title: 'Комплектация', component: <ComplectationTab /> },
					{ key: 1, title: 'Отчет', component: <ReportTab /> },
					{ key: 2, title: 'Очередь согласования', component: <MatchQueueTab /> },
					{ key: 3, title: 'История событий', component: <EventHistoryTab /> },
				]}
			/>
		</>
	)
}

export default ProductionTaskItem
