import React, { useContext, useEffect, useRef } from 'react'

import {
	everyOtherTimeUseEffectHandlerForList,
	startUseEffectHandlerForList,
} from '../../../../../utils'
import { ListContext, SyncDepsContext } from '../../../../../contexts'

export const dataUrl = '/stock/operations/reloc-remote-warehouses'

export const reducer = (state) => {
	return {
		...state,
	}
}

const RelocRemoteWarehouseListMainContext = React.createContext()
RelocRemoteWarehouseListMainContext.displayName = 'RelocRemoteWarehouseListMainContext'

const Provider = (props) => {
	const { children } = props
	const syncDepsCtx = useContext(SyncDepsContext)

	const {
		state: { page, pageSize, filters, mainFilter, sorter },
		setTotal,
	} = useContext(ListContext)
	const [state, dispatch] = React.useReducer(reducer, {
		search: null,
		filter: {},
		fromServer: [],
		fromServerFilters: {
			id: [],
			date: [],
			__idContrOrder: [],
			customerLabel: [],
			prodCat: [],
			status: [],
		},
		isInitialized: false,
		isLoading: false,
	})
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	useEffect(() => {
		const getData = async () => {
			startUseEffectHandlerForList({
				executeDispatch,
				stateRef,
				toServerParams: {
					sorter,
					filters,
					mainFilter,
					offset: pageSize * page - pageSize,
					limit: pageSize,
				},
				setTotal,
				syncDepsCtx,
				dataUrl,
			})
		}
		getData()
	}, [])
	useEffect(() => {
		const getData = async () => {
			everyOtherTimeUseEffectHandlerForList({
				executeDispatch,
				stateRef,
				toServerParams: {
					offset: pageSize * page - pageSize,
					limit: pageSize,
					sorter,
					mainFilter,
					filters,
					search: stateRef.current.search,
				},
				setTotal,
				syncDepsCtx,
				dataUrl,
			})
		}
		getData()
	}, [
		page,
		sorter,
		filters,
		mainFilter,
		stateRef.current.search,
		syncDepsCtx.state.reloadUuids['office-ms'],
	])

	const setSearch = (value) => {
		executeDispatch({ ...stateRef.current, search: value?.toString() || '' })
	}

	const value = {
		state: stateRef.current,
		toDisplayDataList: stateRef.current.fromServer,
		// searchFields,
		setSearch,
	}

	return (
		<RelocRemoteWarehouseListMainContext.Provider value={value}>
			{children}
		</RelocRemoteWarehouseListMainContext.Provider>
	)
}

export { Provider, RelocRemoteWarehouseListMainContext }
