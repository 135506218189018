const rowClassName = (rowId) => (rec) => {
	if (rec.canMatch) {
		if ((rec.idStorage || rec.id) === rowId) return 'bold clickable row-highlight'
		return 'bold'
	}
	if ((rec.idStorage || rec.supplyId || rec.id || rec.link) === rowId) {
		return `clickable row-highlight`
	}
	return `clickable`
}

export default rowClassName
