const { basicValidator, isValidNum } = require('./validators')
const lodash = require('lodash')
const moment = require('moment')
/**
 * вычисление калибра
 * @param {Object} inRec - основной контекст
 */
const getProdCatCaliber = (inRec = {}) => {
	if (!inRec) inRec = {}
	return `${[undefined, null].includes(inRec.valueOne) ? '' : inRec.valueOne}${
		inRec.symbol ? inRec.symbol : ''
	}${[undefined, null].includes(inRec.valueTwo) ? '' : inRec.valueTwo} ${
		inRec.vocMeasure?.labelShort || ''
	}`
}

/**
 * действует ли договор на данный момент
 * @param {Object} inParams
 * @param {String} inParams.status1C - статус договора в 1С
 * @returns {boolean}
 */
const isContractActive = (inParams) => {
	return inParams.status1C === 'Действует'
}

/**
 * получить наименование калибра
 * @param {Object} inRecord
 */
const getPrecalculatedCaliber = (inRecord) => {
	let precalculatedCaliber = ''
	if (inRecord?.caliber?.id === -1 || inRecord?.isNotCalibratedCaliber) {
		precalculatedCaliber = 'некалиброван.'
	} else {
		if (basicValidator(inRecord.caliber)) {
			precalculatedCaliber = `калиброван. ${getProdCatCaliber(inRecord.caliber)}`
		}
	}
	return precalculatedCaliber
}

/**
 * получить наименование вида готовой продукции
 * @param {number} inId
 * @param {Object} inRecord
 * @param {Object} mainCtx
 */
const getProdCatKindReadyProdLabel = (inRecord) => {
	let precalculatedCaliber = getPrecalculatedCaliber(inRecord)
	let caliber = ''
	if (inRecord.vocReadyProdForm?.isBindCaliber) {
		caliber =
			(precalculatedCaliber?.slice(0, precalculatedCaliber.indexOf('.') + 1) || '') +
			' ' +
			(precalculatedCaliber?.slice(precalculatedCaliber.indexOf('.') + 1) || '')
		return `${inRecord.additAttr?.label || ''} б/з ${inRecord.vocReadyProdForm?.label || ''} ${
			caliber || ''
		}`
			.replace(/\s+/g, ' ')
			.trim()
	}
	return `${inRecord.additAttr?.label || ''} б/з ${
		inRecord.vocReadyProdForm?.labelShort || ''
	} ${caliber}`
		.replace(/\s+/g, ' ')
		.trim()
}

const getProdCatKindSemifDisplayVal = (inRecord) => {
	if (!inRecord) return null
	if (inRecord.vocProdType?.labelShort === 'ВО') {
		let label = `ВО от ${inRecord.vocMainSemiKindKind?.label || ''}`
		if (inRecord.isReturnWaste) {
			if (inRecord.vocProdTypeReturnWaste?.labelShort === 'ВО') {
				label += ` - ${inRecord.vocProdTypeReturnWaste.labelShort} от ${
					inRecord.vocMainSemiKindKind2?.label || ''
				}`
			} else {
				label += ` - ${inRecord.vocMainSemiKindKind2?.label || ''}`
			}
		}
		if (inRecord.additArrt?.label) {
			label = label + ` - ${inRecord.additArrt.label || ''}`
		}
		if (inRecord.quality?.label) {
			label += ` - ${inRecord.quality.label}`
		}
		return label
	}
	if (inRecord.vocProdType?.labelShort === 'НО') {
		let label = `НО от ${inRecord.vocMainSemiKindKind.label || ''} - ${
			inRecord.kpVocWaseType?.label || ''
		}`
		if (inRecord.quality) {
			label += ` - ${inRecord.quality.label}`
		}
		return label
	}

	if (inRecord.vocProdType?.labelShort === 'ПФ') {
		let additAttr = inRecord.additArrt?.label || ''
		if (additAttr !== '') {
			additAttr = ' - ' + additAttr
		}
		let label = `${inRecord.vocMainSemiKindKind?.label || ''} ${
			getProdCatCaliber(inRecord.kpCaliber) === '' ? '' : getProdCatCaliber(inRecord.kpCaliber)
		}${additAttr}`
		if (inRecord.quality) {
			label += ` - ${inRecord.quality.label}`
		}
		return label
	}
	if (inRecord.vocProdType?.labelShort === 'ДП') {
		let additAttr = ``
		if (inRecord.additArrt?.label) {
			additAttr += ` - ${inRecord.additArrt.label}`
		}
		if (inRecord.quality) {
			additAttr += ` - ${inRecord.quality.label}`
		}
		return `${inRecord.kpVocAdditSemiType?.label || ''} ${additAttr}`
	}

	return ''
}

/**
 * Возвращает лейбл для карточки спецификации
 * @param {string} inSpec
 */

const getSpecLabel = (inSpec) => {
	if (inSpec?.kindReadyProd) {
		return getProdCatKindReadyProdLabel(inSpec.kindReadyProd)
	}
	if (inSpec?.kindRawMat) {
		return getProdCatKindRawMatLabel(inSpec.kindRawMat)
	}
	return ''
}

/**
 * вычисляет наименование сырья у продуктового каталога
 * @param {string} inLabel
 * @param {Object} inRecord
 */
const getProdCatKindRawMatLabel = (inRecord) => {
	return `${inRecord?.vocTempRegime?.labelShort || ''} ${
		inRecord?.vocRawMatState?.labelShort || ''
	}`
		.replace(/\s+/g, ' ')
		.trim()
}

/**
 * вычисляет артикул
 * @param {number} inId
 * @param {Object} inRecord
 */
const getProdCatKindRawMatsDisplayCode = (idDisplayCode, inRecord) => {
	return `${idDisplayCode || ''}.С${inRecord.vocTempRegime?.displayCode || ''}${
		inRecord.vocRawMatState?.displayCode || ''
	}`
		.replace(/\s+/g, ' ')
		.trim()
}

/**
 * вычисляент ставится ли галочка Давалец (Д ) у задачи на производство
 */
const calcProdTaskIsTolling = (inTask, isEmptyTask = false) => {
	if (isEmptyTask && inTask === undefined) return
	return !!(inTask?.complectations || inTask?.productions || [])[0]?.stockRawMatStor?.stockRawMat
		?.supplProd?.contrQuota?.contract?.tolling
}

/**
 * рассчитать артикул
 * @param {number} inUuid
 * @param {Object} mainCtx - основной контекст
 */
const getProdCatKindSemifArticul = (inRecord, prodCat, vocMainSemiKinds) => {
	if (!inRecord?.vocProdType) return

	const foundMainSemiKind = vocMainSemiKinds
		? findProdCatVocMainSemiKind(inRecord.vocMainSemiKindKind?.id, vocMainSemiKinds)
		: inRecord.vocMainSemiKindKind?.mainSem

	const isNO = inRecord.vocProdType.labelShort === 'НО'
	const isVO = inRecord.vocProdType.labelShort === 'ВО'
	const isPF = inRecord.vocProdType.labelShort === 'ПФ'
	const isDP = inRecord.vocProdType.labelShort === 'ДП'

	const xx = prodCat.displayCode
	const p = inRecord.additArrt ? '/' + inRecord.additArrt?.displayCode : ''
	const k = inRecord.quality?.displayCode ? '.' + inRecord.quality.displayCode : ''

	const commonPart = xx + p + k // XX/P.K

	const y = foundMainSemiKind.vocProcess?.displayCode || ''
	const o = inRecord.vocMainSemiKindKind?.displayCode || ''

	if (isNO || isVO) {
		const foundProdCatVocMainSemiKind = vocMainSemiKinds
			? findProdCatVocMainSemiKind(inRecord.vocMainSemiKindKind2?.id, vocMainSemiKinds)
			: inRecord.vocMainSemiKindKind2?.mainSem
		const d = isVO ? 'В' : 'Н'
		const lastPart = `${d}${y}${o}`
		let articul = `${commonPart}.${lastPart}`
		if (isNO) {
			articul += inRecord.kpVocWaseType ? '.' + inRecord.kpVocWaseType.displayCode : ''
			return articul
		}
		if (inRecord.isReturnWaste) {
			const isVoType = inRecord.vocProdTypeReturnWaste?.labelShort === 'ВО'
			if (isVoType) {
				const d = 'В'
				const v = foundProdCatVocMainSemiKind?.vocProcess?.displayCode || ''
				const n = inRecord.vocMainSemiKindKind2?.displayCode || ''
				articul += `.${d}${v}${n}`
			} else {
				const m = foundProdCatVocMainSemiKind?.vocProcess?.displayCode || ''
				const t = inRecord.vocMainSemiKindKind2?.displayCode || ''
				if (m || t) {
					articul += `.${m}${t}`
				}
			}
		}
		return articul
	} else if (isPF || isDP) {
		const v = isPF
			? inRecord.kpCaliber?.displayCode || '0'
			: inRecord.kpVocAdditSemiType?.displayCode || ''
		return `${commonPart}.${y}${o}${v}`
	}
	return ''
}

/**
 * поиск процеесса в зависимости от вида в справочникуе основных пф
 * @param {number} inKindId
 * @param {Object} mainCtx - основной контекст
 */
const findProdCatVocMainSemiKind = (inKindId, vocMainSemiKinds) => {
	const found = vocMainSemiKinds?.find((e) => e.kinds.some((k) => +k.id === +inKindId))
	return found || null
}

/**
 * вычисляет артикул упаковки продукта из прод каталога
 * @param {Object} inRecord
 * @param {Object} mainCtx
 * @param {Object} dataServerCtx
 */
const getProdCatPkgDisplayCode = (inRecord, prodCat, productCatalog) => {
	return `${
		getProdCatKindReadyProdCode(
			prodCat.displayCode || productCatalog?.length,
			inRecord.kindReadyProd
		) || ''
	}-${inRecord.vocQuality?.displayCode || ''}-${inRecord.vocContType?.displayCode}${
		inRecord.weight
	}`
}

/**
 * вычисляет артикул вида готовой продукции продукта из прод каталога
 * @param {number} inDisplayCode
 * @param {Object} inRecord
 */
const getProdCatKindReadyProdCode = (inDisplayCode, inRecord) => {
	const caliberDisplayCode = inRecord.caliber?.displayCode || ''
	let additAttr = inRecord.additAttr?.displayCode || ''
	if (additAttr !== '') additAttr = `/${additAttr}`
	return `${inDisplayCode || ''}${additAttr}.${inRecord.vocReadyProdForm?.displayCode || ''}${
		caliberDisplayCode ? '.' + caliberDisplayCode : ''
	}`
}

/**
 * получить артикул артикул 1с и вид выпуск продукции
 */
const getSomeCalculatedParamsForProdTask = (inObj, allMainSemiKinds) => {
	if (inObj.prodCatPkg && inObj.taskKind === 'Производство ГП') {
		return {
			articul: getProdCatPkgDisplayCode(
				inObj.prodCatPkg,
				inObj.prodCatPkg?.prodCat || inObj.prodCat
			),
			uuid1C: inObj.prodCatPkg.vocProd1c?.uuid1C,
			articul1C: inObj.prodCatPkg.vocProd1c?.vendorCode,
			prodCatKind: getProdCatPackageLabel(inObj.prodCatPkg),
		}
	} else if (inObj.prodCatKindSemif && inObj.taskKind === 'Производство ПФ') {
		return {
			articul: getProdCatKindSemifArticul(
				inObj.prodCatKindSemif,
				inObj.prodCatKindSemif.prodCat,
				allMainSemiKinds
			),
			articul1C: inObj.prodCatKindSemif.vocProd1c?.vendorCode,
			uuid1C: inObj.prodCatKindSemif.vocProd1c?.uuid1C,
			prodCatKind: getProdCatKindSemifDisplayVal(inObj.prodCatKindSemif),
		}
	}
	return {
		articul: '',
		articul1C: '',
		uuid1C: '',
		prodCatKind: '',
	}
}

/**
 * получить артикул артикул 1с и вид выпуск продукции
 */
const getSomeCalculatedParamsForConsReport = (
	inObj,
	selectorVocMainSemiKind,
	dataServerCtx,
	productCatalog
) => {
	if (inObj.prodCatPkg) {
		return {
			articul: getProdCatPkgDisplayCode(
				inObj.prodCatPkg,
				inObj.prodCat || inObj.prodCatPkg?.prodCat || inObj.task?.prodCat,
				{},
				productCatalog
			),
			articul1C: inObj.prodCatPkg.vocProd1c?.vendorCode,
			prodCatKind: getProdCatPackageLabel(inObj.prodCatPkg),
		}
	} else if (inObj.prodCatKindSemif && inObj.taskKind === 'Производство ПФ') {
		return {
			articul: getProdCatKindSemifArticul(
				inObj.prodCatKindSemif,
				inObj.prodCatKindSemif.prodCat,
				selectorVocMainSemiKind
			),
			articul1C: inObj.prodCatKindSemif.vocProd1c?.vendorCode,
			prodCatKind: getProdCatKindSemifDisplayVal(inObj.prodCatKindSemif),
		}
	}
	return {
		articul: '',
		articul1C: '',
		prodCatKind: '',
	}
}
/**
 * вычисляет наименование упаковки
 * @param {string} inLabel
 * @param {Object} inRecord
 */
const getProdCatPackageLabel = (inRecord) => {
	if (!inRecord) return ''
	return `${getProdCatKindReadyProdLabel(inRecord?.kindReadyProd)} - ${
		inRecord?.vocQuality?.label
	} - ${inRecord?.vocContType?.labelShort} ${inRecord?.weight} кг`
		.replace(/\s+/g, ' ')
		.trim()
}

/**
 * получить артикул артикул 1с и вид выпуск продукции для большинства сырья
 * (образцы)
 */
const getStockCalculatedParams = (inData) => {
	if (inData.toJSON) {
		inData = inData.toJSON()
	}
	let prodCatKind = null
	let articul1C = null
	let articul = null
	let prodCat = null
	let partyNum = null
	let isTolling = null
	let ctrlDate = null
	let pkgType = null
	let repStatus = null
	let date = null
	let type = null
	let uuid1C = null
	if (inData) {
		if (inData.stockRawMat || inData.stockReadyProdResale) {
			const { supplProd } = inData.stockRawMat || inData.stockReadyProdResale
			partyNum = supplProd.assignedPartyNum
			const { contrQuota, order } = supplProd
			const { prodCatKindRawMat } = (contrQuota || order).rmProvProd
			articul = getProdCatKindRawMatsDisplayCode(
				(contrQuota || order).rmProvProd.prodCatKindRawMat.prodCat.displayCode,
				(contrQuota || order).rmProvProd.prodCatKindRawMat
			)
			articul1C = prodCatKindRawMat.vocProd1c.vendorCode
			uuid1C = prodCatKindRawMat.vocProd1c.uuid1C
			const { prodCatKindLabel, prodCatLabel } = getDataForRmProvProd(
				(contrQuota || order).rmProvProd
			)
			prodCatKind = prodCatKindLabel
			prodCat = prodCatLabel
			isTolling = (contrQuota || order).contract?.tolling
			ctrlDate = supplProd.ctrlDate
		} else {
			partyNum = inData.taskRep?.partyNum
			prodCat = inData.taskRep?.task?.prodCat.label
			isTolling = calcProdTaskIsTolling(inData.taskRep?.task)
		}
		if (inData.stockSemif) {
			prodCatKind = getProdCatKindSemifDisplayVal(inData.stockSemif.taskRep.prodCatKindSemif)
			articul = getProdCatKindSemifArticul(
				inData.stockSemif.taskRep.prodCatKindSemif,
				inData.stockSemif.taskRep.prodCatKindSemif.prodCat
			)
			articul1C = inData.stockSemif.taskRep.prodCatKindSemif.vocProd1c?.vendorCode
			uuid1C = inData.stockSemif.taskRep.prodCatKindSemif.vocProd1c?.uuid1C
			prodCat = inData.stockSemif.taskRep.prodCatKindSemif.prodCat.label
			partyNum = inData.stockSemif.taskRep.partyNum
			isTolling = calcProdTaskIsTolling(inData.stockSemif.taskRep?.task)
			ctrlDate = inData.stockSemif.shelfLifeDate || inData.stockSemif.taskRep.task.date
			repStatus = inData.stockSemif.taskRep.status
			date = inData.stockSemif.taskRep.task.date || null
			type = inData.stockSemif.taskRep.type
		}
		if (inData.stockReadyProd) {
			const param = { ...inData.stockReadyProd.taskRep.task, ...inData.stockReadyProd.taskRep }
			const calculated = getSomeCalculatedParamsForProdTask(param)
			articul = calculated.articul
			articul1C = calculated.articul1C
			uuid1C = calculated.uuid1C
			prodCatKind = calculated.prodCatKind
			prodCat = inData.stockReadyProd.taskRep.task.prodCat.label
			partyNum = inData.stockReadyProd.taskRep.partyNum
			isTolling = calcProdTaskIsTolling(inData.stockReadyProd.taskRep?.task)
			ctrlDate = inData.stockReadyProd.shelfLifeDate
			repStatus = inData.stockReadyProd.taskRep.status
			date = inData.stockReadyProd.taskRep.task.date || null
			type = inData.stockReadyProd.taskRep.type
		}
		if (inData.taskRep?.type === 'ОК') {
			if ('Производство ГП' === inData.taskRep.task.taskKind) {
				prodCatKind = getProdCatPackageLabel(inData.taskRep.task.prodCatPkg)
			}
			if ('Производство ПФ' === inData.taskRep.task.taskKind) {
				prodCatKind = getProdCatKindSemifDisplayVal(inData.taskRep.prodCatKindSemif)
			}
		}
		if (['ОК', 'ГП', 'ДГП'].includes(inData.taskRep?.type)) {
			let calculated = getSomeCalculatedParamsForProdTask(inData.taskRep.task)
			articul = calculated.articul
			articul1C = calculated.articul1C
			uuid1C = calculated.uuid1C
			prodCatKind = calculated.prodCatKind
			repStatus = inData.taskRep.status
			date = inData.taskRep.date || null
			type = inData.taskRep.type
		} else if (inData.taskRep?.prodCatKindSemif) {
			articul = getProdCatKindSemifArticul(
				inData.taskRep.prodCatKindSemif,
				inData.taskRep.prodCatKindSemif.prodCat
			)
			articul1C = inData.taskRep.prodCatKindSemif.vocProd1c?.vendorCode
			uuid1C = inData.taskRep.prodCatKindSemif.vocProd1c?.uuid1C
			prodCatKind = getProdCatKindSemifDisplayVal(inData.taskRep.prodCatKindSemif)
			ctrlDate = inData.taskRep.task?.date
			pkgType = inData.taskRep.task?.prodCatPkg?.vocContType?.labelShort
			repStatus = inData.taskRep?.status
			date = inData.taskRep.date || null
			type = inData.taskRep.type
		}
	}
	return {
		prodCatKind,
		articul1C,
		uuid1C,
		articul,
		prodCat,
		partyNum,
		isTolling,
		ctrlDate: moment(ctrlDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
		pkgType,
		workingTime: inData?.taskRep?.task?.workingTime?.id,
		repStatus,
		date: moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
		type,
	}
}

const getProdNameByProviderQuota = (inRec) => {
	return `${inRec?.prodCat?.label} ${getDataForRmProvProd(inRec)?.prodCatKindLabel}`
		.replace(/\s+/g, ' ')
		.trim()
}

/**
 * проверка пометки тем или иным цветом
 * @param {Object} inRec - отбьект с функциями изменения состояния вкладки
 * @param {('etalon'|'deviation')} key
 */
const checkInputCtrlLabIndicatorColor = (inRec, key) => {
	let val = inRec.factVal
	if (!inRec?.specParam) return false
	if (inRec.specParam[`${key}Text`]) return false
	if (
		inRec?.specParam?.vocSpecParamParam?.vocMeasure?.labelShort === '°C' &&
		String(inRec?.factVal)?.includes(' - ')
	) {
		if (!val.split(' - ')[1].length) return false
		if (+val.split(' - ')[1] <= +val.split(' - ')[0]) return false
		val = val.split(' - ')[1]
	}
	switch (inRec.specParam[`${key}Symbol`]) {
		case '-':
			return +inRec.specParam[`${key}Val1`] <= +val && +inRec.specParam[`${key}Val2`] >= +val
		case '<':
			return +val <= +inRec.specParam[`${key}Val2`]
		case '>':
			return +val >= +inRec.specParam[`${key}Val2`]
		case '=':
			return +inRec.specParam[`${key}Val2`] === +val
		case 'не доп.':
			return 0 === +val
		default:
			return false
	}
}

const getSpecParamDeviation = (inData = {}) => {
	if (inData.deviationText) return inData.deviationText || ''
	if (inData.deviationSymbol === 'не доп.') {
		return 'Не допускается'
	}
	return `${[null, undefined].includes(inData.deviationVal1) ? '' : inData.deviationVal1} ${
		(inData.deviationSymbol === 'не доп.'
			? 'Не допускается'
			: inData.deviationSymbol !== '='
			? inData.deviationSymbol
			: '') || ''
	} ${[null, undefined].includes(inData.deviationVal2) ? '' : inData.deviationVal2}`
}

/**
 * Расхождение в массе, кг
 * @param {Object} mainCtx
 */
const calcSupplyInpCtrlDiffWeight = (state) => {
	const result = Math.round((calcSupplyInpCtrlFactWeight(state) - state.weightInv) * 100) / 100
	if (isValidNum(result)) return result
	return ''
}

/**
 * рассчет Масса факт., кг
 * @param {Object} mainCtx
 */
const calcSupplyInpCtrlFactWeight = (state) => {
	const result =
		Math.round(
			(state.unldPalDistribs?.reduce((acc, cur) => {
				return acc + calSupplyInpCtrlPalDistrWeightNetto(state, cur)
			}, 0) || 0) * 100
		) / 100
	if (isValidNum(result)) return result
	return ''
}

/**
 * рассчет Кол-во упак. факт., шт
 * @param {Object} mainCtx
 */
const supplyCalcFactPkgCount = (state) => {
	if (state.unldIsStandPkg) {
		return (
			state.unldPalDistribs?.reduce((acc, cur) => {
				return acc + cur.palCount * cur.palPkgCount
			}, 0) || 0
		)
	} else {
		return (
			state.unldPalDistribs?.reduce((acc, cur) => {
				return acc + +cur.palPkgCount
			}, 0) || 0
		)
	}
}

/**
 * рассчет Ср. масса нетто ед. упак. факт.,кг
 * @param {Object} mainCtx
 */
const supplyCalcAvgNettoPkgWeight = (state) => {
	const result = calcSupplyInpCtrlFactWeight(state) / supplyCalcFactPkgCount(state)
	if (isValidNum(result)) return Math.round(result * 100) / 100
	return 0
}

/**
 * рассчет Масса нетто, кг
 * @param {Object} inPalDistr - пал дистрибуция
 * @param {number} inNettoPkgWeight - Масса нетто ед. упак. факт.,кг из основной карты
 */
const calSupplyInpCtrlPalDistrWeightNetto = (state, inPalDistr = {}) => {
	let result = ''
	if (state.unldIsStandPkg) {
		result =
			Number(inPalDistr.palCount) === 0 && inPalDistr?.palCount !== null
				? +inPalDistr.palPkgCount * +state.unldNettoPkgWeight
				: +inPalDistr.palCount * +inPalDistr.palPkgCount * +(state.unldNettoPkgWeight || 0)
	} else {
		result =
			+inPalDistr?.brutto -
			(+inPalDistr?.palPkgCount * +inPalDistr?.pkgWeight + +inPalDistr?.palWeight)
	}
	if (!isValidNum(result)) return ''
	return Math.round(result * 100) / 100
}

/**
 * Кол-во чисел после запятой
 * @param {number} num
 * @param {number} numsAfterDot
 */
const numToFixed = (num, numsAfterDot) => {
	if (!basicValidator(num)) return
	if (!Number.isInteger(num) && num.toString().split('.').pop().length <= numsAfterDot) return num
	return Number.isInteger(+num) ? +num : +Number(num).toFixed(numsAfterDot)
}

/**
 * получить продукт заказчика
 * @param {Object} inRec
 */
const getProdNameByCustomerQuota = (inRec) => {
	return `${inRec?.prodCat?.label} ${getProdCatPackageLabel(inRec?.prodCatPkg || {})}`
}

/**
 * получить продукты договора
 * @param {Object} inState
 * @param {('customer'|'provider')} type - дата окончания
 * @returns {String}
 */
const getCustomerOrProviderProducts = (inState, type) => {
	if (!['customer', 'provider'].includes(type)) throw Error('Неверный тип контракта')

	if (type === 'customer') {
		return inState.customerQuotas
			.map((e) => getProdNameByCustomerQuota(e))
			.filter((e) => e)
			.join(', ')
	}
	if (type === 'provider') {
		return inState.providerQuotas
			.map((e) => getProdNameByProviderQuota(e.rmProvProd))
			.filter((e) => e)
			.join(', ')
	}
}

/**
 * получить общий балл
 * @param {Object} inData - стейт
 * @returns {number}
 */
const getSumScore = (inData) => {
	if (
		!checkIsNot(
			inData,
			['qualityManagement', 'deliveryTime', 'deliverCondition', 'paymentCondition', 'quality'],
			[undefined, null]
		)
	)
		return ''
	return (
		+inData.qualityManagement +
		+inData.deliveryTime +
		+inData.deliverCondition +
		+inData.paymentCondition +
		+inData.quality +
		5 // ABC-анализ
	)
}

/**
 * получить классификацию
 * @param {Object} inData - стейт
 * @returns {number}
 */
const getClassification = (inData) => {
	const sumScore = getSumScore(inData)
	if (sumScore === '') return ''
	if (sumScore >= 20 && sumScore <= 30) return 'Высшая'
	if (sumScore >= 15 && sumScore <= 19) return 'Средняя'
	if (sumScore <= 14) return 'Низкая'
	return ''
}

/**
 * Проверка конкретных полей на то что они не равны чемуто
 * @param {Object} inData
 * @param {Array<String>} inFields
 * @param {Array<String>} inValues
 */
const checkIsNot = (inData, inFields, inValues) => {
	if (!inData || !inFields?.length || !inValues?.length) throw Error('Параметры неверны')
	for (let f of inFields) {
		if (inValues.includes(inData[f])) return false
	}
	return true
}

/**
 * Вычисляет тип согласования которому соответствует задачкап на производство
 */
const calcIsProdTaskPf = (actualState) => {
	if (!actualState.complectations?.length || !actualState.reports?.length) {
		return false
	}
	return !(
		actualState.complectations.some((c) => {
			let key = null
			if (c.stockRawMatStor) key = 'stockRawMatStor'
			if (c.stockReadyProdStor) key = 'stockReadyProdStor'
			if (key === null) return false
			if (c[key].parent) {
				if ([c[key].parent.status, c[key].parent.oldStatus].includes('Допущено')) {
					return true
				}
			}
			if (c[key].oldStatus === 'Допущено') {
				return true
			}
			return false
		}) || actualState.reports.some((r) => ['ГП', 'ДГП'].includes(r.type) && r.status === 'Допущено')
	)
}

const getRole = (match) => {
	for (const f in match) {
		if (f.includes('Initiator') && match[f]) {
			return { role: 'initiator' }
		}
		if (f.includes('Match') && match[f] !== '-') {
			return { role: 'matcher', currentMatchLvl: +match[f] }
		}
		if (f.includes('Approve') && match[f] !== '-') {
			return { role: 'approver', currentApproveLvl: +match[f] }
		}
	}
	return { role: null }
}

const getImplmntOrderId = (prod, productions) => {
	if (!prod) {
		return null
	}
	if (prod.stockRawMatStor) {
		return prod.stockRawMatStor.stockRawMat.supplProd.idContrOrder
	}
	const { taskRep, idProdTaskRep, idContrOrder, isCreatedFromBal } =
		prod.stockReadyProdStor?.stockReadyProd ||
		prod.stockSemifStor?.stockSemif ||
		prod.stockWasteStor?.stockWaste ||
		prod.stockSampleStor?.stockSample ||
		{}

	if (idContrOrder || isCreatedFromBal) {
		return idContrOrder
	}
	if (!taskRep && !idProdTaskRep) {
		return null
	}
	const id =
		taskRep?.idProductionTask ||
		productions.find((el) => el.reports.some((rep) => rep.id === taskRep.id)).id
	const parentTask = productions?.find((prod) => prod.id === id)
	if (!parentTask.complectations.some((c) => c.stockRawMatStor)) {
		return getImplmntOrderId(parentTask.complectations[0], productions)
	}
	return (
		parentTask.complectations.find((c) => c.stockRawMatStor)?.stockRawMat?.supplProd
			?.idContrOrder || null
	)
}

/**
 * вычисляет статус накладной
 */

const getInvoiceStatus = (supply, invoice) => {
	if (!invoice) {
		return supply.status === 'Выполнена' ? 'Принято в 1С' : supply.status
	}
	if (!['Новая'].includes(invoice.status)) {
		return invoice.status
	}
	const { products } = invoice
	if (products?.length) {
		if (products.every((prod) => prod.ctrlStatus === 'Возврат')) {
			return 'Возврат'
		}
		if (
			products.every(
				(prod) =>
					(['Приемка разрешена', 'Ответственное хранение'].includes(prod.ctrlStatus) &&
						prod.unldStatus === 'Разгрузка завершена') ||
					prod.ctrlStatus === 'Возврат'
			)
		) {
			return 'Ожидает отправки в 1С'
		}
	}
	return invoice.status
}

/**
 * вычисляет вес факт по order id или quota id
 */

const getSupplyFactWeight = (orderOrQuotaId, supplies, type) => {
	if (!orderOrQuotaId) return 0
	return supplies
		.flatMap((sup) => sup.products)
		.filter((p) =>
			type === 'quota' ? p.contrQuota?.id === orderOrQuotaId : p.order?.id === +orderOrQuotaId
		)
		.reduce((acc, cur) => {
			if (cur.invoice?.status === 'Принято в 1С') {
				return acc + +calcSupplyInpCtrlFactWeight(cur)
			}
			return acc
		}, 0)
}

/**
 * получает комплектацию запаса
 */
const getTaskWithStockRawMatCompl = (complectation, mainCtx, tasksForComplectations) => {
	if (!complectation) return
	if (complectation.idStockRawMatStor)
		return mainCtx?.state?.taskRep?.task?.complectations?.[0] || complectation
	if (complectation.stockReadyProdResaleStor?.id)
		return mainCtx?.state?.taskRep?.task?.complectations?.[0] || complectation
	let curTask = ''
	const tasks = mainCtx?.selectors?.tasksForComplectations || tasksForComplectations
	curTask = tasks?.find((t) => {
		if (complectation.stockSemifStor?.stockSemif?.taskRep?.idProductionTask === t.id) return t
		if (complectation.stockReadyProdStor?.stockReadyProd?.taskRep?.idProductionTask === t.id)
			return t
		return undefined
	})
	if (!curTask?.complectations?.[0]?.idStockRawMatStor && curTask?.complectations?.length) {
		curTask = getTaskWithStockRawMatCompl(
			curTask?.complectations[0],
			mainCtx,
			tasksForComplectations
		)
	}
	return curTask?.complectations?.[0]
}

/**
 * Склеиает фамилию с Буквами имени и отчества через точку
 * @param {String} name
 * @param {String} surname
 * @param {String} patron
 * @returns {String} - сокращщеное имя
 */
const makeShortPersonName = ({ name, surname, patronymic }) => {
	let patronShort = ''
	if (patronymic?.length) {
		patronShort = `.${patronymic[0]}`
	}
	return `${surname || ''} ${name?.[0] || ''}${patronShort || ''}.`
}

/**
 * изет слудуцющий айдишник
 * @param {Array<Object>} inArr
 * @returns {number}
 */
const getNextCode = (inArr, options = { fromZero: false }) => {
	if (!inArr) throw Error('пустой параметр')
	if (inArr.length === 0) return options.fromZero ? 0 : 1
	if (inArr.some((e) => [undefined, null].includes(e))) throw Error('передан пустой айдишник')
	let max = null
	if (options.startWith) {
		max = Math.max(...inArr, options.startWith)
	} else {
		max = Math.max(...inArr)
	}
	return max + 1
}

/**
 * изет первый свободный айдишник
 * @param {Array<number>} inArr
 * @param {Object} options
 * @returns {number}
 */
const getAvailableCode = (inArr, options = { fromZero: false, withLength: false }) => {
	if (!inArr) throw Error('пустой параметр')
	if (inArr.length === 0) {
		if (options.startWith) return options.startWith
		return options.fromZero ? 0 : 1
	}
	if (inArr.some((e) => [undefined, null].includes(e))) throw Error('передан пустой айдишник')
	if (inArr.some((e) => typeof e === 'string'))
		try {
			inArr = inArr.map((el) => +el)
		} catch (err) {
			console.log(err.message)
			throw Error('Невозможно преобразовать код в число')
		}
	const max = Math.max(...inArr)
	let newCode = max + 1
	let startWith = 1
	if (options.fromZero) {
		startWith = 0
	}
	if (options.startWith) {
		startWith = options.startWith
		if (max < startWith) newCode = startWith
	}
	for (let i = startWith; i <= max + 1; i++) {
		if (inArr.some((e) => e === i)) {
			continue
		}
		newCode = i
		break
	}
	if (options.withLength) {
		newCode = String(newCode)
		newCode = newCode.padStart(6, '0')
	}
	return newCode
}

/**
 * проверяет удовлетворяет ли значение критериям фильтра
 */
const isAcceptedByFilters = (obj, filters = {}) => {
	return Object.entries(filters).every(([name, val]) => {
		if (!val?.length) return true
		val = val.map((v) => v.toString())
		if (!val.includes(String(obj[name]))) return false
		return true
	})
}

const getTotalSumForInStock = (data, filters, search, searchFields) =>
	applyFilterForList(data, { search, searchFields }).reduce((sum, cur) => {
		if (isAcceptedByFilters(cur, filters)) return sum + cur.weight
		return sum
	}, 0)

const applyFilterForList = (inList, params = {}) => {
	let result = [...inList]
	if (![undefined, '', null].includes(params.search))
		if (params.searchFields?.length) {
			result = result.filter((e) => {
				return params.searchFields.some((sf) =>
					String(lodash.get(e, sf)).toLowerCase().includes(params.search.toLowerCase())
				)
			})
		}
	return result
}

const getDataForRmProvProd = (inData) => {
	const prodCatKind = inData?.prodCatKindRawMat
	const prodCat = prodCatKind?.prodCat || inData?.prodCat

	const uuid1C = prodCatKind?.vocProd1c?.uuid1C

	let prodCatKindLabel = ''
	let articul = ''
	if (inData?.prodCatKindRawMat) {
		prodCatKindLabel = getProdCatKindRawMatLabel(inData.prodCatKindRawMat)
		articul = getProdCatKindRawMatsDisplayCode(prodCat.displayCode, prodCatKind)
	}

	const articul1C = prodCatKind?.vocProd1c?.vendorCode

	const prodCatLabel = prodCatKind?.prodCat?.label || inData?.prodCat?.label

	return { uuid1C, prodCatKindLabel, prodCatLabel, articul, articul1C }
}

const getDataFromSupplyProdRmProvProd = (inData) => {
	if (inData?.contrQuota) {
		return getDataForRmProvProd(inData?.contrQuota.rmProvProd)
	}
	if (inData?.order) {
		return getDataForRmProvProd(inData?.order.rmProvProd)
	}
	return ''
	// throw Error(`getDataFromSupplyProdRmProvProd wrong params ${inData}`)
}

module.exports = {
	getImplmntOrderId,
	getProdCatKindReadyProdLabel,
	getProdCatCaliber,
	getSpecLabel,
	getProdCatKindRawMatLabel,
	getProdNameByProviderQuota,
	checkInputCtrlLabIndicatorColor,
	getSpecParamDeviation,
	calcSupplyInpCtrlDiffWeight,
	calcSupplyInpCtrlFactWeight,
	calSupplyInpCtrlPalDistrWeightNetto,
	supplyCalcFactPkgCount,
	supplyCalcAvgNettoPkgWeight,
	numToFixed,
	getProdCatKindRawMatsDisplayCode,
	calcProdTaskIsTolling,
	findProdCatVocMainSemiKind,
	getDataForRmProvProd,
	getDataFromSupplyProdRmProvProd,
	getProdCatPkgDisplayCode,
	getSomeCalculatedParamsForProdTask,
	getProdCatPackageLabel,
	getStockCalculatedParams,
	getProdCatKindSemifArticul,
	getProdCatKindSemifDisplayVal,
	getCustomerOrProviderProducts,
	getProdNameByCustomerQuota,
	getSumScore,
	getClassification,
	checkIsNot,
	calcIsProdTaskPf,
	getRole,
	getInvoiceStatus,
	getSupplyFactWeight,
	getSomeCalculatedParamsForConsReport,
	getTaskWithStockRawMatCompl,
	makeShortPersonName,
	getNextCode,
	getAvailableCode,
	isAcceptedByFilters,
	getTotalSumForInStock,
	isContractActive,
	applyFilterForList,
}
