import React, { useContext, useEffect, useRef, useCallback } from 'react'
import { ModContext } from '../../../../contexts'
import lodash from 'lodash'
import {
	prepareObjFromServer,
	getNewObj,
	removeByUuid,
	modes,
	isAvailableToAddToArr,
	deepFind,
	getObjDiff,
	findByFields,
	validateRequired,
	validateNum,
	validateUniq,
	useItemFetchers,
	getNextIdV2,
} from '../../../../utils'
import {
	basicValidator,
	isUniqueByMultipleFields,
	isUniqueValidator,
	isValidNum,
	isValidNumberDigits,
} from '@berry/common-functions/validators'
import { getAvailableCode, getCommonProviderFunctions } from '../../../../utils/helpers/generators'
import { canDelete } from '../../../../utils/helpers/for-deps'
import {
	getProdCatCaliber,
	getProdCatKindReadyProdLabel,
} from '@berry/common-functions/cross-project-functions'
import { useHistory } from 'react-router-dom'
const dataUrl = '/product-catalogs'
const tabs = {
	'addit-attrs': 'additAttrs',
	calibers: 'calibers',
	'kind-raw-mats': 'kindRawMats',
	'kind-ready-prods': 'kindReadyProds',
	'kind-semifs': 'kindSemifs',
	packages: 'packages',
	qualities: 'qualities',
}

export const reducer = (state) => {
	return {
		...state,
	}
}

const initialData = {
	additAttrs: [],
	calibers: [],
	kindRawMats: [],
	kindReadyProds: [],
	kindSemifs: [],
	packages: [],
}

const ProductCatalogItemMainContext = React.createContext()
ProductCatalogItemMainContext.displayName = 'ProductCatalogItemMainContext'

const Provider = (props) => {
	const { children, params } = props

	const modCtx = useContext(ModContext)

	const [state, dispatch] = React.useReducer(reducer, {
		data: { ...initialData },
		oldData: { ...initialData },
		additional: {
			allVocProdGroups: [],
			allVocMeasures: [],
			allVocProd1Cs: [],
			allProdCats: [],
			allVocAdditSemiTypes: [],
			allVocContTypes: [],
			allVocMainSemifKinds: [],
			allVocQualities: [],
			allVocRawMatStates: [],
			allVocReadyProdForms: [],
			allVocTempRegimes: [],
			allVocWasteTypes: [],
			allVocProdTypes: [],
		},
		formErrors: [],
		isInitializedMain: false,
		isInitializedAdditional: false,
		tabsLoading: {
			additAttrs: false,
			calibers: false,
			kindRawMats: false,
			kindReadyProds: false,
			kindSemifs: false,
			packages: false,
			qualities: false,
		},
	})
	const stateRef = useRef(state)

	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch({ ...newState })
	}
	const history = useHistory()

	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []), {
		initialDataUrl: 'calc-initial-data',
	})

	const getEditedData = () => {
		return getObjDiff(stateRef.current.oldData, stateRef.current.data)
	}
	const isItemEdited = () => {
		if (modCtx.mod === modes.new && Object.keys(stateRef.current.data).length) {
			return true
		}
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}

	/**
	 * Валидация всех полей в соответствии с конфлюенсом
	 * @returns {string} - если вернулась строка и не пустая значит есть ошибка валидации
	 */
	const validate = () => {
		const data = stateRef.current.data
		validateRequired(setError, 'vocProdGroup', '', data.vocProdGroup)

		if (validateRequired(setError, 'label', '', data.label)) {
			validateUniq(
				setError,
				'label',
				data.label,
				stateRef.current.additional.allProdCats,
				stateRef.current.data.id,
				'label',
				'Продукт с таким наименованием уже создан'
			)
		}
		if (validateRequired(setError, 'labelShort', '', data.labelShort)) {
			validateUniq(
				setError,
				'labelShort',
				data.labelShort,
				stateRef.current.additional.allProdCats,
				stateRef.current.data.id,
				'labelShort',
				'Продукт с таким кратким наименованием уже создан'
			)
		}

		const whereToFind = stateRef.current.additional.allProdCats.filter(
			(e) => +e.id !== +stateRef.current.data.id
		)

		if (
			whereToFind
				.map((prod) => prod.color)
				.filter((e) => e)
				.includes(stateRef.current.data.color)
		) {
			return 'Продукт с данным цветом уже создан'
		}
		if (
			whereToFind.some(
				(e) => e.label.toLowerCase() === String(stateRef.current.data.label).toLowerCase()
			)
		) {
			return 'Продукт с таким наименованием уже создан'
		}

		if (
			whereToFind.some(
				(e) => e.labelShort.toLowerCase() === String(stateRef.current.data.labelShort).toLowerCase()
			)
		) {
			return 'Продукт с таким кратким наименованием уже создан'
		}

		return ''
	}

	const validators = {
		package: (inUuid) => {
			const found = stateFunctions.packages.get(inUuid)
			validateRequired(setError, 'package.vocContType', '', found.vocContType)
			if (validateRequired(setError, 'package.weight', '', found.weight)) {
				if (!isValidNum(found.weight) || !isValidNumberDigits(+found.weight, 'float', [4, 1])) {
					setError([], [], 'package.weight', 'Поле заполнено не верно')
				} else {
					setError([], [], 'package.weight', undefined)
				}
			}
			validateRequired(setError, 'package.kindReadyProd', '', found.kindReadyProd)
			validateRequired(setError, 'package.vocQuality', '', found.vocQuality)
			// Проверяем есть ли у нас хотябы 1 ошибка
			if (
				errorFields.packages.some((e) => {
					return !!stateRef.current.formErrors[e]
				})
			)
				throw Error('')
			const whereToFind = stateRef.current.data.packages.filter((e) => e._uuid_ !== inUuid)
			if (
				whereToFind
					.filter((el) => !el.isArchive)
					.some((e) => {
						if (
							String(e.vocContType?.id) === String(found.vocContType?.id) &&
							String(e.weight) === String(found.weight) &&
							String(e.kindReadyProd?.id) === String(found.kindReadyProd?.id) &&
							String(e.vocQuality?.id) === String(found.vocQuality?.id)
						) {
							return true
						}
						return false
					})
			) {
				throw Error('Продукт с такими параметрами уже существует')
			}

			return
		},
		kindSemifs: (inUuid) => {
			const found = stateFunctions.kindSemifs.get(inUuid)
			validateRequired(setError, 'kindSemif.vocMainSemiKindKind', '', found.vocMainSemiKindKind)
			validateRequired(setError, 'kindSemif.vocProdType', '', found.vocProdType)

			switch (found.vocProdType?.labelShort) {
				case 'ПФ':
					if (found.vocMainSemiKindKind?.isCaliber) {
						validateRequired(setError, 'kindSemif.kpCaliber', '', found.kpCaliber)
					}
					break
				case 'ДП':
					validateRequired(setError, 'kindSemif.kpVocAdditSemiType', '', found.kpVocAdditSemiType)
					break
				case 'НО':
					validateRequired(setError, 'kindSemif.kpVocWaseType', '', found.kpVocWaseType)
					break
				default:
					break
			}
			if (found.isReturnWaste) {
				validateRequired(setError, 'kindSemif.vocMainSemiKindKind2', '', found.vocMainSemiKindKind2)
				validateRequired(
					setError,
					'kindSemif.vocProdTypeReturnWaste',
					'',
					found.vocProdTypeReturnWaste
				)
			}
			if (
				errorFields.kindSemifs.some((e) => {
					return !!stateRef.current.formErrors[e]
				})
			)
				throw Error('')
			const whereToFind = stateRef.current.data.kindSemifs.filter((e) => e._uuid_ !== inUuid)
			if (
				whereToFind
					.filter((el) => !el.isArchive)
					.some((e) => {
						if (
							String(e.vocMainSemiKindKind?.id) === String(found.vocMainSemiKindKind?.id) &&
							String(e.kpCaliber?.id) === String(found.kpCaliber?.id) &&
							String(e.vocProdType?.id) === String(found.vocProdType?.id) &&
							String(e.vocProdTypeReturnWaste?.id) === String(found.vocProdTypeReturnWaste?.id) &&
							String(e.kpVocAdditSemiType?.id) === String(found.kpVocAdditSemiType?.id) &&
							String(e.kpVocWaseType?.id) === String(found.kpVocWaseType?.id) &&
							String(!!e.isReturnWaste) === String(!!found.isReturnWaste) &&
							String(e.vocMainSemiKindKind2?.id) === String(found.vocMainSemiKindKind2?.id) &&
							String(e.additArrt?.id) === String(found.additArrt?.id) &&
							String(e.quality?.id) === String(found.quality?.id)
						)
							return true
						return false
					})
			) {
				return 'Продукт с такими параметрами уже существует'
			}
		},
		calibers: (inUuid) => {
			const found = stateFunctions.calibers.get(inUuid)
			validateRequired(setError, 'caliber.valueTwo', '', found.valueTwo)
			validateNum(setError, 'caliber.valueTwo', '', found.valueTwo, {
				numType: 'int',
				digits: [10, 0],
			})
			validateRequired(setError, 'caliber.vocMeasure', '', found.vocMeasure)
			validateRequired(setError, 'caliber.symbol', '', found.symbol)
			if (found.symbol === '-') {
				validateRequired(setError, 'caliber.valueOne', '', found.valueOne)
				validateNum(setError, 'caliber.valueOne', '', found.valueOne, {
					numType: 'int',
					digits: [10, 0],
				})
				validateNum(setError, 'caliber.valueTwo', '', found.valueTwo, {
					numType: 'int',
					digits: [10, 0],
				})
				if (+found.valueOne >= +found.valueTwo)
					throw Error('Значение 1 должно быть меньше значения 2')
			}
			if (
				errorFields.calibers.some((e) => {
					return !!stateRef.current.formErrors[e]
				})
			)
				throw Error('')

			const whereToFind = stateRef.current.data.calibers.filter((e) => e._uuid_ !== inUuid)
			if (
				!isUniqueByMultipleFields(
					whereToFind,
					['valueOne', 'symbol', 'valueTwo', 'vocMeasure.id'],
					found
				)
			) {
				return 'Данный Калибр уже добавлен'
			}
			return
		},
		additAttr: (inUuid) => {
			const found = stateFunctions.additAttrs.get(inUuid)
			validateRequired(setError, 'additAttr.label', '', found.label)
			if (
				errorFields.additAttrs.some((e) => {
					return !!stateRef.current.formErrors[e]
				})
			)
				throw Error('')
			const whereToFind = stateRef.current.data.additAttrs.filter((e) => e._uuid_ !== inUuid)

			if (!isUniqueValidator(whereToFind, 'label', found.label))
				throw Error('Данный Доп. признак уже добавлен')
			return
		},
		quality: (inUuid) => {
			const found = stateFunctions.qualities.get(inUuid)
			validateRequired(setError, 'quality.label', '', found.label)
			if (
				errorFields.qualities.some((e) => {
					return !!stateRef.current.formErrors[e]
				})
			)
				throw Error('')
			const whereToFind = stateRef.current.data.qualities.filter((e) => e._uuid_ !== inUuid)

			if (!isUniqueValidator(whereToFind, 'label', found.label))
				throw Error('Данное качество уже добавлено')
			return
		},

		kindReadyProds: () => {
			if (!stateRef.current.data.kindReadyProds?.length) throw Error('Не выбрана ни одна форма')
			if (
				stateRef.current.data.isCalibration &&
				stateRef.current.data.kindReadyProds.some(
					(e) =>
						e.vocReadyProdForm.isBindCaliber &&
						!basicValidator(e.caliber) &&
						!e.isNotCalibratedCaliber
				) &&
				!!stateRef.current.data.calibers?.length
			)
				throw Error('Не выбран ни один калибр')
		},
		kindRawMats: () => {
			if (!stateRef.current.data.kindRawMats?.length) throw Error('Не выбрано ни одно состояние')
		},
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch({
			...stateRef.current,
			data: recordFromDataSrvCtx,
			oldData: recordFromDataSrvCtx,
			formErrors: {},
		})
	}, [])

	const reset = () => {
		modCtx.set(modes.view)
		_reset({ dropId: true })
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, params.id])

	const depsFunctions = {
		additAttrs: (inId) => [],
		calibers: (inId) => [],
		kindReadyProds: (inId) => [],
		kindRawMats: (inId) => [],
		packages: (inId) => [],
		kindSemifs: (inId) => [],
	}

	const canDeleteFunctions = {
		kindReadyProds: (inIds) => {
			const canDel = canDelete(
				{
					prod_cat_kind_ready_prod: [...new Set(inIds.filter((e) => e))],
				}
				// dataServerCtx
			)
			return canDel
		},
	}
	/**
	 * подготавливает данные которые нужно выбират
	 */
	const selectors = {
		packages: {
			kindReadyProd: (stateRef.current.data.kindReadyProds || []).map((e) => {
				return { ...e, displayVal: getProdCatKindReadyProdLabel(e) }
			}),
		},

		kindReadyProds: {
			additAttr: (stateRef.current.data.additAttrs || []).map((e) => {
				return {
					...e,
					displayVal: e.label,
				}
			}),
			caliber: [{ id: -1, label: 'некалиброван.' }, ...(stateRef.current.data.calibers || [])],
		},

		kindSemifs: {
			allVocMainSemiKindKind: (inProc) => {
				if ([null, undefined, ''].includes(inProc)) {
					return stateRef.current.additional.allVocMainSemifKinds.map((e) => e.kinds).flat()
				}
				return (
					stateRef.current.additional.allVocMainSemifKinds.find((e) => e.id === inProc)?.kinds || []
				)
			},

			kpCaliber: (stateRef.current.data?.calibers || []).map((e) => {
				return {
					...e,
					displayVal: getProdCatCaliber(e),
				}
			}),

			additArrt: (stateRef.current.data.additAttrs || []).map((e) => {
				return { ...e, displayVal: e.label }
			}),
			allVocMainSemiKindKind2: (inProc) => {
				if ([null, undefined, ''].includes(inProc)) {
					return stateRef.current.additional.allVocMainSemifKinds.map((e) => e.kinds).flat()
				}
				return (
					stateRef.current.additional.allVocMainSemifKinds.find((e) => e.id === inProc)?.kinds || []
				)
			},
		},
	}
	// Сделать сеттеры со сбросом. Сдеклать бэк. Сджелать рассчет артикула
	const getActualState = () => {
		const obj = lodash.cloneDeep(stateRef.current.data)
		return obj
	}

	const errorFields = {
		main: ['vocProdGroup', 'label', 'labelShort', 'outPercent', 'onePalleta', 'deviation'],
		packages: [
			'package.weight',
			'package.vocContType',
			'package.kindReadyProd',
			'package.vocQuality',
		],
		calibers: ['caliber.vocMeasure', 'caliber.symbol', 'caliber.valueOne', 'caliber.valueTwo'],
		additAttrs: ['additAttr.label'],
		kindSemifs: [
			'kindSemif.vocMainSemiKindKind',
			'kindSemif.vocProdType',
			'kindSemif.kpCaliber',
			'kindSemif.kpVocAdditSemiType',
			'kindSemif.kpVocWaseType',
			'kindSemif.vocMainSemiKindKind2',
			'kindSemif.selectedVocMainSemiKind',
			'kindSemif.selectedVocMainSemiKind2',
		],
		qualities: ['quality.label'],
	}

	const {
		stateFunctions,
		serverEdit,
		serverDelete,
		isEdited,
		commonDeepFieldUpdate,
		commonFieldUpdate,
		setError,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{ modCtx, dataUrl, params, pageUrl: '/pv/product-catalog', history },
		{},
		{
			label: 'common',
			labelShort: 'common',
			outPercent: 'common',
			onePalleta: 'common',
			deviation: 'common',
			vocProdGroup: 'common',
		},
		{
			kindRawMats: {
				vocProd1c: 'common',
				isArchive: 'common',
			},

			packages: {
				vocContType: 'common',
				kindReadyProd: 'common',
				vocQuality: 'common',
				vocProd1c: 'common',
				isAdditionalProduct: 'common',
				isArchive: 'common',
			},

			calibers: {
				valueOne: 'common',
				valueTwo: 'common',
				vocMeasure: 'common',
			},
			kindReadyProds: {},
			kindSemifs: {
				kpCaliber: 'common',
				kpVocAdditSemiType: 'common',
				kpVocWaseType: 'common',
				additArrt: 'common',
				vocMainSemiKindKind2: 'common',
				vocProd1c: 'common',
				quality: 'common',
				isArchive: 'common',
				vocProdTypeReturnWaste: 'common',
			},

			additAttrs: {},
			qualities: {
				label: 'common',
			},
		}
	)

	stateFunctions.setColor = (val) => {
		commonFieldUpdate('color', val)
		const whereToFind = stateRef.current.additional.allProdCats.filter(
			(e) => +e.id !== +stateRef.current.data.id
		)
		if (whereToFind.map((prod) => prod.color).includes(val)) {
			setError([], [], 'color', 'Продукт с данным цветом уже создан')
		} else {
			setError([], [], 'color', undefined)
		}
	}

	stateFunctions.setIsCalibration = (val) => {
		commonFieldUpdate('isCalibration', val)
		if (val === false) {
			commonFieldUpdate('calibers', [])
			let newVal = lodash.cloneDeep(stateRef.current.data.kindReadyProds || [])
			newVal.forEach((e) => (e.caliber = null))
			newVal = stateFunctions.kindReadyProds.makeUniq(newVal)
			commonFieldUpdate('kindReadyProds', newVal)
		}
	}
	stateFunctions.setIsKindPfByQuality = (val) => {
		commonFieldUpdate('isKindPfByQuality', val)
		if (val === false) {
			commonFieldUpdate('qualities', [])
			let newVal = lodash.cloneDeep(stateRef.current.data.kindSemifs || [])
			newVal.forEach((e) => (e.quality = null))
			commonFieldUpdate('kindSemifs', newVal)
		}
	}
	stateFunctions.setIsAdditAttr = (val) => {
		commonFieldUpdate('isAdditAttr', val)
		if (val === false) {
			commonFieldUpdate('additAttrs', [])

			let newVal = lodash.cloneDeep(stateRef.current.data.kindReadyProds)
			lodash.remove(newVal, (e) => {
				return e.additAttr
			})
			commonFieldUpdate('kindReadyProds', newVal)
		}
		// очистить kindReadyProds
	}
	stateFunctions.kindRawMats.findByFields = (inData) => {
		return findByFields(stateRef.current.data.kindRawMats, inData, [
			'vocRawMatState.id',
			'vocTempRegime.id',
		])
	}
	stateFunctions.kindRawMats.editAll = (value = []) => {
		if (!Array.isArray(value)) throw Error('not array')
		commonFieldUpdate('kindRawMats', value)
	}

	stateFunctions.packages.setWeight = (inUuid, val) => {
		commonDeepFieldUpdate(['packages'], [inUuid], 'weight', val)
		if (!isValidNum(val) || !isValidNumberDigits(val, 'float', [4, 1])) {
			setError([], [], 'package.weight', 'Поле Вес заполнено неверно')
		} else {
			setError([], [], 'package.weight', undefined)
		}
	}
	stateFunctions.calibers.create = (inData) => {
		let newValues
		const displayCode = getAvailableCode(
			(stateRef.current.data.calibers || []).map((e) => +e.displayCode)
		)
		const id = getNextIdV2(stateRef.current.additional.allProdCats, stateRef.current.data.calibers)
		if (isAvailableToAddToArr(inData)) {
			newValues = [
				...(stateRef.current.data.calibers || []),
				{
					...getNewObj(inData),
					id: id,
					displayCode,
				},
			]
		} else {
			newValues = [
				...(stateRef.current.data.calibers || []),
				{
					...getNewObj(),
					id: id,
					displayCode,
				},
			]
		}
		commonFieldUpdate('calibers', newValues)
		return newValues.slice(-1)[0]._uuid_
	}
	stateFunctions.calibers.delete = (inUuid) => {
		const newValues = removeByUuid(inUuid, stateRef.current.data.calibers)
		const found = deepFind(['calibers'], [inUuid], stateRef.current.data)
		commonFieldUpdate('calibers', newValues)

		if (stateRef.current.data.kindSemifs?.length) {
			let newKS = lodash.cloneDeep(stateRef.current.data.kindSemifs)
			newKS.forEach((e) => {
				if (e.kpCaliber?.id === found.id) e.kpCaliber = null
			})
			commonFieldUpdate('kindSemifs', newKS)
		}

		if (stateRef.current.data.kindReadyProds?.length) {
			let newKRP = stateRef.current.data.kindReadyProds.filter((e) => {
				return e.caliber?.id !== found.id
			})
			commonFieldUpdate('kindReadyProds', newKRP)
		}
	}
	stateFunctions.calibers.updateInKindSemifs = () => {
		stateRef.current.data.calibers.forEach((c) => {
			const oldKindSemifs =
				stateRef.current.data.kindSemifs?.filter((e) => {
					if (c.id === e.additAttr?.id) return true
					return false
				}) || []
			oldKindSemifs.forEach((ks) => {
				if (
					+ks.kpCaliber.valueOne !== +c.valueOne ||
					ks.kpCaliber.symbol !== c.symbol ||
					+ks.kpCaliber.valueTwo !== +c.valueTwo
				) {
					commonDeepFieldUpdate(['kindSemifs'], [ks._uuid_], 'kpCaliber', c)
				}
			})
		})
	}
	stateFunctions.calibers.setSymbol = (inUuid, inData) => {
		commonDeepFieldUpdate(['calibers'], [inUuid], 'symbol', inData)
		if (['<', '>'].includes(inData)) {
			commonDeepFieldUpdate(['calibers'], [inUuid], 'valueOne', null)
		}
		stateFunctions.calibers.updateInKindSemifs()
		validateRequired(setError, 'caliber.symbol', 'Символ', inData)
	}
	stateFunctions.kindSemifs.setVocMainSemiKindKind = (inUuid, val) => {
		commonDeepFieldUpdate(['kindSemifs'], [inUuid], 'vocMainSemiKindKind', val)
		if (!val?.isCaliber) {
			commonDeepFieldUpdate(['kindSemifs'], [inUuid], 'kpCaliber', null)
			setError([], [], 'kindSemif.kpCaliber', undefined)
		}
		validateRequired(setError, 'kindSemif.vocMainSemiKindKind', '', val)
	}
	stateFunctions.kindSemifs.setVocProdType = (inUuid, val) => {
		const found = deepFind(['kindSemifs'], [inUuid], stateRef.current.data)
		commonDeepFieldUpdate(['kindSemifs'], [inUuid], 'vocProdType', val)
		validateRequired(setError, 'kindSemif.vocProdType', '', val)
		if (String(found?.vocProdType?.id) !== String(val)) {
			setError([], [], 'kindSemif.kpCaliber', undefined)
			setError([], [], 'kindSemif.kpVocAdditSemiType', undefined)
			setError([], [], 'kindSemif.kpVocWaseType', undefined)
		}
	}
	stateFunctions.kindSemifs.setQuality = (inUuid, val) => {
		commonDeepFieldUpdate(['kindSemifs'], [inUuid], 'quality', val)
		validateRequired(setError, 'kindSemif.quality', '', val)
	}
	stateFunctions.kindSemifs.setIsReturnWaste = (inUuid, inData) => {
		commonDeepFieldUpdate(['kindSemifs'], [inUuid], 'isReturnWaste', inData)
		if (!inData) {
			commonDeepFieldUpdate(['kindSemifs'], [inUuid], 'vocMainSemiKindKind2', null)
			setError([], [], 'kindSemif.vocMainSemiKindKind2', undefined)
			setError([], [], 'kindSemif.selectedVocMainSemiKind2', undefined)
		}
	}

	stateFunctions.additAttrs.create = (inData) => {
		let newValues
		const displayCode = getAvailableCode(
			(stateRef.current.data.additAttrs || []).map((e) => +e.displayCode)
		)
		const id = getNextIdV2(
			stateRef.current.additional.allProdCats,
			stateRef.current.data.additAttrs
		)
		if (isAvailableToAddToArr(inData)) {
			newValues = [
				...(stateRef.current.data.additAttrs || []),
				{
					...getNewObj(inData),
					id: id,
					displayCode: displayCode,
				},
			]
		} else {
			newValues = [
				...(stateRef.current.data.additAttrs || []),
				{
					...getNewObj(),
					id: id,
					displayCode: displayCode,
				},
			]
		}
		commonFieldUpdate('additAttrs', newValues)
		return newValues.slice(-1)[0]._uuid_
	}

	stateFunctions.qualities.create = (inData) => {
		let newValues
		const displayCode = getAvailableCode(
			(stateRef.current.data.qualities || []).map((e) => +e.displayCode)
		)
		const id = getNextIdV2(
			stateRef.current.additional.allQualities,
			stateRef.current.data.qualities
		)
		if (isAvailableToAddToArr(inData)) {
			newValues = [
				...(stateRef.current.data.qualities || []),
				{
					...getNewObj(inData),
					id: id,
					displayCode,
				},
			]
		} else {
			newValues = [
				...(stateRef.current.data.qualities || []),
				{
					...getNewObj(),
					id: id,
					displayCode,
				},
			]
		}
		commonFieldUpdate('qualities', newValues)
		return newValues.slice(-1)[0]._uuid_
	}

	stateFunctions.qualities.delete = (inUuid) => {
		const newValues = removeByUuid(inUuid, stateRef.current.data.qualities)
		const found = deepFind(['qualities'], [inUuid], stateRef.current.data)
		commonFieldUpdate('qualities', newValues)

		if (stateRef.current.data.kindSemifs?.length) {
			let newKS = lodash.cloneDeep(stateRef.current.data.kindSemifs)
			newKS.forEach((e) => {
				if (e.quality?.id === found.id) e.quality = null
			})
			commonFieldUpdate('kindSemifs', newKS)
		}
	}

	stateFunctions.additAttrs.delete = (inUuid) => {
		const newValues = removeByUuid(inUuid, stateRef.current.data.additAttrs)
		const found = deepFind(['additAttrs'], [inUuid], stateRef.current.data)
		commonFieldUpdate('additAttrs', newValues)

		if (!stateRef.current.data.kindReadyProds?.length) return
		let newKRP = lodash.cloneDeep(stateRef.current.data.kindReadyProds)
		newKRP.forEach((e) => {
			if (e.additAttr?.id === found.id) e.additAttr = null
		})
		newKRP = stateFunctions.kindReadyProds.makeUniq(newKRP)
		commonFieldUpdate('kindReadyProds', newKRP)

		let newKS = lodash.cloneDeep(stateRef.current.data.kindSemifs)
		newKS.forEach((e) => {
			if (e.additAttr?.id === found.id) e.additAttr = null
		})
		commonFieldUpdate('kindSemifs', newKS)
	}
	stateFunctions.additAttrs.setLabel = (inUuid, val) => {
		// если мы меняем доп итрибут то должны его и в видах ГП
		let oldObj = deepFind(['additAttrs'], [inUuid], stateRef.current.data)
		const oldKindReadyProds =
			stateRef.current.data.kindReadyProds?.filter((e) => {
				if (oldObj.id === e.additAttr?.id) return true
				return false
			}) || []
		const oldKindSemifs =
			stateRef.current.data.kindSemifs?.filter((e) => {
				if (oldObj.id === e.additAttr?.id) return true
				return false
			}) || []
		commonDeepFieldUpdate(['additAttrs'], [inUuid], 'label', val)
		oldObj.label = val
		oldKindReadyProds.forEach((e) => {
			commonDeepFieldUpdate(['kindReadyProds'], [e._uuid_], 'additAttr', oldObj)
		})
		oldKindSemifs.forEach((e) => {
			commonDeepFieldUpdate(['kindSemifs'], [e._uuid_], 'additAttr', oldObj)
		})
		validateRequired(setError, 'additAttr.label', '', val)
	}

	stateFunctions.kindReadyProds.makeUniq = (inKrp) => {
		const result = lodash.uniqWith(inKrp, (a, b) => {
			if (
				a.additAttr?.id === b.additAttr?.id &&
				a.vocReadyProdForm?.id === b.vocReadyProdForm?.id &&
				a.caliber?.id === b.caliber?.id
			) {
				return true
			}
			return false
		})
		return result
	}
	stateFunctions.kindReadyProds.get = (inParams = {}, options) => {
		const { _uuid_ } = inParams
		if (_uuid_) return deepFind(['kindReadyProds'], [_uuid_], stateRef.current.data)

		const result = findByFields(
			getActualState().kindReadyProds,
			inParams,
			['additAttr.id', 'vocReadyProdForm.id', 'caliber.id'],
			options
		)
		return lodash.cloneDeep(result)
	}
	stateFunctions.kindReadyProds.getCalibers = (inAdditAttr) => {
		let withCalibers =
			stateRef.current.data?.kindReadyProds?.filter((e) => {
				if (inAdditAttr?.id) {
					return e.additAttr?.id === inAdditAttr.id && basicValidator(e.caliber)
				} else {
					return !e.additAttr?.id && basicValidator(e.caliber)
				}
			}) || []
		const calibers = [...new Set(withCalibers.map((e) => e.caliber))]
		return calibers
	}
	stateFunctions.kindReadyProds.editAll = (value) => {
		if (!Array.isArray(value)) throw Error('not array')
		commonFieldUpdate('kindReadyProds', value)
	}
	stateFunctions.kindReadyProds.isBindCaliber = (inAdditAttr) => {
		if (inAdditAttr) {
			return !!stateRef.current.data?.kindReadyProds
				?.filter((e) => e.additAttr?.id === inAdditAttr.id)
				.find((e) => e.vocReadyProdForm?.isBindCaliber)
		}
		return !!stateRef.current.data?.kindReadyProds?.find(
			(e) => e.vocReadyProdForm?.isBindCaliber && !e.additAttr
		)
	}
	stateFunctions.kindReadyProds.create = (inData) => {
		let newValues
		const id = getNextIdV2(
			stateRef.current.additional.allProdCats,
			stateRef.current.data.kindReadyProds
		)
		if (isAvailableToAddToArr(inData)) {
			newValues = [
				...(stateRef.current.data.kindReadyProds || []),
				{
					...getNewObj(inData),
					id: id,
				},
			]
		} else {
			newValues = [
				...(stateRef.current.data.kindReadyProds || []),
				{
					...getNewObj(),
					id: id,
				},
			]
		}
		commonFieldUpdate('kindReadyProds', newValues)
		return newValues.slice(-1)[0]._uuid_
	}
	stateFunctions.kindReadyProds.delete = (inUuid) => {
		const newValues = removeByUuid(inUuid, stateRef.current.data.kindReadyProds)
		const found = deepFind(['kindReadyProds'], [inUuid], stateRef.current.data)
		commonFieldUpdate('kindReadyProds', newValues)

		if (stateRef.current.data.packages?.length) {
			const newPackages = stateRef.current.data.packages.filter(
				(e) => String(e.kindReadyProd?.id) !== String(found.id)
			)
			commonFieldUpdate('packages', newPackages)
		}
	}
	stateFunctions.kindReadyProds.setCaliber = (inUuid, val) => {
		if (val === null) {
			commonDeepFieldUpdate(['kindReadyProds'], [inUuid], 'isNotCalibratedCaliber', false)
		}
		if (String(val?.id) === '-1' || String(val) === '-1') {
			commonDeepFieldUpdate(['kindReadyProds'], [inUuid], 'isNotCalibratedCaliber', true)
			return
		}
		commonDeepFieldUpdate(['kindReadyProds'], [inUuid], 'caliber', val)
	}

	const value = {
		state: getActualState(),
		additional: stateRef.current.additional,
		isPendingReq: stateRef.current.isPendingReq,
		stateFunctions: stateFunctions,
		selectors: selectors,
		validators: validators,
		serverEdit: serverEdit,
		serverDelete: serverDelete,
		delEditDeps: stateRef.current.delEditDeps,
		getEditedData: getEditedData,
		isEdited: isItemEdited,
		validate: validate,
		reset: reset,
		setError,
		errorFields: errorFields,
		depsFunctions,
		canDeleteFunctions,
		formErrors: stateRef.current.formErrors,
	}

	return (
		<ProductCatalogItemMainContext.Provider value={value}>
			{children}
		</ProductCatalogItemMainContext.Provider>
	)
}

export { Provider, ProductCatalogItemMainContext }
