import { Modal, Form, Input } from 'antd'
import { getUserFromToken } from '../../utils'
import { findDepsForTasksForCurLvl } from '../../pages/ProductionTask/ProductionTaskItem/common'
import { calcIsProdTaskPf, getRole } from '@berry/common-functions/cross-project-functions'

export const getIsDoneMatch = (eventHistories) => {
	const user = getUserFromToken()
	const reversedHistory = eventHistories.sort((a, b) => b.id - a.id)
	const foundIndex = reversedHistory.findIndex((rec) => rec.action === 'Инициация согласования')
	const result = reversedHistory
		.slice(0, foundIndex)
		.find((el) => el.staff && el.staff.id === user.id && el.action === 'Согласовано')
	return !!result
}

export const handleCancelMatch = (serverMatch, form) => {
	return new Promise((resolve, reject) => {
		Modal.confirm({
			title: 'Комментарий',
			width: '40%',
			okButtonProps: { htmlType: 'submit', form: 'comment' },
			centered: true,
			icon: null,
			onCancel: () => {
				form.resetFields()
			},
			onOk: async () => {
				const { errorFields = [] } = await form.validateFields()
				if (errorFields.length) {
					return true
				}
			},
			content: (
				<Form
					name="comment"
					form={form}
					colon={false}
					onFinish={(values) => {
						serverMatch({ action: 'Не утверждено', comment: values.comment })
						form.resetFields()
						resolve()
					}}
				>
					<Form.Item
						name="comment"
						label=" "
						rules={[{ required: true, message: 'Поле обязательно для заполнения!' }]}
					>
						<Input.TextArea
							maxLength={1000}
							rows={10}
							style={{
								display: 'block',
							}}
						/>
					</Form.Item>
				</Form>
			),
		})
	})
}

/**
 * Согласующим должны быть согласованы все родительские отчеты.
 */
export const calcAllowMatchOrApproveForTask = (action, match, matchObject, task, dataServerCtx) => {
	if (!['taskPf', 'task'].includes(matchObject)) return true
	const taskDepsLvlsMap = {}
	let lvl = 1
	let curLvlTasks = [task]
	const taskPfMatchData = getRole(match['taskPf'])
	const taskMatchData = getRole(match['task'])
	do {
		const tasksForLvl = findDepsForTasksForCurLvl(curLvlTasks, dataServerCtx, {
			withoutStatusFilter: true,
		})
		if (
			tasksForLvl.some((t) => {
				if (['Ожидает отправки', 'Не утверждено', 'Черновик', 'Отклонено 1С'].includes(t.status))
					return true
				const { currentMatchLvl, currentApproveLvl } = calcIsProdTaskPf(t)
					? taskPfMatchData
					: taskMatchData
				if (action === 'match') {
					if (t.status === 'На согласовании' && t.level <= currentMatchLvl) return true
				}
				if (action === 'approve') {
					if (t.status === 'На согласовании') return true
					if (t.status === 'На утверждении' && t.level <= currentApproveLvl) return true
				}
				return false
			})
		) {
			return false
		}
		if (tasksForLvl.length === 0) {
			return true
		}
		curLvlTasks = [...tasksForLvl]
		taskDepsLvlsMap[lvl] = [...curLvlTasks]
		lvl++
	} while (true)
}
