import { sorter, getColumnSearchProps } from '../../../../utils'

export const getDisplayColumns = (inArr = []) => {
	return [
		{
			title: 'Наименование',
			dataIndex: 'label',
			key: 'label',
			width: '42%',
		},
		{
			title: 'Версия',
			dataIndex: 'version',
			key: 'version',
			width: '12%',
		},

		{
			title: 'Дата утв.',
			dataIndex: 'dateOfApprove',
			key: 'dateOfApprove',
			width: '12%',
			sorter: sorter('string', 'dateOfApprove'),
		},
		{
			title: 'Вид продукта',
			dataIndex: 'quality',
			key: 'quality',
			width: '12%',
			...getColumnSearchProps(
				inArr.map((e) => e?.quality),
				undefined,
				'quality'
			),
		},
		{
			title: 'Качество',
			dataIndex: ['vocQuality', 'label'],
			key: 'displayCode1c',
			width: '12%',
			...getColumnSearchProps(
				inArr.map((e) => e?.vocQuality?.label),
				undefined,
				'vocQuality.label'
			),
		},
	]
}
