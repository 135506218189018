import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import { Card } from 'antd'
import { Provider, RawMaterialSupplyListMainContext } from './provider/main'
import { Main } from '../../../layouts'
import { HeaderMenu, StatefulTable } from '../../../components'
import * as utils from './raw-material-supply-list'
import { goToItem, openNewTab } from '../../../utils'
import { AuthContext } from '../../../contexts'
import rowClassName from '../../../utils/helpers/rowClassName'

const RawMaterialSupplyList = () => (
	<Provider>
		<Main title="Поставки сырья">
			<RawMaterialSupplyListTable />
		</Main>
	</Provider>
)

const RawMaterialSupplyListTable = () => {
	const mainCtx = useContext(RawMaterialSupplyListMainContext)
	const history = useHistory()
	const { getColumns, rights, url } = utils
	const authCtx = useContext(AuthContext)
	const [rowId, setRowId] = useState('')

	return (
		<>
			<HeaderMenu
				rights={rights}
				breadcrumbsTitle="Поставки сырья"
				search
				canCreate
				handleAddOnMenu={() => goToItem(history, { url, id: 'new' })}
			/>
			<Card>
				<StatefulTable
					rowKey="id"
					columns={getColumns}
					url={url}
					prepareDataFns={[]}
					dataSource={mainCtx.toDisplayDataList}
					loading={mainCtx.state.isLoading || !mainCtx.state.isInitialized}
					columnProps={{ mainCtx, fromServerFilters: mainCtx.state.fromServerFilters }}
					rowClassName={rowClassName(rowId)}
					onRow={(data) => ({
						onClick: (e) => {
							if (e.target.className === 'ant-checkbox-input' && !e.target.disabled) {
								return
							}
							if (e.ctrlKey) {
								openNewTab(`${url}/${data.supplyId}`, {
									authCtx: authCtx.state,
								})
								return
							}
							goToItem(history, { url, id: data.supplyId }, data)
						},
						onMouseEnter: () => {
							setRowId(data.supplyId)
						},
						onMouseLeave: () => {
							setRowId('')
						},
					})}
				/>
			</Card>
		</>
	)
}

export default RawMaterialSupplyList
