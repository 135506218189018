import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import lodash from 'lodash'
import moment from 'moment'
import { ModContext } from '../../../../../contexts'
import {
	getCommonProviderFunctions,
	getCommonProviderModalFunctions,
} from '../../../../../utils/helpers/generators'
import {
	prepareObjFromServer,
	modes,
	getNewObj,
	useItemFetchers,
	axios,
	goToItem,
	removeByUuid,
} from '../../../../../utils'
import { basicValidator } from '@berry/common-functions/validators'
import { useHistory } from 'react-router-dom'
import { capitalizeFirstLetter } from '@berry/common-functions/text-functions'
import { v4 } from 'uuid'

export const dataUrl = '/stock/operations/disposals'
const tabs = { productions: 'productions', 'event-histories': 'eventHistories' }
export const reducer = (state) => {
	return {
		...state,
	}
}

const StockOpsDisposalItemMainContext = React.createContext()
StockOpsDisposalItemMainContext.displayName = 'StockOpsDisposalItemMainContext'

const getInitialState = (inData) => {
	prepareObjFromServer(inData)
	return {
		data: inData,
		oldData: inData,
		formErrors: [],
		additional: {},
		addProd: {
			__isOpen: false,
			__name: null,
			addProdStockWasteStor: [],
			addProdStockSampleStor: [],
			allStockWastes: [],
			allStockSamples: [],
			allProdTasks: [],
		},
		deletedProds: [],
	}
}

const Provider = (props) => {
	const { children, params } = props
	const modCtx = useContext(ModContext)
	const initialState = useMemo(
		() => ({
			date: moment().format('YYYY-MM-DD'),
			status: 'Новая',
			productions: [],
			eventHistories: [],
		}),
		[]
	)
	const [state, dispatch] = React.useReducer(reducer, getInitialState(initialState))
	const stateRef = useRef(state)
	const executeDispatch = (newState) => {
		stateRef.current = { ...newState }
		dispatch(newState)
	}
	const history = useHistory()
	useItemFetchers(dataUrl, params.id, tabs, stateRef, useCallback(executeDispatch, []))

	const requiredFields = {
		productions: ['weight'],
	}

	const isItemEdited = () => {
		if (modCtx.mod === modes.new && Object.keys(stateRef.current).length) {
			return true
		}
		const editedFields = getEditedData()
		return isEdited(editedFields)
	}
	const getAvailableWeight = (product) => {
		if (!isNaN(product.availableWeight)) {
			return product.availableWeight || 0
		}
		const storage = (product.stockWasteStor || product.stockSampleStor).storages.find(
			(e) => product.idStorage === e.id
		)
		if (!storage) {
			return 0
		}
		return storage.status === 'Бронь' && storage.parent?.status === 'Допущено'
			? +storage.weight + (+storage.parent?.weight || 0)
			: +storage.weight
	}
	const checkAvailable = () => {
		let errors = []
		for (let p of stateRef.current.data.productions) {
			const availableWeight = getAvailableWeight(p)
			let formErrors = { ...stateRef.current.formErrors }
			if (basicValidator(p.weight) && availableWeight < +p.weight) {
				formErrors[`weight.${p._uuid_}`] = `Доступная масса = ${availableWeight} кг`
				errors.push(p)
			} else {
				formErrors[`weight.${p._uuid_}`] = formErrors[`weight.${p._uuid_}`]
			}
			executeDispatch({
				...stateRef.current,
				formErrors: {
					...stateRef.current.formErrors,
					...formErrors,
				},
			})
		}
		if (errors.length) {
			return false
		}
		return true
	}

	const setDeletedProds = (val) => {
		executeDispatch({
			...stateRef.current,
			deletedProds: val ? [...stateRef.current.deletedProds, val] : [],
		})
	}

	const validate = () => {
		executeDispatch({
			...stateRef.current,
			formErrors: {},
		})
		const data = stateRef.current.data
		if (!data.productions?.length)
			return 'Не добавлена ни одна строка в табличной вкладке Продукция'
		if (!checkAvailable()) {
			return 'В продукции указана масса, превышающая доступную. Карточка не может быть сохранена'
		}
		let newFormErrors = {}
		data.productions.forEach((p) => {
			requiredFields.productions.forEach((f) => {
				if (!basicValidator(p[f]) || +p[f] === 0) {
					newFormErrors = { ...newFormErrors, [f + '.' + p._uuid_]: true }
				}
			})
		})
		if (Object.keys(newFormErrors).length) {
			executeDispatch({ ...stateRef.current, formErrors: newFormErrors })
			return 'Не заполнены обязательные поля'
		}
		return ''
	}

	/**
	 * Сбрасывает все изменения и возвращается к изначальному состоянию
	 */
	const _reset = useCallback(() => {
		const recordFromDataSrvCtx = lodash.cloneDeep(stateRef.current.oldData)
		prepareObjFromServer(recordFromDataSrvCtx)
		executeDispatch(getInitialState(recordFromDataSrvCtx))
	}, [])
	const reset = () => {
		modCtx.set(modes.view)
		_reset()
	}

	useEffect(() => {
		if (params.id === 'new') return
		_reset()
	}, [_reset, params.id])

	const {
		getEditedData,
		isEdited,
		stateFunctions,
		commonFieldUpdate,
		commonDeepFieldUpdate,
		serverDelete,
		setError,
	} = getCommonProviderFunctions(
		stateRef,
		stateRef.current.oldData,
		executeDispatch,
		{ modCtx, dataUrl, params, requiredFields, pageUrl: '/stock-operations/disposals', history },
		{},
		{
			date: 'common',
		},
		{
			productions: {
				weight: 'common',
			},
		}
	)
	stateFunctions.setDate = (date) => {
		commonFieldUpdate('date', moment(date).format('YYYY-MM-DD'))
	}
	stateFunctions.productions.create = (inData, field) => {
		const newArr = [
			...stateRef.current.data.productions,
			...inData.map((el) =>
				getNewObj({
					[field]: el,
					partyNum: el.partyNum,
					articul: el.articul,
					articul1C: el.articul1C,
					prodCat: el.prodCat,
					prodCatKind: el.prodCatKind,
					weight: el.weight,
					idStorage: el.idStorage,
				})
			),
		]
		commonFieldUpdate('productions', newArr)
	}
	stateFunctions.productions.setWeight = (inUuid, value) => {
		commonDeepFieldUpdate(['productions'], [inUuid], 'weight', value, true)
		checkAvailable()
	}
	stateFunctions.productions.delete = (inUuid) => {
		const newProducts = removeByUuid(inUuid, stateRef.current.data.productions)
		commonFieldUpdate('productions', newProducts)
		commonFieldUpdate('idContrOrder', newProducts[0]?.idContrOrder || null)
	}
	const { modalFunctions } = getCommonProviderModalFunctions(
		stateRef,
		executeDispatch,
		{},
		{},
		[
			{ field: 'stockWasteStor', updateVal: 'common', modalName: 'addProd' },
			{ field: 'stockSampleStor', updateVal: 'common', modalName: 'addProd' },
			{ field: '__name', updateVal: 'common', modalName: 'addProd' },
		],
		[
			{
				mainField: 'stockWasteStor',
				field: 'stockWasteStor',
				updateVal: 'common',
				modalName: 'addProd',
			},
			{
				mainField: 'stockSampleStor',
				field: 'stockSampleStor',
				updateVal: 'common',
				modalName: 'addProd',
			},
		]
	)
	const serverEdit = async () => {
		if (!isEdited()) return modCtx.set(modes.view)
		let body = {}
		let method = ''
		let axiosMethod = ''
		if (params.id === 'new') {
			body = stateRef.current.data
			method = 'POST'
			axiosMethod = 'post'
		} else {
			method = 'PUT'
			axiosMethod = 'put'
			body = getEditedData()
		}
		if (body.date) {
			body.date = moment(body.date).format('YYYY-MM-DD')
		}
		executeDispatch({ ...stateRef.current, isPendingReq: true })
		let res = null
		try {
			res = await axios[axiosMethod](dataUrl, body)
			executeDispatch({ ...stateRef.current, isPendingReq: false })
			if (res.data?.data?.id) {
				if (method === 'POST') {
					goToItem(history, { url: dataUrl, id: res.data.data.id })
				}
				executeDispatch({ ...stateRef.current, reloadUuid: v4() })
				modCtx.set(modes.view)
				return { method: method, id: res.data.data.id }
			}
		} catch (err) {
			executeDispatch({ ...stateRef.current, isPendingReq: false })
		}

		return res
	}
	const serverSendTo1c = async () => {
		executeDispatch({ ...stateRef.current, isPendingReq: true })
		const { data } = await axios.put(`${dataUrl}/send-to-1c`, { id: stateRef.current.data.id })
		executeDispatch({ ...stateRef.current, isPendingReq: false })
		if (data?.data?.id) {
			goToItem(history, { id: data.data.id, url: '/stock-operations/disposals' }, { refresh: true })
		}
	}
	const validateStock = () => {
		if (!stateRef.current.addProd.s.length) {
			throw Error('Не выбрана партия')
		}
	}

	const isModalItemDisabled = (record) => {
		const { data, addProd } = stateRef.current
		const { productions } = data

		if (
			productions.length &&
			productions.some((p) => !p[`stock${capitalizeFirstLetter(addProd.__name)}Stor`])
		) {
			return true
		}
		const { addProdStockSampleStor, addProdStockWasteStor } = addProd
		const isAddComplectOrComplectationsLength =
			!!addProdStockWasteStor.length || !!addProdStockSampleStor.length || !!productions?.length

		const idOrder =
			addProdStockWasteStor[0]?.idOrder ||
			addProdStockSampleStor[0]?.idOrder ||
			productions[0]?.idOrder ||
			productions[0]?.idOrder

		if (idOrder) {
			return record.idOrder && record.idOrder === idOrder ? false : true
		}
		if (!idOrder && isAddComplectOrComplectationsLength) {
			return record.idOrder ? true : false
		}
	}
	const value = {
		state: lodash.cloneDeep(stateRef.current.data),
		addProd: lodash.cloneDeep(stateRef.current.addProd),
		deletedProds: lodash.cloneDeep(stateRef.current.deletedProds),
		isPendingReq: stateRef.current.isPendingReq,
		additional: stateRef.current.additional,
		stateFunctions,
		modalFunctions,
		validateStock,
		isModalItemDisabled,
		serverEdit,
		serverDelete,
		isEdited: isItemEdited,
		validate,
		reset,
		formErrors: stateRef.current.formErrors,
		setError,
		serverSendTo1c,
		getAvailableWeight,
		setDeletedProds,
	}

	return (
		<StockOpsDisposalItemMainContext.Provider value={value}>
			{children}
		</StockOpsDisposalItemMainContext.Provider>
	)
}

export { Provider, StockOpsDisposalItemMainContext }
